export enum UserMessagesRoomStatusEnum {
  PENDING = "pending",
  REJECTED = "rejected",
  ACCEPTED = "accepted",
  COMPLETED = "completed",
}

export enum UserMessagesGroupTypeEnum {
  USER = 1,
  WORKSTATION = 0,
}
