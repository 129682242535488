<template>
  <div
    class="messagechat__wrapper"
    v-click-outside="closeEmojiPicker"
    :class="{ messagechat__active: isShowMessage }"
  >
    <v-card class="messagechat__inner" variant="flat">
      <template v-if="!isNewGroupMembers && !isShowMediaList" v-slot:text>
        <div class="messagechat__top" @click="toggleMessages()">
          <div
            class="messagechat__left"
            :class="{ 'with-group': userMessageRoomDetails?.isGroup }"
          >
            <div class="messagechat__img">
              <UserProfileLogo
                v-if="
                  (isProjectOwner && !userMessageRoomDetails?.isUserChat) ||
                  userMessageRoomDetails?.project
                "
                :userName="userMessageRoomDetails?.groupName"
              />
              <img
                loading="lazy"
                :src="
                  !userMessageRoomDetails?.isGroup
                    ? getImagePublicUrl(
                        userMessageRoomDetails?.isUserChat
                          ? receiverUserWorkstation?.user?.profileImage
                          : receiverUserWorkstation?.profileImage
                      )
                    : getImagePublicUrl(userMessageRoomDetails?.groupAvatar)
                    ? getImagePublicUrl(userMessageRoomDetails?.groupAvatar)
                    : require('@/assets/images/user-placeholder.png')
                "
                v-else
                alt="chat_box_icon"
              />
              <div
                v-if="!userMessageRoomDetails?.isGroup"
                :class="
                  (
                    userMessageRoomDetails?.isUserChat
                      ? isUserOnline(receiverUserWorkstation?.user?.id)
                      : isUserWorkStationOnline(receiverUserWorkstation?.id)
                  )
                    ? 'online'
                    : 'offline'
                "
              ></div>
            </div>

            <div class="messagechat__heading">
              <div class="messagechat__heading__title">
                <h5>
                  {{
                    userMessageRoomDetails?.isGroup
                      ? userMessageRoomDetails?.groupName
                      : !receiverUserWorkstation?.isUserChat
                      ? receiverUserWorkstation?.name
                      : receiverUserWorkstation?.user?.firstName +
                        " " +
                        receiverUserWorkstation?.user?.lastName
                  }}
                </h5>
                <p
                  v-if="
                    !userMessageRoomDetails?.isGroup &&
                    !userMessageRoomDetails?.isUserChat
                  "
                >
                  (
                  {{
                    receiverUserWorkstation?.user?.firstName +
                    " " +
                    receiverUserWorkstation?.user?.lastName
                  }}
                  )
                </p>
              </div>
              <div v-if="isShowMessage" class="group_names">
                <span v-if="userMessageRoomDetails?.isGroup">
                  {{ groupsMemberDetails?.groupMembers?.length }}</span
                ><span
                  v-if="userMessageRoomDetails?.isGroup"
                  @click.stop="onShowMember"
                  >Members</span
                >
              </div>
              <p v-if="!userMessageRoomDetails?.isGroup">
                {{
                  (
                    !userMessageRoomDetails?.isUserChat
                      ? isUserWorkStationOnline(receiverUserWorkstation?.id)
                      : isUserOnline(receiverUserWorkstation?.user?.id)
                  )
                    ? "Active now"
                    : "Offline"
                }}
              </p>
            </div>
          </div>
          <div class="messagechat__right">
            <v-icon
              v-if="isShowMessage"
              class="video-call-image"
              @click="onClickVideoCallButton(userMessageRoomDetails?.id)"
            >
              <img :src="require('@/assets/icons/camera.svg')" alt="" />
            </v-icon>
            <v-icon
              @click="closeMessageBox(userMessageRoomDetails?.id)"
            ></v-icon>

            <v-btn
              v-if="isShowMessage"
              @click="onClickToggleMediaModal"
              class="chat_image_display"
              variant="plain"
              height="auto"
            >
              <img src="../../assets/icons/imagefile-icon.svg" alt="" />
            </v-btn>
            <v-icon
              @click="closeMessageBox(userMessageRoomDetails?.id)"
              class="close_icon"
            >
              <img :src="require('@/assets/icons/cancel.svg')" alt="" />
            </v-icon>
          </div>
        </div>
        <div class="messagechat__section" v-if="!loading">
          <div
            class="archive_notification"
            v-if="userMessageRoomDetails?.isArchiveRoom"
          >
            <p>Your chat has been archived.</p>
          </div>
          <div
            class="messagechat__bottom no-scrollbar"
            :class="{ isFileAttached: attachmentFile }"
            ref="chatContainer"
          >
            <div v-if="!userMessages?.length" class="nomessage__wrapper">
              <div class="nomessage__text">
                <div class="comment__icon">
                  <img src="../../assets/icons/comment.svg" alt="" />
                </div>
                <span>Your messages will appear here</span>
              </div>
              <div class="nomessage__note">
                <p>
                  This chat is end-to-end encrypted & monitored by iknowa
                  platfrom.
                </p>
              </div>
            </div>
            <div
              v-click-outside="closeEmojiPicker"
              class="messagechat__bottom__chats"
              v-for="(message, index) in userMessages"
              :key="message.id"
            >
              <div
                :class="
                  message?.userMessageRoom?.isUserChat
                    ? user?.id === message?.fromUser?.id
                      ? 'right_messages'
                      : 'left_messages'
                    : activeUserWorkstation?.id ==
                      message?.fromWorkstationId?.id
                    ? 'right_messages'
                    : 'left_messages'
                "
                v-if="
                  message.content
                    ? true
                    : message.userMessageRoom.userMessageAttachments &&
                      message.userMessageRoom.userMessageAttachments.length
                    ? true
                    : false
                "
              >
                <div
                  v-if="shouldDisplayDate(message, index)"
                  class="message__date"
                >
                  <p>{{ getTheDay(message.dateCreated) }}</p>
                </div>
                <div v-if="message?.unReadMsg" class="message__date">
                  <p>{{ message?.unReadMsg }} Unread Message</p>
                </div>

                <div class="messagechat__bottom__chats__list">
                  <div class="messagechat__left">
                    <div class="messagechat__img">
                      <img
                        :src="
                          getImagePublicUrl(
                            userMessageRoomDetails?.isUserChat ||
                              isGroupUserChat
                              ? message?.fromUser?.profileImage
                              : message?.fromWorkstationId?.profileImage
                          )
                            ? getImagePublicUrl(
                                userMessageRoomDetails?.isUserChat ||
                                  isGroupUserChat
                                  ? message?.fromUser?.profileImage
                                  : message?.fromWorkstationId?.profileImage
                              )
                            : require('@/assets/images/user-placeholder.png')
                        "
                        alt="workstation_img"
                      />
                      <div
                        :class="
                          (
                            userMessageRoomDetails?.isUserChat
                              ? isUserOnline(message?.fromUser?.id)
                              : isUserWorkStationOnline(
                                  message?.fromWorkstationId?.id
                                )
                          )
                            ? 'online'
                            : 'offline'
                        "
                      ></div>
                    </div>
                  </div>

                  <div class="messagechat__center">
                    <div class="messagechat__heading">
                      <h6>
                        {{
                          message?.userMessageRoom?.isUserChat ||
                          isGroupUserChat
                            ? message?.fromUser?.firstName +
                              " " +
                              message?.fromUser?.lastName
                            : message?.fromWorkstationId?.name
                        }}
                      </h6>
                    </div>
                  </div>
                  <div class="messagechat__right">
                    <span>{{ getTimeElapsedString(message.dateCreated) }}</span>
                  </div>
                </div>
                <div
                  class="messagechat__bottom__chats__text"
                  :class="{ 'toggle-text': toggleShowText === message.id }"
                >
                  <p>
                    <span>{{ message.content }}</span>
                    <span
                      class="message-toggle"
                      v-if="countLines(message?.content)"
                      @click="onClickToggleShowText(message.id)"
                      >{{
                        toggleShowText === message.id
                          ? "Show Less"
                          : "Read More"
                      }}</span
                    >
                  </p>
                  <div
                    class="messagechat_attachment_wrapper"
                    v-for="data in message.userMessageRoom
                      .userMessageAttachments"
                    :key="data.id"
                  >
                    <div class="messagechat_attachment">
                      <img
                        style="height: 20px"
                        :src="require('@/assets/icons/documents.svg')"
                        alt=""
                      />
                      <p>{{ data.originalName }}</p>
                      <span
                        class="tw-relative tw-w-[22px] tw-shrink-0 tw-block"
                      >
                        <v-icon
                          @click="downloadAttachment(data)"
                          class="download__icon"
                          v-if="
                            data.id &&
                            !attachmentDownloadLoadingArray.includes(data.id)
                          "
                          ><img
                            style="height: 20px"
                            :src="require('@/assets/icons/downloads.svg')"
                            alt=""
                        /></v-icon>
                        <CommonLoader
                          class="chat_loader"
                          v-if="
                            attachmentDownloadLoadingArray.includes(data.id)
                          "
                          :loading="
                            attachmentDownloadLoadingArray.includes(data.id)
                          "
                        />
                        <CommonLoader
                          class="chat_loader"
                          v-if="isLoading && isShowMessage"
                          :loading="isLoading && !data.id"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="messagechat__input"
            :class="{ isAttached: attachmentFile }"
            v-if="!isProjectChatCompleted"
            id="click-outside"
          >
            <div class="dox_preview" v-if="attachmentFile">
              <div class="dox_icon">
                <v-img
                  v-if="attachmentImageUrl"
                  class="uploaded-img"
                  :width="200"
                  aspect-ratio="16/9"
                  cover
                  :src="attachmentImageUrl"
                ></v-img>
                <img
                  v-else
                  :src="require('@/assets/icons/chat-dox.svg')"
                  alt=""
                />
              </div>
              <v-icon class="cancel-icon" @click="onAttachmentDiscard">
                <img src="../../assets/icons/cancel.svg" alt="" />
              </v-icon>
            </div>
            <div class="messagechat__complete" v-if="isCompleteChat">
              <p>
                This project related to this chat is finished. You can’t reply
                to this conversation.
              </p>
            </div>
            <div class="messagechat__edit" v-else>
              <div class="messagechat__icons">
                <v-icon icon="mdi mdi-attachment" @click="openFilePicker" />
                <v-icon
                  @click="isEmojiKeyboard = !isEmojiKeyboard"
                  :disabled="isLoading"
                  icon="mdi mdi-emoticon-happy-outline"
                  class="tw-cursor-pointer"
                >
                </v-icon>
              </div>
              <v-textarea
                bg-color="white"
                base-color="white"
                density="compact"
                variant="solo"
                flat
                placeholder="Reply"
                no-resize
                rows="1"
                auto-grow
                v-model="inputNewMessage"
                max-rows="8"
                @keydown.enter="saveNewMessage(true, $event)"
              >
                <template v-slot:append-inner>
                  <v-icon
                    icon="mdi-chevron-right-circle"
                    @click="saveNewMessage(false, null)"
                  />
                </template>
              </v-textarea>

              <v-file-input
                :disabled="isLoading"
                ref="fileInputRef"
                v-model="attachmentFile"
                accept="image/*"
                style="display: none"
                @change="appendMessageAttachment"
              ></v-file-input>
            </div>

            <div class="emoji__wrapper">
              <EmojiPicker
                v-if="isEmojiKeyboard"
                :native="true"
                :display-recent="true"
                @select="onSelectEmoji"
              />
            </div>
          </div>
        </div>
        <CommonLoader :loading="loading" />
      </template>
      <template v-else-if="isNewGroupMembers && !isShowMediaList" v-slot:text>
        <div class="header">
          <div class="left__block">
            <span v-if="userMessageRoomDetails?.isGroup">
              {{ groupsMemberDetails?.groupMembers?.length }}</span
            ><span @click.stop="isNewGroupMembers = !isNewGroupMembers"
              >Members</span
            >
          </div>
          <v-icon @click="closeNewGroupModal()" class="close_icon">
            <img :src="require('@/assets/icons/cancel.svg')" alt="" />
          </v-icon>
        </div>
        <div class="members__search">
          <v-text-field
            v-model:model-value="memberSearching"
            class="chatbox__search"
            prepend-inner-icon="mdi-magnify"
            variant="solo-filled"
            hide-details
            single-line
            flat
            placeholder="Search"
          ></v-text-field>
        </div>
        <div class="group__members" v-if="!isAddNewGroupMember">
          <div class="group__contact__members">
            <div
              v-if="isUserHasDeletePermission"
              class="group__add__members"
              @click="onShowAddNewMember"
            >
              <div class="add__members__icon">
                <v-icon icon="mdi-account-group" />
              </div>
              <p>Add New Member</p>
            </div>
            <div class="group__members__list" v-if="filterMemberList?.length">
              <div
                class="group_members"
                v-for="contact in filterMemberList"
                :key="contact?.id"
              >
                <div class="group__contact_details">
                  <div class="group__members__img">
                    <img
                      loading="lazy"
                      :src="
                        getImagePublicUrl(
                          contact?.type === UserMessagesGroupTypeEnum.USER
                            ? contact?.user?.profileImage
                            : contact?.userWorkStation?.profileImage
                        )
                          ? getImagePublicUrl(
                              contact?.type === UserMessagesGroupTypeEnum.USER
                                ? contact?.user?.profileImage
                                : contact?.userWorkStation?.profileImage
                            )
                          : require('@/assets/images/user-placeholder.png')
                      "
                    />
                  </div>
                  <span
                    :class="
                      (
                        userMessageRoomDetails?.isUserChat
                          ? isUserOnline(contact?.user?.id)
                          : isUserWorkStationOnline(
                              contact?.userWorkStation?.id
                            )
                      )
                        ? 'online'
                        : 'offline'
                    "
                  ></span>

                  <p>
                    {{
                      contact?.type === UserMessagesGroupTypeEnum.USER
                        ? contact?.user?.firstName +
                          " " +
                          contact?.user?.lastName
                        : contact?.userWorkStation?.name
                    }}
                  </p>
                </div>
                <div v-if="contact?.isParent" class="admin">
                  <span>admin</span>
                </div>

                <div
                  v-if="!contact.isParent && isUserHasDeletePermission"
                  class="delete"
                >
                  <span @click="deleteGroupMember(contact?.id)">delete</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="group__new__members" v-else>
          <div class="group_selector">
            <v-list dense>
              <div class="group__members__list">
                <div
                  class="group_members"
                  v-for="contact in filterMemberList"
                  :key="contact?.id"
                >
                  <div class="group__contact_details">
                    <div class="group__members__img">
                      <img
                        loading="lazy"
                        :src="
                          getImagePublicUrl(
                            contact?.type === UserMessagesGroupTypeEnum.USER
                              ? contact?.user?.profileImage
                              : contact?.userWorkStation?.profileImage
                          )
                            ? getImagePublicUrl(
                                contact?.type === UserMessagesGroupTypeEnum.USER
                                  ? contact?.user?.profileImage
                                  : contact?.userWorkStation?.profileImage
                              )
                            : require('@/assets/images/user-placeholder.png')
                        "
                      />
                    </div>
                    <span
                      :class="
                        (
                          userMessageRoomDetails?.isUserChat
                            ? isUserOnline(contact?.user?.id)
                            : isUserWorkStationOnline(
                                contact?.userWorkStation?.id
                              )
                        )
                          ? 'online'
                          : 'offline'
                      "
                    ></span>
                    <p>
                      {{
                        contact?.type === UserMessagesGroupTypeEnum.USER
                          ? contact?.user?.firstName +
                            " " +
                            contact?.user?.lastName
                          : contact?.userWorkStation?.name
                      }}
                    </p>
                  </div>
                  <div v-if="contact.isParent" class="admin">
                    <span>admin</span>
                  </div>
                  <div v-else class="add">
                    <span>added</span>
                  </div>
                </div>
                <div
                  class="group_members"
                  :key="contact?.id"
                  v-for="contact in filterGroupNetWorkContactList"
                >
                  <div class="group__contact_details">
                    <div class="group__members__img">
                      <img
                        loading="lazy"
                        :src="
                          getImagePublicUrl(
                            contact?.isUserChat
                              ? contact?.receiverWorkstation.user?.profileImage
                              : contact?.receiverWorkstation?.profileImage
                          )
                            ? getImagePublicUrl(
                                contact?.isUserChat
                                  ? contact?.receiverWorkstation.user
                                      ?.profileImage
                                  : contact?.receiverWorkstation?.profileImage
                              )
                            : require('@/assets/images/user-placeholder.png')
                        "
                      />
                    </div>

                    <span
                      :class="
                        (
                          userMessageRoomDetails?.isUserChat
                            ? isUserOnline(
                                contact?.receiverWorkstation.user?.id
                              )
                            : isUserWorkStationOnline(
                                contact?.receiverWorkstation?.id
                              )
                        )
                          ? 'online'
                          : 'offline'
                      "
                    ></span>
                    <p>
                      {{
                        contact?.isUserChat
                          ? contact?.receiverWorkstation?.user.firstName +
                            " " +
                            contact?.receiverWorkstation?.user.lastName
                          : contact?.receiverWorkstation?.name
                      }}
                    </p>
                  </div>

                  <div @click="updateSelectedItems(contact)" class="add">
                    <span class="cursor-pointer" v-if="isAddedContact(contact)"
                      >add</span
                    >
                    <span class="cursor-pointer" v-else>added</span>
                  </div>
                </div>
              </div>
            </v-list>

            <div class="submit_button">
              <v-btn
                @click="onShowAddNewMember"
                class="button button-purple-border"
                width="auto"
              >
                cancel
              </v-btn>
              <v-btn
                width="auto"
                class="button button-orange"
                :disabled="!selectedItems.length"
                @click="addNewGroupMembers"
              >
                save
              </v-btn>
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="isShowMediaList" v-slot:text>
        <div class="media-modal">
          <div class="header">
            <v-icon
              icon="mdi-chevron-left"
              @click="onClickToggleMediaModal()"
            />
            <h4>Media {{ totalMediaCount || 0 }}</h4>
            <v-icon>
              <img
                src="../../assets/icons/cancel.svg"
                alt=""
                @click="onClickToggleMediaModal()"
              />
            </v-icon>
          </div>
          <div class="media-modal__inner">
            <div class="members__search">
              <v-text-field
                v-model:model-value="mediaSearch"
                class="chatbox__search"
                prepend-inner-icon="mdi-magnify"
                variant="solo-filled"
                flat
                placeholder="Search"
              ></v-text-field>
            </div>
            <CommonLoader class="chat_loader" :loading="isLoading" />
            <p
              v-if="
                !isLoading &&
                (filterMediaList?.length == 0 ||
                  filterMediaList?.[0].mediaList.length == 0)
              "
            >
              No Data Available
            </p>

            <div class="media-modal__group-wrapper">
              <div
                class="media-modal__group"
                v-for="message in filterMediaList"
                :key="message.date"
              >
                <div
                  class="media-modal__group-date"
                  v-if="message.mediaList.length > 0"
                >
                  <p>{{ message.date }}</p>
                </div>
                <div class="media-modal__group-inner">
                  <div
                    class="media-modal__item"
                    v-for="media in message.mediaList"
                    :key="media.id"
                  >
                    <v-menu
                      :close-on-content-click="false"
                      location="end"
                      origin="auto"
                      scroll-strategy="close"
                      class="dropdown_editor"
                    >
                      <template v-slot:activator="{ props }">
                        <div v-bind="props" class="media-modal__item-menu-icon">
                          <img src="@/assets/icons/dots.svg" alt="" />
                        </div>
                      </template>

                      <div class="media-modal__item-menu">
                        <div
                          class="media-modal__item-menu-item"
                          @click="downloadAttachment(media)"
                        >
                          <v-icon icon="mdi-download"></v-icon>
                          <p>Download</p>
                        </div>
                        <div
                          class="media-modal__item-menu-item"
                          @click="deleteAttachment(media)"
                        >
                          <v-icon icon="mdi-delete"></v-icon>
                          <p>Delete</p>
                        </div>
                      </div>
                    </v-menu>

                    <div class="media-modal__item-image">
                      <img
                        loading="lazy"
                        :src="
                          media?.mediaUrl
                            ? media?.mediaUrl
                            : require('@/assets/images/media-image.jpg')
                        "
                        alt=""
                      />
                    </div>

                    <div class="media-modal__item-title">
                      <p>{{ media.originalName }}</p>
                    </div>
                    <div class="media-modal__item-info">
                      <p>
                        {{ media?.meta?.size }} <span>•</span>
                        {{
                          formatDate(media.dateCreated)
                            ? formatDate(media.dateCreated)
                            : ""
                        }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </v-card>
  </div>
</template>
<script lang="ts">
import chatService from "../services/chat.service";
import { onBeforeMount, ref, computed, onBeforeUnmount } from "vue";
import {
  documentExtensions,
  getImageApiUrl,
  getImageStringToImageURL,
  imageExtensions,
} from "@/core/utils/common";
import $axios from "@/core/utils/axios-api-config";
import CommonLoader from "./CommonLoader.vue";

import "vue3-emoji-picker/css";
import { WORKSTATION } from "@/store/modules/workstation";
import { useStore } from "vuex";
import { onMounted } from "vue";
import { inject } from "vue";
import { UserRolesEnum } from "../enums/RolesEnum";
import { USER_STORE } from "@/store/modules/user";
import UserProfileLogo from "@/core/components/UserProfileLogo.vue";
import {
  UserMessagesGroupTypeEnum,
  UserMessagesRoomStatusEnum,
} from "../enums/chatsEnums";
import { watch } from "vue";
import moment from "moment";
import { nextTick } from "vue";
import { ElMessage } from "element-plus";
import EmojiPicker from "vue3-emoji-picker";
export default {
  name: "MessageChatComponent",
  emits: ["closeMessageChatBox"],
  props: {
    userAllMessages: {
      type: Array,
    },
    receiverUserWorkstation: {
      type: Object,
    },
    senderUserWorkstation: {
      type: Object,
    },
    userMessageRoomDetails: {
      type: Object,
    },
    projectId: {
      type: Number,
    },
    userImagesMap: {
      type: Object,
    },
    groupNetWorkContactList: {
      type: Array,
    },
  },
  components: {
    CommonLoader,
    // CommonDialog,
    UserProfileLogo,
    // Picker,
    EmojiPicker,
  },
  setup(props: any, ctx: any) {
    const store = useStore();
    const isShowMessage = ref(true);
    const inputNewMessage = ref("");
    const userMessages = ref([]) as any;
    const fileInputRef = ref(null) as any;
    const chatContainer = ref(null) as any;
    const isEmojiKeyboard = ref(false);
    const previousDate = ref(null) as any;
    const attachmentFile = ref(null) as any;
    const isLoading = ref(false);
    const loading = ref(false);
    const groupsMemberDetails = ref(null) as any;
    const isNewGroupMembers = ref(false);
    const isAddNewGroupMember = ref(false);
    const selectedItems = ref([]) as any;
    const attachmentImageUrl = ref("");
    const memberSearching = ref();
    const mediaSearch = ref("");
    const attachmentDownloadLoadingArray = ref([]) as any;
    const isGroupUserChat = ref(false);
    const toggleShowText = ref(null) as any;
    // let emojiIndex = new EmojiIndex(data);
    const isCompleteChat = ref(false);
    const onSelectEmoji = (e: any) => {
      inputNewMessage.value += e.i;
    };
    const closeMessageBox = (msgId: any) => {
      ctx.emit("closeMessageChatBox", msgId);
    };
    const closeNewGroupModal = () => {
      isNewGroupMembers.value = false;
      isAddNewGroupMember.value = false;
      selectedItems.value = [];
    };
    const groupNetWorkContactList = computed(() => {
      const data = props.groupNetWorkContactList.filter((contact: any) => {
        if (contact?.isUserChat)
          return !groupsMemberDetails.value?.groupMembers.some(
            (member: any) =>
              member.type === UserMessagesGroupTypeEnum.USER &&
              member.user.id === contact.receiverWorkstation.user.id
          );
        else
          return !groupsMemberDetails.value?.groupMembers.some(
            (member: any) =>
              member.type === UserMessagesGroupTypeEnum.WORKSTATION &&
              member.userWorkStation.id === contact.receiverWorkstation.id
          );
      });

      if (groupsMemberDetails.value.isUserChat)
        return data.filter((member: any) => member.isUserChat);
      else return data.filter((member: any) => !member.isUserChat);
    });
    //filter with both users and workstation
    const filterGroupNetWorkContactList = computed(() =>
      !memberSearching.value && isAddNewGroupMember.value
        ? groupNetWorkContactList.value
        : groupNetWorkContactList.value?.filter(
            (contact: any) =>
              contact?.receiverWorkstation?.name
                .toLowerCase()
                .includes(memberSearching.value.toLowerCase().trim()) ||
              contact.receiverWorkstation?.user?.firstName
                .toLowerCase()
                .includes(memberSearching.value.toLowerCase().trim()) ||
              contact.receiverWorkstation?.user?.lastName
                .toLowerCase()
                .includes(memberSearching.value.toLowerCase().trim())
          )
    );

    const activeUserWorkstation = computed(
      () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
    );
    const isChecked = computed(() => {
      return (item: any) => selectedItems.value.includes(item);
    });
    const isAddedContact = (item: any) => {
      const index = selectedItems.value.findIndex(
        (selectedItem: any) => selectedItem === item
      );
      return index === -1;
    };

    const updateSelectedItems = (item: any) => {
      // if (value) {
      //   selectedItems.value.push(item);
      // } else {
      const index = selectedItems.value.findIndex(
        (selectedItem: any) => selectedItem === item
      );
      if (index !== -1) {
        selectedItems.value.splice(index, 1);
      } else {
        selectedItems.value.push(item);
      }
      // }
    };

    const addNewGroupMembers = async () => {
      const users = [] as any;
      selectedItems.value.map((item: any) => {
        users.push({
          isUserChat: item?.isUserChat,
          userId: item?.receiverWorkstation?.user?.id,
          workstationId: item?.receiverWorkstation?.id,
        });
      });
      try {
        const formData = {
          groupId: groupsMemberDetails.value.id,
          projectId: props.userMessageRoomDetails?.project?.id,
          users,
        };
        ctx.emit("saveNewGroupMembers", formData);
        closeNewGroupModal();
      } catch (error) {
        console.log(error);
      }
    };
    const closeEmojiPicker = (e: any) => {
      const isClickOnDotsButton = document
        .getElementById("click-outside")
        ?.contains(e.target);
      if (!isClickOnDotsButton) {
        isEmojiKeyboard.value = false;
      }
    };
    const toggleMessages = () => {
      isShowMessage.value = !isShowMessage.value;
    };
    const newMessagePayload = computed(() => {
      return {
        userMessageRoomId: props.userMessageRoomDetails?.id,
        projectId: props?.projectId,
        toWorkstationId: props.receiverUserWorkstation?.id,
        fromWorkstationId: props.senderUserWorkstation?.id,
        toUser: props.receiverUserWorkstation?.user?.id,
        fromUser: props.senderUserWorkstation?.user?.id,
        state: "unread",
        isUserChat: props.receiverUserWorkstation?.isUserChat,
      };
    });
    const getTimeElapsedString = (timestamp: any) => {
      const previous = new Date(timestamp) as any;
      const current = new Date() as any;

      const elapsed = current - previous;

      const seconds = Math.floor(elapsed / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);
      const weeks = Math.floor(days / 7);
      if (weeks > 0) {
        return weeks === 1 ? "1 week ago" : `${weeks} weeks ago`;
      } else if (days > 0) {
        return days === 1 ? "1 day ago" : `${days} days ago`;
      } else if (hours > 0) {
        return hours === 1 ? "1 hour ago" : `${hours} hours ago`;
      } else if (minutes > 0) {
        return minutes === 1 ? "1 minute ago" : `${minutes} minutes ago`;
      } else {
        return "Just now";
      }
    };
    const shouldDisplayDate = (message: any, index: any) => {
      if (index === userMessages.value.length - 1) {
        previousDate.value = new Date(message.dateCreated).getDate();
        return true;
      } else {
        const previousMessage = userMessages.value[index + 1];
        if (previousMessage) {
          const previousDay = new Date(previousMessage.dateCreated).getDate();
          const currentDay = new Date(message.dateCreated).getDate();
          if (currentDay !== previousDay) {
            previousDate.value = currentDay;
            return true;
          } else {
            return false;
          }
        }
      }
    };
    const getTheDay = (timestamp: any) => {
      const currentDate = new Date() as any;
      const messageDate = new Date(timestamp) as any;

      currentDate.setUTCHours(0, 0, 0, 0);
      messageDate.setUTCHours(0, 0, 0, 0);

      const differenceInTime = currentDate.getTime() - messageDate.getTime();
      const differenceInDays = Math.floor(
        differenceInTime / (1000 * 3600 * 24)
      );

      if (differenceInDays === 0) {
        return "Today";
      } else if (differenceInDays === 1) {
        return "Yesterday";
      } else {
        const options = { month: "long", day: "numeric" };
        return messageDate.toLocaleDateString("en-US", options);
      }
    };

    const downloadAttachment = async (data: any) => {
      const { attachment, originalName, id } = data;
      attachmentDownloadLoadingArray.value.push(id);
      const imageUrl = getImageApiUrl(attachment, true);

      try {
        const response: any = await $axios.get(imageUrl);
        const blob = new Blob([new Uint8Array(response.Body.data)]);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = originalName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      } catch (error) {
        console.error("Error downloading image:", error);
      } finally {
        attachmentDownloadLoadingArray.value = [
          ...attachmentDownloadLoadingArray.value.filter(
            (el: any) => el !== id
          ),
        ];
      }
    };
    const deleteAttachment = async (message: any) => {
      try {
        if (!message) return;

        messageMediaList.value = messageMediaList.value.map((entry: any) => {
          entry.mediaList = entry.mediaList.filter(
            (media: any) => media.id !== message.id
          );
          return entry;
        });

        userMessages.value = userMessages.value.filter(
          (data: any) => data.id !== message.messageId
        );

        await chatService.deleteUserMessage(message.messageId);
      } catch (error) {
        console.log("error", error);
      }
    };

    const formatDate = (dateString: any) => {
      const date = dateString ? moment(dateString) : moment();
      return date.format("DD-MM-YYYY HH:mm");
    };
    const saveNewMessage = async (isEvent: boolean, event: any) => {
      if (isEvent) {
        if (event.shiftKey) {
          return;
        } else {
          event.preventDefault();
        }
      }
      let attachedFile = attachmentFile.value;
      attachmentFile.value = null;
      if (!attachedFile && inputNewMessage.value.trim() === "") {
        return;
      }

      const payload = {
        ...newMessagePayload.value,
        messageId: `${new Date().getTime()}`,
        content: inputNewMessage.value,
      };
      inputNewMessage.value = "";
      try {
        let responseMessage = (await chatService.createUserMessage(
          payload
        )) as any;
        if (!responseMessage) return;

        scrollBottom();

        if (attachedFile && responseMessage) {
          const fileExtension = attachedFile.name
            .split(".")
            .pop()
            .toLowerCase();

          const validExtensions = [...imageExtensions, ...documentExtensions];
          // Check if the file extension is in the allowed list
          if (!validExtensions.includes(fileExtension)) {
            ElMessage.error("Invalid file type. Please select a valid file");
            return;
          }

          const userWorkstationId = props.senderUserWorkstation?.id;
          const userMessageRoomId = props.userMessageRoomDetails?.id;
          const userMessageId = responseMessage?.id;
          const formData = new FormData();
          formData.append("file", attachedFile);
          responseMessage.userMessageRoom.userMessageAttachments = [];
          responseMessage.userMessageRoom.userMessageAttachments.push({
            originalName: attachedFile.name,
          });
          userMessages.value.unshift(responseMessage);
          isLoading.value = true;
          const attachmentResponse =
            (await chatService.createUserMessageRoomAttachment(
              userWorkstationId,
              userMessageRoomId,
              userMessageId,
              formData
            )) as any;
          isLoading.value = false;
          if (attachmentResponse) {
            const findMessage = userMessages.value.find(
              (msg: any) => msg.id === userMessageId
            );
            findMessage.userMessageRoom.userMessageAttachments = [];
            findMessage.userMessageRoom.userMessageAttachments.push(
              attachmentResponse
            );
          }
        } else {
          userMessages.value.unshift(responseMessage);
        }
      } catch (error) {
        console.log(error);
      } finally {
        if (attachedFile) {
          await setMessageMediaList();
        }
        attachedFile = null;
        isLoading.value = false;
      }

      inputNewMessage.value = "";
    };

    const getImagePublicUrl = (profileImage: string | null) => {
      if (!profileImage) return require("@/assets/images/user-placeholder.png");

      const imgUrl = props.userImagesMap[profileImage];
      if (!imgUrl) return require("@/assets/images/user-placeholder.png");

      return imgUrl;
    };

    const pusher = inject("pusher") as any;
    const subscribeGroupChannelList = ref([]) as any;
    const unbindSubscribeGroupChannelList = () => {
      if (subscribeGroupChannelList.value.length) {
        subscribeGroupChannelList.value.forEach((channel: any) => {
          // Unbind all events from channel
          channel?.unbind();
          pusher?.unsubscribe(channel.name);
        });
        subscribeGroupChannelList.value.length = [];
      }
    };
    const subscribeGroupMessage = () => {
      const roomChannel = pusher?.subscribe(
        `group-messages-${props.userMessageRoomDetails?.id}`
      );
      roomChannel
        ?.unbind("group.new.member")
        ?.bind("group.new.member", async (newGroupMembers: any) => {
          if (newGroupMembers) {
            ////Todo : add member instead of call api
            await getUserGroupMembers(props.userMessageRoomDetails?.id);
          }
        });
      roomChannel
        ?.unbind("group.delete.member")
        ?.bind("group.delete.member", async (newGroupMembers: any) => {
          if (newGroupMembers) {
            //Todo : filter member instead of call api
            await getUserGroupMembers(props.userMessageRoomDetails?.id);
          }
        });
      subscribeGroupChannelList.value.push(roomChannel);
    };

    const getUserMessages = async (messageRoomId: number) => {
      try {
        loading.value = true;
        const messages = (await chatService.getUserMessages(
          messageRoomId
        )) as any;
        return messages;
      } catch (error) {
        console.log();
      } finally {
        loading.value = false;
      }
    };

    const scrollBottom = () => {
      nextTick(() => {
        const container = chatContainer.value;
        container.scrollTop = container.scrollHeight;
      });
    };
    watch(
      () => props.userAllMessages,
      async (value: any) => {
        if (value && value.length) {
          let latestMessages = props.userAllMessages.map((msg: any) => {
            return {
              ...msg,
              unReadMsg: 0,
            };
          });
          latestMessages = latestMessages.filter((msg: any) => {
            const isExits = userMessages.value.find(
              (data: any) => data.id === msg?.id
            );
            if (!isExits) return true;
          });
          userMessages.value = [...latestMessages, ...userMessages.value];
          scrollBottom();
        }
      },

      { immediate: true, deep: true }
    );
    const onClickVideoCallButton = (msgId: number) => {
      if (!props.userMessageRoomDetails.isGroup) {
        ctx.emit(
          "onClickVideoCallButton",
          msgId,
          props.senderUserWorkstation.id,
          props.receiverUserWorkstation.id,
          null
        );
      } else {
        ctx.emit(
          "onClickVideoCallButton",
          msgId,
          props.senderUserWorkstation.id,
          null,
          props.userMessageRoomDetails?.parentId ||
            props.userMessageRoomDetails?.id
        );
      }
    };

    const openFilePicker = () => {
      fileInputRef.value.click();
    };
    const appendMessageAttachment = async (event: any) => {
      const fileInput = event.target;

      if (fileInput.files && fileInput.files.length > 0) {
        const file = fileInput.files[0];
        attachmentImageUrl.value = URL.createObjectURL(file);
        if (file.size <= 200 * 1024 * 1024) {
          attachmentFile.value = file;
        } else {
          // errorMessage.value = "file size exceeds the limit (200MB).";
        }
      }
    };

    const formatGroupNames = computed(() => {
      if (props.userMessageRoomDetails?.isGroup && groupsMemberDetails.value) {
        const groupMembers = groupsMemberDetails?.value?.groupMembers;
        const names = groupMembers.map(
          (member: any) => member?.userWorkStation.name
        );
        return names.join(", ");
      }
      return "";
    });

    const groupAdminUser = computed(() =>
      groupsMemberDetails.value?.groupMembers.find(
        (member: any) => member?.isParent
      )
    );

    const isUserHasDeletePermission = computed(
      () =>
        activeUserWorkstation.value?.id ===
          groupAdminUser.value?.userWorkStation.id &&
        user.value.id === groupAdminUser.value?.user.id
    );

    const getUserGroupMembers = async (groupId: number) => {
      try {
        const response = (await chatService.getAllGroupMembers(groupId)) as any;

        const adminUser = response?.groupMembers.find(
          (member: any) => member.isParent
        );

        const isExistUserInGroup = response?.groupMembers.find(
          (member: any) =>
            member.type === UserMessagesGroupTypeEnum.USER &&
            user.value.id === member?.user.id
        );
        if (isExistUserInGroup) {
          isGroupUserChat.value = true;
        } else isGroupUserChat.value = false;

        response.groupMembers = [
          adminUser,
          ...response?.groupMembers.filter((member: any) => !member.isParent),
        ];

        groupsMemberDetails.value = response;
        for (const member of groupsMemberDetails.value?.groupMembers) {
          if (member.user?.profileImage)
            ctx.emit(
              "getUserWorkStationProfileImage",
              member.user?.profileImage
            );

          if (member.userWorkStation?.profileImage)
            ctx.emit(
              "getUserWorkStationProfileImage",
              member.userWorkStation.profileImage
            );

          // if (member.user?.profileImage)
          // ctx.emit(
          //     "getUserWorkStationProfileImage",
          //     member.user?.profileImage
          //   );
        }
      } catch (error) {
        console.log("error", error);
      }
    };
    const onAttachmentDiscard = () => {
      attachmentFile.value = null;
    };

    const deleteGroupMember = async (groupMemberId: number) => {
      try {
        groupsMemberDetails.value.groupMembers =
          groupsMemberDetails.value?.groupMembers.filter(
            (room: any) => room.id !== groupMemberId
          );

        await chatService.deleteGroupMember(groupMemberId);
      } catch (error) {
        console.log(error);
      }
    };
    const user = computed(() => store.getters[`${USER_STORE}/user`]);
    const isProjectOwner = computed(() => {
      if (user.value?.role?.name === UserRolesEnum.PROPERTY_OWNER) {
        return true;
      }
      return false;
    });

    const isProjectChatCompleted = computed(() => {
      return (
        props.userMessageRoomDetails.projectStatus ===
          UserMessagesRoomStatusEnum.COMPLETED &&
        props.userMessageRoomDetails.project
      );
    });

    const onlineUser = computed(
      () => store.getters[`${WORKSTATION}/getUserOnlineWorkStations`]
    );
    const isUserWorkStationOnline = (wsId: number) => {
      const isExist = onlineUser.value?.find(
        (data: any) => data?.userWorkstations?.id === wsId
      );
      return isExist ? true : false;
    };
    const isUserOnline = (userId: number) => {
      return onlineUser.value?.find((user: any) => user.id == userId)
        ? true
        : false;
    };

    const isShowMediaList = ref(false);
    const messageMediaList = ref([]) as any;
    const onClickToggleMediaModal = () => {
      isShowMediaList.value = !isShowMediaList.value;
    };

    const setMessageMediaList = async () => {
      const updatedMessage = await Promise.all(
        userMessages.value.map(async (message: any) => ({
          date: moment(message.dateCreated).format("DD MMMM, YYYY"),
          mediaList: message?.userMessageRoom?.userMessageAttachments?.length
            ? await Promise.all(
                message?.userMessageRoom?.userMessageAttachments.map(
                  async (attachment: any) => ({
                    ...attachment,
                    messageId: message.id,
                    meta: attachment?.meta
                      ? JSON.parse(attachment?.meta)
                      : null,
                    mediaUrl: await getImageStringToImageURL(
                      attachment?.attachment
                    ),
                  })
                )
              )
            : [],
        }))
      );
      const messageGroupByDate = updatedMessage.reduce(
        (newObj: any, message: any) => {
          if (!message.date) return newObj;
          if (newObj[message.date]) {
            newObj[message.date] = [
              ...newObj[message.date],
              ...message.mediaList,
            ];
          } else {
            newObj[message.date] = message.mediaList;
          }
          return newObj;
        },
        {}
      );
      messageMediaList.value = Object.entries(messageGroupByDate).map(
        (message: any) => ({
          date: message[0],
          mediaList: message[1],
        })
      );
    };
    const filterMediaList = computed(() =>
      !mediaSearch.value
        ? messageMediaList.value
        : messageMediaList.value.reduce((newArr: any, media: any) => {
            const filterMedia = media?.mediaList.filter((image: any) =>
              image.originalName
                .toLowerCase()
                .includes(mediaSearch.value.toLowerCase().trim())
            );
            if (filterMedia.length) {
              newArr.push({ ...media, mediaList: filterMedia });
            }
            return newArr;
          }, [])
    );
    const totalMediaCount = computed(() =>
      messageMediaList.value.reduce((mediaCount: any, media: any) => {
        mediaCount += media?.mediaList.length;
        return mediaCount;
      }, 0)
    );

    const filterMemberList = computed(() => {
      let memberList = groupsMemberDetails.value?.groupMembers;
      if (groupsMemberDetails.value?.isUserChat) {
        memberList = memberList.filter(
          (member: any) => member.type === UserMessagesGroupTypeEnum.USER
        );
      }

      return !memberSearching.value
        ? memberList
        : memberList?.filter((member: any) =>
            member?.userWorkStation?.name
              .toLowerCase()
              .includes(memberSearching.value.toLowerCase().trim())
          );
    });
    const onShowMember = () => {
      isNewGroupMembers.value = !isNewGroupMembers.value;
      memberSearching.value = "";
    };
    const onShowAddNewMember = () => {
      selectedItems.value = [];
      isAddNewGroupMember.value = !isAddNewGroupMember.value;
      memberSearching.value = "";
    };
    onMounted(() => {
      if (props.userMessageRoomDetails.isGroup) {
        subscribeGroupMessage();
      }
    });

    const countLines = (description: string) => {
      let words = description?.trim().split(" ");
      let maxLength = window.innerWidth < 720 ? 10 : 18;

      if (words?.length > maxLength) {
        return true;
      }

      return false;
    };

    const onClickToggleShowText = (itemId: number) => {
      if (toggleShowText.value === itemId) {
        toggleShowText.value = null;
      } else {
        toggleShowText.value = itemId;
      }
    };

    onBeforeUnmount(() => {
      unbindSubscribeGroupChannelList();
    });

    onBeforeMount(async () => {
      let messageRoomId = props.userMessageRoomDetails?.id;
      const getAllMessages = await getUserMessages(messageRoomId);
      userMessages.value = getAllMessages?.map((msg: any) => {
        return {
          ...msg,
          unReadMsg: 0,
        };
      });

      const unreadMessagesCount =
        props.userMessageRoomDetails?.unReadMessageCount;
      if (unreadMessagesCount) {
        const total = userMessages.value.length;
        userMessages.value[unreadMessagesCount - 1].unReadMsg =
          unreadMessagesCount;
      }

      if (
        props.userMessageRoomDetails &&
        props.userMessageRoomDetails.isGroup
      ) {
        try {
          isLoading.value = true;
          const groupId = props.userMessageRoomDetails.id;
          await getUserGroupMembers(groupId);
        } catch (error) {
          console.log(error);
        } finally {
          isLoading.value = false;
        }
      }

      setMessageMediaList();
    });
    return {
      formatGroupNames,
      isShowMessage,
      closeMessageBox,
      toggleMessages,
      userMessages,
      inputNewMessage,
      saveNewMessage,
      getTimeElapsedString,
      appendMessageAttachment,
      openFilePicker,
      fileInputRef,
      attachmentFile,
      isEmojiKeyboard,
      onSelectEmoji,
      downloadAttachment,
      getTheDay,
      shouldDisplayDate,
      closeEmojiPicker,
      getImagePublicUrl,
      isLoading,
      groupsMemberDetails,
      isNewGroupMembers,
      isAddNewGroupMember,
      closeNewGroupModal,
      isChecked,
      updateSelectedItems,
      activeUserWorkstation,
      filterGroupNetWorkContactList,
      addNewGroupMembers,
      selectedItems,
      onAttachmentDiscard,
      attachmentDownloadLoadingArray,
      deleteGroupMember,
      isProjectOwner,
      isProjectChatCompleted,
      isUserOnline,
      attachmentImageUrl,
      loading,
      onClickVideoCallButton,
      onClickToggleMediaModal,
      isShowMediaList,
      messageMediaList,
      isUserHasDeletePermission,
      isAddedContact,
      memberSearching,
      filterMemberList,
      onShowMember,
      onShowAddNewMember,
      mediaSearch,
      filterMediaList,
      totalMediaCount,
      user,
      UserMessagesGroupTypeEnum,
      isGroupUserChat,
      countLines,
      formatDate,
      onClickToggleShowText,
      toggleShowText,
      chatContainer,
      deleteAttachment,
      // emojiIndex,
      isCompleteChat,
      isUserWorkStationOnline,
    };
  },
};
</script>
<style lang="scss" scoped>
.messagechat__wrapper {
  width: 400px;
  height: 56.5px;
  max-height: 100%;
  transition: 0.5s all ease;

  @include respond(s720) {
    width: 100%;
    max-width: 100%;
  }

  .messagechat__inner {
    border-radius: 8px;
    //padding: 10px 16px 0 12px;
    position: relative;
    height: 100%;
    box-shadow: 0px 0px 8px 0px rgba(12, 15, 74, 0.4);
    transition: 0.5s all ease;
    // @include respond(xlg) {
    //   padding: 12px 16px 0;
    // }

    // @include respond(s720) {
    //   padding-top: 7px;
    // }

    :deep(.v-card-text) {
      padding: 0;
      height: 100%;
      display: flex;
      flex-direction: column;
      .header {
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 16px;
        padding-top: 6px;
        border-bottom: 1px solid rgba($blueDark, 0.18);
        .left__block {
          @include fluidFont(14, 14, 1.3);
          font-weight: 700;
          color: rgba($blueDark, 1);
          letter-spacing: 0.25px;
          display: flex;
          gap: 4px;
          align-items: center;
        }
        .close_icon {
          width: 20px;
          height: 20px;
          border: 1px solid #0c0f4a;
          border-radius: 100%;
        }
      }
      .members__search {
        padding: 16px 0;
        margin-bottom: 15px;
        border-bottom: 1px solid rgba($blueDark, 0.18);
        .chatbox__search {
          .v-input__control {
            .v-field {
              border-radius: 9px;
              .v-field__input {
                padding: 0;
                min-height: unset;
                @include fluidFont(12, 12, 1.2);
                font-weight: 400;
                color: rgba($blueDark, 1);
              }
              .v-field__field {
                label {
                  @include fluidFont(12, 12, 1);
                  color: rgba(142, 142, 147, 1);
                }
              }
            }
            .v-field--prepended {
              padding: 0;
              border-color: rgba(0, 0, 0, 0.04);
              display: flex;
              align-items: center;
              height: 38px;
              padding: 8px 16px;
              gap: 8px;
              border-radius: 9px;
            }
          }
        }
      }
      .group__members {
        .group__contact__members {
          .group__add__members {
            margin-bottom: 16px;
            padding: 10px;
            background-color: #ffa500;
            border-radius: 6px;
            display: flex;
            gap: 8px;
            align-items: center;
            cursor: pointer;

            @include respond(s720) {
              padding: 6px 10px;
            }

            .add__members__icon {
              width: 30px;
              height: 30px;
              position: relative;
              border-radius: 100%;
              overflow: hidden;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: #fff;

              .v-icon {
                color: #ffa500;
              }
            }
            p {
              font-size: 14px !important;
              line-height: 25px;
              color: #fff;
              font-weight: 700;
              letter-spacing: 0.25px;
            }
          }
          .group__members__list {
            padding-top: 14px;
            display: flex;
            flex-direction: column;
            gap: 16px;
            max-height: 460px;
            overflow: auto;
            .group_members {
              display: flex;
              justify-content: space-between;
              align-items: center;
              .group__contact_details {
                display: flex;
                gap: 8px;
                align-items: center;
                width: 80%;
                max-width: 100%;
                position: relative;
                .group__members__img {
                  width: 24px;
                  height: 24px;
                  border-radius: 100%;
                  overflow: hidden;
                  img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                  }
                }
                .online {
                  position: absolute;
                  width: 8px;
                  height: 8px;
                  border: 1px solid rgba($white, 1);
                  border-radius: 100%;
                  background-color: rgba(59, 199, 64, 1);
                  left: 17px;
                  top: 0;
                }
                .offline {
                  position: absolute;
                  width: 8px;
                  height: 8px;
                  border: 1px solid rgba($white, 1);
                  border-radius: 100%;
                  background-color: rgba(255, 54, 54, 1);
                  left: 17px;
                  top: 0;
                }
                p {
                  @include fluidFont(12, 12, 1.3);
                  font-weight: 700;
                  color: rgba($blueDark, 1);
                  letter-spacing: 0.25px;
                  width: calc(100% - 30px);
                  max-width: 100%;
                  text-align: left;
                }
              }
              .admin {
                background-color: rgba($PrimaryBlue, 0.1);
                padding: 3px 10px;
                border-radius: 4px;
                span {
                  @include fluidFont(10, 10, 1.3);
                  font-weight: 700;
                  color: rgba($PrimaryBlue, 1);
                  letter-spacing: 0.25px;
                  text-transform: uppercase;
                }
              }
              .add {
                span {
                  @include fluidFont(10, 10, 1.3);
                  font-weight: 400;
                  color: rgba($blueDark, 1);
                  letter-spacing: 0.15px;
                  text-transform: capitalize;
                  cursor: pointer;
                }
              }
              .delete {
                span {
                  @include fluidFont(10, 10, 1.3);
                  font-weight: 400;
                  color: rgba(255, 54, 54, 1);
                  letter-spacing: 0.15px;
                  text-transform: capitalize;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
      .group__new__members {
        .group_selector {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;

          .v-list {
            height: 500px;
            max-height: 450px;
            overflow: auto;
          }

          .group__members__list {
            padding-top: 14px;
            display: flex;
            flex-direction: column;
            gap: 16px;
            max-height: 520px;
            overflow: auto;
            .group_members {
              display: flex;
              justify-content: space-between;
              align-items: center;
              .group__contact_details {
                display: flex;
                gap: 8px;
                align-items: center;
                width: 80%;
                max-width: 100%;
                position: relative;
                .group__members__img {
                  width: 24px;
                  height: 24px;
                  border-radius: 100%;
                  overflow: hidden;
                  img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                  }
                }
                .online {
                  position: absolute;
                  width: 8px;
                  height: 8px;
                  border: 1px solid rgba($white, 1);
                  border-radius: 100%;
                  background-color: rgba(59, 199, 64, 1);
                  left: 17px;
                  top: 0;
                }
                .offline {
                  position: absolute;
                  width: 8px;
                  height: 8px;
                  border: 1px solid rgba($white, 1);
                  border-radius: 100%;
                  background-color: rgba(255, 54, 54, 1);
                  left: 17px;
                  top: 0;
                }
                p {
                  @include fluidFont(12, 12, 1.3);
                  font-weight: 700;
                  color: rgba($blueDark, 1);
                  letter-spacing: 0.25px;
                  width: calc(100% - 30px);
                  max-width: 100%;
                  text-align: left;
                }
              }
              .admin {
                background-color: rgba($PrimaryBlue, 0.1);
                padding: 3px 10px;
                border-radius: 4px;
                span {
                  @include fluidFont(10, 10, 1.3);
                  font-weight: 700;
                  color: rgba($PrimaryBlue, 1);
                  letter-spacing: 0.25px;
                  text-transform: uppercase;
                }
              }
              .add {
                span {
                  @include fluidFont(10, 10, 1.3);
                  font-weight: 400;
                  color: rgba($blueDark, 1);
                  letter-spacing: 0.15px;
                  text-transform: capitalize;
                }
                .cursor-pointer {
                  cursor: pointer;
                }
              }
              .delete {
                span {
                  @include fluidFont(10, 10, 1.3);
                  font-weight: 400;
                  color: rgba(255, 54, 54, 1);
                  letter-spacing: 0.15px;
                  text-transform: capitalize;
                }
              }
            }
          }
          .submit_button {
            display: flex;
            justify-content: center;
            gap: 16px;
            align-items: center;
          }
        }
      }
      .media-modal {
        width: 100%;
        max-width: 100%;
        height: 660px;
        display: flex;
        flex-direction: column;

        @media screen and (max-width: 1279px) {
          margin-left: -16px;
          width: calc(100% + 32px);
        }

        @include respond(s720) {
          height: 450px;
        }

        .header {
          justify-content: flex-start;
          background-color: #f8f8f8 !important;
          border: 0;
          padding: 15px 15px 15px 10px;

          h4 {
            flex: auto;
            text-align: left;
          }
        }

        .media-modal__inner {
          padding: 16px;
          display: grid;
          flex-direction: column;
          flex: auto;
        }

        .members__search {
          padding: 0;
          border-bottom: 0;
          .chatbox__search {
            .v-input__control {
              .v-field {
                border-radius: 9px;
                .v-field__input {
                  padding: 0;
                  min-height: unset;
                  @include fluidFont(12, 12, 1);
                  font-weight: 400;
                  color: rgba($blueDark, 1);
                }
                .v-field__field {
                  label {
                    @include fluidFont(12, 12, 1);
                    color: rgba(142, 142, 147, 1);
                    margin-left: 0;
                  }
                }
              }
              .v-field--prepended {
                padding: 0;
                border-color: rgba(0, 0, 0, 0.04);
                display: flex;
                align-items: center;
                height: 38px;
                padding: 8px 16px;
                gap: 8px;
                border-radius: 9px;
              }
            }
          }
        }
        .media__wrapper {
          max-height: 500px;
          overflow: auto;
          .media__wrapper__date {
            text-align: left;
            margin-top: 24px;
            p {
              @include fluidFont(14, 14, 1.3);
              font-weight: 500;
              color: rgba($blueDark, 0.5);
              letter-spacing: 0.25px;
            }
            .media__list {
              display: flex;
              flex-wrap: wrap;
              gap: 8px;
              align-items: flex-start;
              margin-top: 24px;
              .media__list__item {
                width: 135px;
                max-width: 100%;
                border-radius: 8px;
                overflow: hidden;
                height: 135px;
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }
            }
          }
        }

        .media-modal__group {
          padding: 20px 0;

          &-wrapper {
            height: 530px;
            overflow: auto;
            padding: 0 8px;

            @media screen and (max-width: 1279px) {
              height: 540px;
            }

            @include respond(s720) {
              height: 330px;
            }

            &::-webkit-scrollbar {
              width: 0;
            }
          }

          &:not(:last-child) {
            border-bottom: 0.66px dashed rgba(134, 135, 165, 1);
          }

          &-date {
            text-align: left;
            @include fluidFont(14, 14, 1);
            color: #0c0f4a;
            margin-bottom: 15px;
            font-weight: 500;
          }

          &-inner {
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            grid-gap: 20px;

            @media screen and (max-width: 1279px) {
              grid-gap: 10px;
            }
          }
        }

        &__item {
          box-shadow: 0px 0px 8px 0px #00000014;
          background-color: #fff;
          border-radius: 8px;
          padding: 14px;
          text-align: left;

          &-menu {
            &-icon {
              width: 16px;
              height: 16px;
              border-radius: 2px;
              background-color: #fff;
              margin-left: auto;
              margin-bottom: 6px;
              display: flex;
              align-items: center;
              justify-content: center;
              box-shadow: 0px 0px 8px 0px #00000014;
              padding: 2px;
              cursor: pointer;

              img {
                width: 100%;
                height: auto;
                object-fit: contain;
              }
            }
          }

          &-image {
            position: relative;
            height: 0;
            padding-bottom: 45%;
            width: 100%;
            border-radius: 4px;
            overflow: hidden;
            margin-bottom: 8px;

            img {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          &-title {
            @include fluidFont(13, 13, 20px);
            color: #616161;
            font-weight: 600;
          }

          &-info {
            margin-top: 4px;
            @include fluidFont(10, 10, 12px);
            color: rgba(36, 36, 36, 0.5);

            span {
              margin: 0 5px;
            }
          }
        }
      }
      .messagechat__top {
        display: flex;
        justify-content: space-between;
        // align-items: flex-start;
        align-items: center;
        padding: 12px;
        border-bottom: 1px solid rgba($blueDark, 0.18);
        cursor: pointer;
        // height: 47px;
        // flex: 0 0 47px;
        height: 54px;
        flex: 0 0 56px;
        gap: 20px;
        position: relative;

        @include respond(s720) {
          align-items: center;
          // height: 50px;
          // flex: 0 0 50px;
          margin-top: -5px;
        }

        &::before {
          content: "";
          position: absolute;
          left: -12px;
          top: -10px;
          width: calc(100% + 16px + 12px);
          height: calc(100% + 10px);
          background-color: #f8f8f8;
          pointer-events: none;

          @include respond(xlg) {
            left: -16px;
            top: -12px;
            width: calc(100% + 32px);
            height: calc(100% + 12px);
          }
        }

        @media screen and (max-width: 1279px) {
          // padding: 0 0 10px;
          // padding: 0;
        }

        .messagechat__left {
          display: flex;
          gap: 8px;
          align-items: flex-start;
          position: relative;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          flex: auto;
          max-width: 100%;

          &.with-group {
            align-items: center;
          }

          .messagechat__img {
            width: 24px;
            height: 24px;
            flex-shrink: 0;
            border-radius: 100%;
            overflow: hidden;
            margin-top: 2px;

            .online {
              width: 10px;
              height: 10px;
              position: absolute;
              left: 15px;
              top: 0;
              border-radius: 100%;
              background-color: #3bc740;
              border: 1px solid rgba($white, 1);
            }
            .offline {
              width: 10px;
              height: 10px;
              position: absolute;
              left: 15px;
              top: 0;
              border-radius: 100%;
              background-color: rgba($errorRed, 1);
              border: 1px solid rgba($white, 1);
            }

            img {
              width: 100%;
              max-width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .msg__notification {
            width: 8px;
            height: 8px;
            background-color: rgba($success, 1);
            border: 1px solid rgba($white, 1);
            border-radius: 100%;
            position: absolute;
            left: 18px;
            top: 0;
          }

          .messagechat__heading {
            text-align: left;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            .messagechat__heading__title {
              display: inline-flex;
              gap: 10px;
              align-items: center;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              width: 100%;

              h5 {
                @include fluidFont(16, 16, 1);
                line-height: normal;
                font-weight: 700;
                letter-spacing: 0.25px;
                color: rgba($blueDark, 1);
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
              }
              .v-icon {
                @include fluidFont(16, 16, 1.2);
                color: rgba($blueDark, 1);
              }
            }

            p {
              @include fluidFont(14, 14, 1);
              font-weight: 400;
              letter-spacing: 0.25px;
              color: rgba($blueDark, 0.6);
              margin-top: 0;
            }
            .group_names {
              margin-top: 3px;
              display: flex;
              gap: 4px;
              align-items: center;
              span {
                @include fluidFont(12, 12, 1.3);
                font-weight: 500;
                letter-spacing: 0.25px;
                color: rgba($PrimaryPurple, 1);
                white-space: nowrap;
                display: inline-block;
              }
            }
          }
        }

        .messagechat__right {
          margin-top: -2px;
          flex-shrink: 0;

          .chat_image_display {
            padding: 0;
            opacity: 1;
            .v-ripple__container {
              display: none;
            }
          }
          .close_icon {
            width: 14px;
            height: 14px;
            border-radius: 100%;

            img {
              width: 100%;
              height: auto;
              object-fit: contain;
            }
          }

          .video-call-image {
            img {
              max-width: 14px;
              object-fit: contain;
            }
          }
        }
      }

      .messagechat__section {
        height: calc(100% - 50px);
        display: flex;
        flex-direction: column;
        flex: auto;

        .messagechat__bottom {
          height: auto;
          flex: auto;
          overflow: auto;
          display: flex;
          flex-direction: column-reverse;
          justify-content: end;
          gap: 12px;
          padding: 12px;
          .nomessage__wrapper {
            display: flex;
            flex-direction: column;
            gap: 230px;
            margin-bottom: 12px;
            .nomessage__text {
              .comment__icon {
                img {
                  filter: invert(9%) sepia(24%) saturate(7413%)
                    hue-rotate(229deg) brightness(86%) contrast(106%);
                  opacity: 0.5;
                }
              }
              span {
                @include fluidFont(14, 14, 1.3);
                font-weight: 400;
                color: rgba($blueDark, 0.5);
                letter-spacing: 0.25px;
              }
            }
            .nomessage__note {
              padding: 4px;
              background-color: rgba($black, 0.04);
              border-radius: 6px;
              max-width: 85%;
              margin: 0 auto;
              p {
                @include fluidFont(11, 11, 1.3);
                font-weight: 400;
                color: rgba($blueDark, 0.5);
                letter-spacing: 0.25px;
              }
            }
          }
          .messagechat__bottom__chats {
            .right_messages {
              .message__date {
                margin: 10px 0;
                position: relative;
                p {
                  @include fluidFont(12, 12, 1.2);
                  font-weight: 400;
                  color: rgba($blueDark, 1);
                  border-radius: 11px;
                  background-color: rgba($blueDark, 0.04);
                  display: inline-block;
                  padding: 4px 12px;
                }
              }
              .messagechat__bottom__chats__list {
                display: flex;
                gap: 8px;
                align-items: center;
                flex-direction: row-reverse;
                .messagechat__left {
                  width: 24px;
                  max-width: 100%;
                  position: relative;

                  .messagechat__img {
                    width: 24px;
                    height: 24px;
                    border-radius: 100%;
                    overflow: hidden;

                    .online {
                      width: 10px;
                      height: 10px;
                      position: absolute;
                      right: 0;
                      top: -3px;
                      border-radius: 100%;
                      background-color: #3bc740;
                      border: 1px solid rgba($white, 1);
                    }
                    .offline {
                      width: 10px;
                      height: 10px;
                      position: absolute;
                      right: 0;
                      top: -3px;
                      border-radius: 100%;
                      background-color: rgba($errorRed, 1);
                      border: 1px solid rgba($white, 1);
                    }
                    img {
                      width: 100%;
                      max-width: 100%;
                      height: 100%;
                      object-fit: cover;
                    }
                  }
                }

                .messagechat__center {
                  padding: 0;
                  display: block;
                  text-align: left;
                  border: none;

                  .messagechat__heading {
                    h6 {
                      @include fluidFont(12, 12, 1);
                      font-weight: 700;
                      letter-spacing: 0.25px;
                      color: $blueDark;
                      max-width: 170px;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                      overflow: hidden;
                    }
                  }
                }

                .messagechat__right {
                  margin-right: auto;

                  span {
                    @include fluidFont(12, 12, 1);
                    font-weight: 400;
                    letter-spacing: 0.25px;
                    color: rgba($blueDark, 0.54);
                  }
                }
              }
              .messagechat__bottom__chats__text {
                margin-right: auto;
                margin-left: 0;
                max-width: 91%;
                p {
                  text-align: right;
                  max-width: 100%;
                  text-overflow: unset;
                  @include fluidFont(12, 12, 1.7);
                  font-weight: 600;
                  color: rgba($blueDark, 1);

                  span {
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 3;
                  }
                }

                &.toggle-text {
                  p {
                    span {
                      -webkit-line-clamp: unset;
                    }
                  }
                }

                .message-toggle {
                  color: #4f55f0;
                  cursor: pointer;
                  user-select: none;
                }

                .messagechat_attachment_wrapper {
                  text-align: right;
                  .messagechat_attachment {
                    display: inline-flex;
                    max-width: 185px;
                    gap: 5px;
                    padding: 6px 10px;
                    border-radius: 6px;
                    background-color: rgba($black, 0.04);

                    .download__icon {
                      cursor: pointer;
                    }
                    .chat_loader {
                      width: 20px;
                      height: 20px;
                      overflow: hidden;
                      position: absolute;
                      left: 50%;
                      top: 0;
                      transform: translate(-50%, 0);
                      .v-progress-circular {
                        width: 20px !important;
                        height: 20px !important;
                      }
                    }

                    p {
                      word-break: break-word;
                      overflow: hidden;
                      display: -webkit-box;
                      -webkit-box-orient: vertical;
                      -webkit-line-clamp: 2;
                    }
                  }
                }
              }
            }
            .left_messages {
              .message__date {
                margin: 10px 0;
                position: relative;
                p {
                  @include fluidFont(12, 12, 1.2);
                  font-weight: 400;
                  color: rgba($blueDark, 1);
                  border-radius: 11px;
                  background-color: rgba($blueDark, 0.04);
                  display: inline-block;
                  padding: 4px 12px;
                }
              }
              .messagechat__bottom__chats__list {
                display: flex;
                gap: 8px;
                align-items: center;

                .messagechat__left {
                  width: 24px;
                  max-width: 100%;
                  position: relative;

                  .messagechat__img {
                    width: 24px;
                    height: 24px;
                    border-radius: 100%;
                    overflow: hidden;

                    .online {
                      width: 10px;
                      height: 10px;
                      position: absolute;
                      right: 0;
                      top: -3px;
                      border-radius: 100%;
                      background-color: #3bc740;
                      border: 1px solid rgba($white, 1);
                    }
                    .offline {
                      width: 10px;
                      height: 10px;
                      position: absolute;
                      right: 0;
                      top: -3px;
                      border-radius: 100%;
                      background-color: rgba($errorRed, 1);
                      border: 1px solid rgba($white, 1);
                    }
                    img {
                      width: 100%;
                      max-width: 100%;
                      height: 100%;
                      object-fit: cover;
                    }
                  }
                }

                .messagechat__center {
                  padding: 0;
                  display: block;
                  text-align: left;
                  border: none;

                  .messagechat__heading {
                    h6 {
                      @include fluidFont(12, 12, 1);
                      font-weight: 700;
                      letter-spacing: 0.25px;
                      color: $blueDark;
                      max-width: 170px;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                      overflow: hidden;
                    }
                  }
                }

                .messagechat__right {
                  margin-left: auto;

                  span {
                    @include fluidFont(12, 12, 1);
                    font-weight: 400;
                    letter-spacing: 0.25px;
                    color: rgba($blueDark, 0.54);
                  }
                }
              }
              .messagechat__bottom__chats__text {
                max-width: 88%;
                margin-left: 32px;
                @include respond(s720) {
                  max-width: 95%;
                }

                p {
                  text-align: left;
                  @include fluidFont(12, 12, 1.7);
                  font-weight: 600;
                  color: rgba($blueDark, 1);
                  // max-width: 190px;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  // white-space: nowrap;

                  span {
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 3;
                  }
                }

                &.toggle-text {
                  p {
                    span {
                      -webkit-line-clamp: unset;
                    }
                  }
                }

                .message-toggle {
                  color: #4f55f0;
                  cursor: pointer;
                  user-select: none;
                }

                .messagechat_attachment_wrapper {
                  text-align: left;
                  .messagechat_attachment {
                    display: inline-flex;
                    max-width: 185px;
                    gap: 5px;
                    padding: 6px 10px;
                    border-radius: 6px;
                    background-color: rgba($black, 0.04);

                    .download__icon {
                      cursor: pointer;
                    }
                    .chat_loader {
                      width: 20px;
                      height: 20px;
                      overflow: hidden;
                      .v-progress-circular {
                        width: 20px !important;
                        height: 20px !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .dox_preview {
          width: 100%;
          display: flex;
          align-items: flex-start;
          // margin-bottom: 10px;
          padding: 10px 0 0;
          .cancel-icon {
            cursor: pointer;
          }
          .dox_icon {
            width: 30px;
            height: 30px;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
        .messagechat__input {
          position: relative;
          // min-height: 50px;
          border-top: 1px solid rgba($blueDark, 0.18);

          .messagechat__edit {
            // padding: 12px 0;
            padding: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 5px;

            .v-input {
              .v-input__control {
                .v-field {
                  border-radius: 8px;
                  padding: 0;
                  align-items: flex-end;
                  .v-field__input {
                    // min-height: var(--chat-input-height, 28px);
                    // padding: 8px;
                    mask-image: none;
                    padding: 13px 8px;
                    @include fluidFont(12, 12, 1);
                    font-weight: 400;
                    letter-spacing: 0.5px;
                    color: rgba($blueDark, 1);
                  }
                  .v-field__append-inner {
                    padding: 0 0 8px 0;
                  }

                  .v-icon {
                    color: rgba($blueDark, 1);
                    opacity: 1;
                  }
                  .v-field__prepend-inner {
                    padding: 0 0 8px 0;
                  }
                }
              }
            }
          }

          .messagechat__complete {
            padding: 5px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 5px;
            background-color: rgba(0, 0, 0, 0.04);
          }
        }
      }
      .emoji__wrapper {
        position: absolute;
        left: 30px;
        bottom: 60px;
        width: 100%;
      }
    }
  }
}

.media-modal__item-menu {
  box-shadow: 0px 0px 8px 0px #00000014;
  border-radius: 4px;
  background-color: #fff;
  width: 120px;
  padding: 5px 10px;

  &-item {
    cursor: pointer;
    padding: 5px 0;
    display: grid;
    grid-template-columns: 15px 1fr;
    grid-gap: 5px;
    align-items: center;
    @include fluidFont(12, 12, 1);
    font-weight: 500;
  }
}

.messagechat__wrapper.messagechat__active {
  height: 660px;

  @include respond(s720) {
    height: 550px;
  }

  .messagechat__top {
    @include respond(s720) {
      align-items: flex-start !important;
      height: 54px;
      flex: 0 0 54px;
      margin-top: 0 !important;
    }
  }

  .messagechat__inner {
    @include respond(s720) {
      padding-top: 12px !important;
    }
  }

  .messagechat__right {
    margin-top: 0 !important;
  }

  .messagechat__left.with-group {
    padding-top: 0 !important;
    align-items: flex-start !important;
  }
}

.messagechat__inner.messagechat__active .messagechat__section {
  height: calc(100% - 40px);
  transition: 0.5s all ease;
  overflow-y: auto;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.messagechat__inner.messagechat__active {
  // bottom: 20px;
  bottom: 0;

  @media screen and (max-width: 1279px) {
    // bottom: 92px;
    bottom: 72px;
    // max-height: 400px;
  }
}

.messagechat__inner.messagechat__active.v-card-text .messagechat__top {
  padding-bottom: 12px;
}
.message__date::after {
  content: "";
  position: absolute;
  right: 0;
  border: 1px solid #e8ecee;
  width: 30%;
  top: 10px;
}
.message__date::before {
  content: "";
  position: absolute;
  left: 0;
  border: 1px solid #e8ecee;
  width: 30%;
  top: 10px;
}
@media screen and (max-width: 1280px) {
  .messagechat__wrapper.messagechat__active:nth-child(3) {
    display: none;
  }
}
@media screen and (max-width: 991px) {
  .messagechat__wrapper.messagechat__active:nth-child(2) {
    display: none;
  }
}
.custom_loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.messagechat__icons {
  display: flex;
  gap: 6px;
  align-items: center;
}
.dropdown_editor {
  z-index: 9999 !important;
}
.archive_notification {
  background-color: rgba(250, 165, 0, 0.16);
  font-size: calc(14px + 0 * (100vw - 320px) / 2240);
  line-height: 1.3;
  color: #faa500;
  letter-spacing: 0.25px;
  padding: 8px;
  text-align: center;
  width: 100%;
  max-width: 100%;
}
</style>
