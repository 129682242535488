import { reviewRatingState } from "@/core/models/project-rating";
export default {
  setProjectReviewList(state: reviewRatingState, payload: []) {
    state.allProjectReviewList = payload;
  },
  setTradePassportReview(state: reviewRatingState) {
    state.showTradePassportReview = "Reviews";
  },
  resetTradePassportReview(state: reviewRatingState) {
    state.showTradePassportReview = null;
  },
};
