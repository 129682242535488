<template>
  <WizardModal
    v-if="!isShowKYBInfoModal"
    :hideFooter="true"
    :hideBackButton="true"
    :singleData="modalData"
    @on-close="onClickCloseTab"
  >
    <template #content>
      <div class="sole-trader-data-wrapper">
        <div class="your-img-wrap !tw-mb-0 rmd:!tw-mb-8">
          <img class="open-logo" src="../../../assets/icons/id-check.svg" />
        </div>

        <a
          v-if="typeOfVerification === 'KYC'"
          class="btn_link"
          :href="siltRedirectUrl"
          >Start ID verification</a
        >
        <a
          v-if="typeOfVerification === 'KYB'"
          class="btn_link !tw-mt-0 rmd:!tw-mt-8"
          @click="onClickIdVerification"
          >Start ID verification</a
        >
      </div>
    </template>
  </WizardModal>
  <KYCStatusModal
    v-if="isShowKYBInfoModal"
    :onContinue="onClickCloseTab"
    kycStatus="KYBINFO"
  />
</template>
<script lang="ts">
import WizardModal from "@/core/components/modals/WizardModal.vue";
import { computed, ref } from "vue";
import {
  VUE_APP_SILT_KYB_API_TOKEN,
  VUE_APP_SILT_KYB_COMPANY_APP_ID,
} from "@/config/index";

import { USER_STORE } from "@/store/modules/user";
import { WORKSTATION } from "@/store/modules/workstation";
import { useStore } from "vuex";
import { BusinessDetailTypeEnum } from "@/core/enums/RolesEnum";
import $axios from "@/core/utils/axios-api-config";
import KYCStatusModal from "@/core/components/KYCStatusModal.vue";
import { VUE_APP_SILT_KYC_COMPANY_APP_ID } from "@/config";
export default {
  components: { KYCStatusModal, WizardModal },
  props: {
    onClickCloseTab: {
      type: Function,
    },
  },
  setup() {
    const modalData = {
      tab: "ID Check",
      name: "idCheck",
      header: "ID Verification (KYC)",
      title: "We need to verify your identity",
      description:
        "We will require your passport or driver’s license.Verified ID is required to ensure trust security and compliance on iknowa",
    };
    const store = useStore();
    const user = computed(() => store.getters[`${USER_STORE}/user`]);
    const isShowKYBInfoModal = ref(false);
    const activeUserWorkstation = computed(
      () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
    );
    const typeOfVerification = computed(() =>
      user.value?.userBusinessDetails?.type ===
      BusinessDetailTypeEnum.LIMITED_COMPANY
        ? "KYB"
        : "KYC"
    );
    const siltRedirectUrl = computed(() => {
      const host = `${window.location.protocol}//${window.location.host}`;
      const redirectUrl = `${host}/dashboard`;
      return `https://signup.getsilt.com/?company_app_id=${VUE_APP_SILT_KYC_COMPANY_APP_ID}&hide_redirect_button=true&customer_user_id=${user.value?.id}-${activeUserWorkstation.value?.id}&redirect_url=${redirectUrl}&meta={"isUserVerification": true}`;
    });

    const onClickIdVerification = async () => {
      try {
        const response: any = await store.dispatch(
          `${WORKSTATION}/sendUserKYBProcessMail`,
          {
            email: user.value.email,
          }
        );
        response["custom_user_workstation_id"] =
          activeUserWorkstation.value?.id;
        response["custom_user_id"] = user.value.id;
        await store.dispatch(
          `${WORKSTATION}/setWorkStationSiltVerificationStatus`,
          response
        );
        await $axios.post(`/silt/verification/kyb`, response);

        isShowKYBInfoModal.value = true;
      } catch (error) {
        console.log("error", error);
      }
    };
    const closeKYBModal = () => {
      isShowKYBInfoModal.value = false;
    };
    return {
      modalData,
      siltRedirectUrl,
      typeOfVerification,
      onClickIdVerification,
      isShowKYBInfoModal,
      closeKYBModal,
    };
  },
};
</script>

<style scoped>
.sole-trader-data-wrapper {
  max-width: 100%;
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  /* align-items: center; */
}
.your-img-wrap {
  height: 250px;
  width: 250px;
  margin-bottom: 16px;
  @include respond(sm) {
    width: auto;
    height: 250px;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
}
.btn_link {
  font-size: 0.875rem;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 1.7;
  display: flex;
  width: auto;
  box-shadow: none;
  border-radius: 0.5rem;
  letter-spacing: 0;
  min-height: 42px;
  text-decoration: none;
  border: 1px solid #4f55f0;
  align-items: center;
  justify-content: center;
  color: #4f55f0;
  margin-top: 16px;
  cursor: pointer;
}

.btn_link:hover {
  background-color: #4f55f0;
  color: #fff;
}
.back_btn {
  width: auto;
  justify-content: flex-start;
  color: #fff;
  max-width: fit-content;
  font-weight: 700;
  opacity: 1;
}
</style>
