export default {
  setPropertyForm(state: { propertyForm: any }, value: any) {
    state.propertyForm.properties = value;
  },

  setProperties(state: { properties: any }, value: any) {
    state.properties = value;
  },

  setPropertyDetails(state: { propertyDetails: any }, value: any) {
    state.propertyDetails = value;
  },

  setEPCCategoryDataList(state: { epcCategoryDataList: any }, value: any) {
    state.epcCategoryDataList = value;
  },

  setEPCDataListByCategoryId(
    state: { epcDataListByCategoryId: any },
    value: any
  ) {
    state.epcDataListByCategoryId = value;
  },

  setSelectedProperties(state: { selectedProperties: any }, value: any) {
    state.selectedProperties = value;
  },

  setAssetRegisterForm(state: { assetRegisterForm: any }, value: any) {
    state.assetRegisterForm = value;
  },

  setAssetRegisters(state: { assetRegisters: any }, value: any) {
    state.assetRegisters = value;
  },

  setAssetRegisterDetails(state: { assetRegisterDetails: any }, value: any) {
    state.assetRegisterDetails = value;
  },

  setSelectedAsset(state: { selectedAsset: any }, value: any) {
    state.selectedAsset = value;
  },

  setPropertyProjects(state: { propertyProjects: any }, value: any) {
    state.propertyProjects = value;
  },
  setPropertyMapLayers(state: { propertyMapLayers: any }, value: any) {
    state.propertyMapLayers = value;
  },
  setPropertySafetyCheckList(state: any, value: any) {
    state.propertySafetyCheckList = value;
  },
  setNewPropertyProjectData(state: any, value: any) {
    state.newPropertyProjectData.propertyId = value;
  },
};
