<template>
  <div class="historic_project" v-if="!showProjectDetails">
    <!-- <div class="project_banner">
      <div class="project_banner__header">
        <v-icon>
          <img
            :src="require('@/assets/icons/project-banner-icon.svg')"
            alt=""
          />
        </v-icon>
        <h5>Iknowa Verified Projects</h5>
      </div>
      <div class="project_banner__content">
        <div class="left__block">
          <h4>Your Iknowa Verified Project Request Are Here!</h4> -->
    <!-- <p>
            You can review all your verified projects, track their progress, and
            revisit their history. Manage your projects with confidence, knowing
            they have the Iknowa seal of approval.
          </p> -->
    <!-- </div>
        <div class="right__block">
          <img :src="estimatesBannerSvg" />
          <v-icon
            icon="mdi-close-circle-outline"
            class="close_icon"
            @click="showBanner = false"
          />
        </div>
      </div>
    </div> -->
    <div
      class="content_field tw-mb-4"
      v-if="getProjectRequestedList.length && !loading"
    >
      <v-text-field
        prepend-inner-icon="mdi-magnify"
        class="input_field"
        placeholder="Search"
        variant="outlined"
        v-model="filters.search"
      />

      <div class="sort__wrapper sort_dropdown_wrapper">
        <div class="sortby_btn">Sort by</div>

        <v-menu :close-on-content-click="false" :persistent="true">
          <template v-slot:activator="{ props }">
            <v-btn v-bind="props" class="sort__btn" variant="plain">
              <img src="../../../assets/icons/sort.svg" alt="" />
            </v-btn>
          </template>

          <!-- TODO : Toggle Active Class With Item -->
          <v-list class="dropdown__sort sort_dropdown_inner">
            <v-list-item>
              <v-radio-group v-model="filters.sortBy">
                <v-radio
                  label="Most Recent"
                  value="ASC"
                  color="indigo"
                ></v-radio>
                <v-radio label="Oldest" value="DESC" color="indigo"></v-radio>
              </v-radio-group>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

      <!-- <div class="sort__wrapper">
        <v-menu
          :persistent="true"
          :close-on-content-click="false"
          class="desktop_sort_menu"
        >
          <template v-slot:activator="{ props }">
            <v-btn v-bind="props" variant="plain">
              Sort By
              <span>
                <img :src="require('@/assets/icons/sort.svg')" alt="" />
              </span>
            </v-btn>
          </template>
          <v-radio-group v-model="filters.sortBy">
            <v-radio label="Asc" value="ASC" color="indigo"></v-radio>
            <v-radio label="Desc" value="DESC" color="indigo"></v-radio>
          </v-radio-group>
        </v-menu>
      </div> -->
    </div>

    <div class="historic_project__card_list" v-if="!loading">
      <v-card
        v-for="item in getProjectRequestedList"
        :key="item.id"
        class="historic_project__card__item"
      >
        <div class="card__header">
          <div class="card__header__img">
            <img
              class=""
              v-if="!contractorsImages[item?.id]"
              src="@/assets/images/user-placeholder.jpg"
            />
            <img v-else class="" :src="contractorsImages[item?.id]" />
          </div>
          <div class="right__block">
            <EstimateBadge :badge="getEstimateBadge(item)" bgColor="#C19F5F" />
            <div class="heart_icon">
              <img src="../../../assets/icons/heart-icon.svg" alt="" />
            </div>
          </div>
        </div>
        <div class="card__content">
          <div class="card__content__header">
            <h5>
              {{ item?.userWorkStation?.user?.firstName }}
              {{ item?.userWorkStation?.user?.lastName }}
            </h5>
            <div class="card__info">
              <div class="card__info__left">
                <span
                  >{{
                    BusinessDetailTypeEnum.SOLETRADER ===
                    item?.userWorkStation?.user?.userBusinessDetails?.type
                      ? "Solo Trader"
                      : "Limited Company"
                  }}
                  |
                </span>
                <span>
                  <v-icon icon="mdi-map-marker" size="x-small" />
                  {{ item?.userWorkStation?.user?.postcode || "NA" }}
                </span>
              </div>
              <div class="card__info__right">
                <v-icon icon="mdi-star" color="#FFA500" />
                <span>4.8/5 (3 Jobs)</span>
              </div>
            </div>
          </div>
          <div class="card__info__tags">
            <div class="card__info__tags__default">
              <v-icon icon="mdi-check-circle" color="#1fb27a" />
              <p>
                {{ item?.userWorkstation?.experience || "NA" }}
                <span>Exp.</span>
              </p>
            </div>
            <div
              class="card__info__tags__custom"
              v-if="
                item?.userWorkstation?.user?.userSkills &&
                item?.userWorkstation?.user.userSkills.length
              "
            >
              <div
                class="custom_tags"
                v-for="skill in getUserSkillList(
                  item.projectCreator?.userSkills
                ).slice(0, 1)"
                :key="skill.id"
              >
                <v-icon>
                  <img
                    v-if="skill?.iconName"
                    :src="
                      require('@/assets/category-icons/' +
                        skill?.iconName +
                        '.svg')
                    "
                /></v-icon>
                <span>{{ skill?.label }}</span>
              </div>

              <div
                class="custom_tags__more"
                v-if="
                  getUserSkillList(item.projectCreator?.userSkills).length > 1
                "
              >
                <span>
                  +{{
                    getUserSkillList(item.projectCreator?.userSkills).length - 1
                  }}
                  More
                </span>
              </div>
            </div>
          </div>
          <div class="card_project_summary">
            <div class="card_project_summary__list">
              <div class="card_project_summary__list_item">
                <p>Start Date</p>
                <span>
                  {{
                    moment(item?.projectEstimation?.suggestedStartDate).format(
                      "DD MMMM YYYY"
                    )
                  }}
                </span>
              </div>
              <div class="card_project_summary__list_item">
                <p>Duration</p>
                <span>
                  {{
                    duration(
                      item?.projectEstimation?.suggestedStartDate,
                      item?.projectEstimation?.suggestedEndDate
                    )
                  }}
                </span>
              </div>
              <div class="card_project_summary__list_item">
                <p>Cost</p>
                <span>
                  {{
                    formattedCurrency(
                      item?.projectEstimation?.totalEstimateCost
                    )
                  }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="card__footer">
          <v-btn
            class="button button-orange"
            width="100%"
            @click="viewProject(item?.refId, item?.projectEstimateId)"
            >View Project</v-btn
          >
        </div>

        <!-- <div class="tw-flex tw-items-center tw-gap-1 tw-mt-4">
          <span class="tw-text-[16px] tw-font-[700]">
            {{ item?.projectCreator?.firstName }}
            {{ item?.projectCreator?.lastName }}
          </span>
        </div>

        <div class="tw-flex tw-items-center tw-gap-1 tw-mt-1">
          <div
            class="tw-flex tw-items-center tw-font-[600] tw-text-[10px] tw-font-[#0C0F4A]"
          >
            <span
              >{{
                BusinessDetailTypeEnum.SOLETRADER ===
                item?.projectCreator?.userBusinessDetails?.type
                  ? "Solo Trader"
                  : "Limited Company"
              }}
              |
            </span>
            <span class="tw-flex tw-items-center">
              <v-icon icon="mdi-map-marker" size="x-small" />
              {{ item?.projectCreator?.postcode || "NA" }}
            </span>
          </div>
          <div class="tw-flex tw-items-center tw-gap">
            <v-icon icon="mdi-star" color="yellow" />
            <span class="tw-font-[400] tw-text-[12px]">4.8/5 (3 Jobs)</span>
          </div>
        </div>

        <div class="tw-flex tw-items-center tw-gap-2 tw-mt-3">
          <CustomBadge>
            <v-icon
              class="tw-text-[12px]"
              icon="mdi-check-circle"
              color="green"
            />
            <span
              class="tw-pl-[4px] tw-text-[12px] tw-font-[600] tw-font-[#1FB27A]"
            >
              {{ item?.userWorkstation?.experience || "NA" }}
            </span>
            <span class="tw-text-[12px] tw-font-[600]"> Exp. </span>
          </CustomBadge>
          <template
            v-if="
              item.projectCreator?.userSkills &&
              item.projectCreator?.userSkills.length
            "
          >
            <CustomBadge
              class="tags tw-inline-flex tw-shadow-[0px_0px_6px_0px_#0C0F4A14] tw-bg-white tw-rounded-md tw-p-1 tw-items-center tw-gap-1"
              v-for="skill in getUserSkillList(
                item.projectCreator?.userSkills
              ).slice(0, 2)"
              :key="skill.id"
            >
              <v-icon
                class="!tw-w-[12px] !tw-h-[12px] tw-rounded-full tw-bg-[#0C0F4A] tw-overflow-hidden"
              >
                <img
                  class="tw-w-[10px] tw-h-[10px]"
                  v-if="skill?.iconName"
                  :src="
                    require('@/assets/category-icons/' +
                      skill?.iconName +
                      '.svg')
                  "
              /></v-icon>
              <p
                class="tw-text-xs tw-text-semi-bold tw-leading-[15px] tw-text-[#0C0F4A]"
              >
                {{ skill?.label }}
              </p>
            </CustomBadge>
            <CustomBadge
              class="tags tw-inline-flex tw-shadow-[0px_0px_6px_0px_#0C0F4A14] tw-bg-white tw-rounded-md tw-p-1 tw-items-center tw-gap-1"
              v-if="
                getUserSkillList(item.projectCreator?.userSkills).length > 2
              "
            >
              <span>
                +{{
                  getUserSkillList(item.projectCreator?.userSkills).length - 2
                }}
                More
              </span>
            </CustomBadge>
          </template>
        </div>

        <div class="tw-mt-4">
          <div class="tw-w-full tw-h-[1px] tw-bg-gray-200 tw-mb-1" />
          <div class="tw-grid tw-grid-cols-3">
            <div
              class="tw-flex tw-flex-col tw-gap-2 tw-items-center tw-justify-center"
            >
              <span class="tw-font-[500] tw-text-[12px] tw-text-[#00000080]">
                Start Date
              </span>
              <span class="tw-font-[700] tw-text-[14px] tw-text-[#0C0F4A]">
                {{ formatDate(item.project?.startDate) }}
              </span>
            </div>
            <div
              class="tw-flex tw-flex-col tw-gap-2 tw-items-center tw-justify-center"
            >
              <span class="tw-font-[500] tw-text-[12px] tw-text-[#00000080]">
                Duration
              </span>
              <span class="tw-font-[700] tw-text-[14px] tw-text-[#0C0F4A]">
                {{
                  formatDuration(item.project?.startDate, item.project?.endDate)
                }}
              </span>
            </div>
            <div
              class="tw-flex tw-flex-col tw-gap-2 tw-items-center tw-justify-center"
            >
              <span class="tw-font-[500] tw-text-[12px] tw-text-[#00000080]">
                Cost
              </span>
              <span class="tw-font-[700] tw-text-[14px] tw-text-[#0C0F4A]">
                {{
                  CurrencyFilter.formatToCurrency(
                    item?.project?.projectEstimation[0]?.totalEstimateCost
                  )
                }}
              </span>
            </div>
          </div>
          <div class="tw-w-full tw-h-[1px] tw-bg-gray-200 tw-mt-1" />
        </div>

        <Button
          variant="primary"
          label="VIEW PROJECT"
          activeFontColor="#FFF"
          @click="
            viewProject(item.project?.refId, item.project?.projectEstimateId)
          "
        /> -->
      </v-card>
    </div>
    <div
      class="empty-screen"
      v-if="!getProjectRequestedList.length && !loading && !isOpenNewProject"
    >
      <div class="empty-screen__inner">
        <div class="empty-screen__image">
          <img src="@/assets/images/project-request-empty.png" alt="" />
        </div>
        <div class="empty-screen__header">
          <h2>You currently don't have any project requests.</h2>
          <p>Kick off your historic project with unparalleled speed.</p>
        </div>
        <div class="empty-screen__content">
          <div class="empty-screen__content-item">
            <div class="mdi mdi-check-circle"></div>
            <p><strong>Dispute protection</strong> as standard</p>
          </div>
          <div class="empty-screen__content-item">
            <div class="mdi mdi-check-circle"></div>
            <p>Deposits secured <strong>in Escrow</strong></p>
          </div>
          <div class="empty-screen__content-item">
            <div class="mdi mdi-check-circle"></div>
            <p>
              Verified trade contractors <strong>in your local area</strong>
            </p>
          </div>
        </div>
        <div class="empty-screen__footer">
          <v-btn class="button button-purple" @click="openNewProject" block>
            Start a new project
          </v-btn>
        </div>
      </div>
    </div>

    <Loader v-show="loading" class="tw-mt-[250px]" />
  </div>
  <PropertyListComponent
    @onClickBackButton="onClickBackButton"
    v-if="isOpenNewProject"
  />
</template>
<script lang="ts" setup>
import { onBeforeRouteLeave, useRouter } from "vue-router";
import { PROJECT_REQUESTED_DETAILS_ROUTE } from "../routes";
import Text from "@/core/uni-components/Text.vue";
import InputField from "@/core/uni-components/InputField.vue";
import ProjectRequestedCard from "@/modules/project/components/common/ProjectRequestedCard.vue";
import { PROJECT_ROUTE } from "../routes";
import { computed } from "vue";
import { useStore } from "vuex";
import { PROJECT_STORE } from "@/store/modules/project";
import { onMounted, ref, reactive, onBeforeUnmount, watch } from "vue";
import { debounce } from "lodash";
import CommonLoader from "@/core/components/CommonLoader.vue";
import estimatesBannerSvg from "@/core/uni-assets/svgs/estimates-banner.svg";
import { EstimateTypeForDeposit } from "@/core/enums/estimateEnum";
import { BusinessDetailTypeEnum } from "@/core/enums/RolesEnum";
import {
  getImageStringToImageURL,
  getUserSubSkillList,
} from "@/core/utils/common";
import { USER_STORE } from "@/store/modules/user";
import { WORKSTATION } from "@/store/modules/workstation";
import moment from "moment";
import currencyFilter from "@/filters/currency.filter";
import { watchEffect } from "vue";
import EstimateBadge from "@/core/uni-components/EstimateBadge.vue";
import PropertyListComponent from "./PropertyListComponent.vue";
import Loader from "@/core/components/common/Loader.vue";
const store = useStore();
const router = useRouter();
const loading = ref(false);
const showBanner = ref(true);
const showProjectDetails = ref(false);
const getProjectRequestedList = computed(
  () => store.getters[`${PROJECT_STORE}/getProjectRequestedList`]
);
const isOpenNewProject = ref(false);

const filters = reactive({
  search: "",
  sortBy: "ASC",
}) as any;
const contractorsImages: any = ref({});
const viewProject = async (refId: string, projectId: number) => {
  if (refId) {
    router.push({
      name: PROJECT_REQUESTED_DETAILS_ROUTE,
      params: { refId: refId },
    });
  }
};

watch(
  filters,
  (state: any) => {
    let params = JSON.parse(JSON.stringify({ ...state }));
    if (!params.search.length) {
      delete params["search"];
    }

    if (!getProjectRequestedList.value.length) return;
    fetchProjects(params);
  },
  { deep: true }
);
const fetchProjects = debounce(async (params) => {
  try {
    loading.value = true;
    await store.dispatch(`${PROJECT_STORE}/fetchAllRequestedProjects`, params);
  } catch (error) {
    console.log("error", error);
  } finally {
    loading.value = false;
  }
}, 500);
const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);
const getAllSpecialism = computed(
  () => store.getters[`${USER_STORE}/getAllSpecialism`]
);
const getUserSkillList = (userSkill: any) => {
  return getUserSubSkillList(userSkill, getAllSpecialism.value);
};
const getEstimateBadge = (project: any) => {
  let selectedEstimate = project?.projectEstimation;
  if (Array.isArray(project.projectEstimation)) {
    selectedEstimate = project?.projectEstimation[0];
  }

  if (selectedEstimate?.depositType === EstimateTypeForDeposit.Initial_Estimate)
    return "Initial Estimate";
  else if (
    selectedEstimate?.depositType ===
    EstimateTypeForDeposit.Fixed_Cost_Estimation
  )
    return "Fixed Cost Estimation";
  else if (
    selectedEstimate?.depositType ===
    EstimateTypeForDeposit.Variable_Cost_Estimation
  )
    return "Variable Cost Estimation";
  else return "";
};
const duration = (suggestedStartDate: string, suggestedEndDate: string) => {
  if (!suggestedStartDate || !suggestedEndDate) return 0;
  const startDate = moment(suggestedStartDate);
  const endDate = moment(suggestedEndDate);

  const totalDays = endDate.diff(startDate, "days");
  const totalWeeks = endDate.diff(startDate, "weeks");

  let result;

  if (totalDays % 7 === 0) {
    result = `${totalWeeks} week${totalWeeks !== 1 ? "s" : ""}`;
  } else {
    result = `${totalDays} day${totalDays !== 1 ? "s" : ""}`;
  }

  return result;
};
const formattedCurrency = (amount: any) => {
  return currencyFilter.formatToCurrency(amount);
};

const setProfileImageUrl = async (proj: any) => {
  for (const projectCreator of proj) {
    if (projectCreator?.userWorkStation?.userPublicProfile?.profileImage) {
      contractorsImages.value[projectCreator.id] =
        await getImageStringToImageURL(
          projectCreator.userPublicProfile.profileImage
        );
    }
  }
};

watchEffect(async () => {
  await setProfileImageUrl(getProjectRequestedList.value);
});
onMounted(async () => {
  let params = JSON.parse(JSON.stringify({ ...filters }));
  if (!params.search.length) delete params["search"];
  await fetchProjects(params);
});

onBeforeUnmount(async () => {
  if (!getAllSpecialism.value.length) {
    await store.dispatch(
      `${USER_STORE}/setAllSpecialismsCategory`,
      activeUserWorkstation.value.id
    );
  }
  store.dispatch(`${PROJECT_STORE}/resetAllRequestedProjects`, []);
});

const openNewProject = () => {
  isOpenNewProject.value = true;
};
const onClickBackButton = () => {
  isOpenNewProject.value = false;
};
</script>

<style lang="scss" scoped>
.banner {
  position: relative;
}

.close-banner {
  position: absolute;
  top: 10px;
  right: 10px;
}
.historic_project {
  .project_banner {
    width: 100%;
    padding: 16px;
    text-align: left;
    box-shadow: 0px 0px 8px 0px #0c0f4a14;
    border-radius: 8px;
    margin: 24px 0 0;
    background-color: rgba($white, 1);
    position: relative;
    .project_banner__header {
      display: flex;
      gap: 8px;
      align-items: center;
      h5 {
        @include fluidFont(16, 16, 1.2);
        font-weight: 700;
        color: rgba($blueDark, 1);
        letter-spacing: 0.15px;
      }
    }
    .project_banner__content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include respond(s720) {
        flex-direction: column-reverse;
        align-items: flex-start;
        margin: 22px 0 0;
      }
      .left__block {
        @include respond(s720) {
          margin-top: 22px;
        }
        h4 {
          @include fluidFont(20, 20, 1.2);
          font-weight: 400;
          color: rgba($blueDark, 1);
        }
        p {
          margin-top: 8px;
          @include fluidFont(14, 14, 1.2);
          font-weight: 500;
          color: rgba($blueDark, 0.5);
          letter-spacing: 0.15px;
          max-width: 500px;
        }
      }
      .right__block {
        margin-right: 150px;
        @include respond(md) {
          margin: 0;
        }
        @include respond(s720) {
          margin: 22px 0 0;
        }
        .close_icon {
          position: absolute;
          right: 16px;
          top: 16px;
          cursor: pointer;
          color: rgba($blueDark, 0.5);
        }
      }
    }
  }
  .content_field {
    margin: 24px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .input_field {
      margin: 0;
      width: 100%;
      max-width: 216px;
      :deep(.v-input__control) {
        .v-field {
          border: 1px solid rgba($blueDark, 0.2);
          border-radius: 8px;
          padding: 12px 16px;
          .v-field__field {
            .v-field__input {
              background-color: transparent;
              border: 0;
              border-radius: 0;
              @include fluidFont(12, 12, 1.2);
              font-weight: 400;
              color: rgba($blueDark, 1);
              letter-spacing: 0.4px;
              top: 0;
              padding: 0;
              min-height: auto;
              &::placeholder {
                @include fluidFont(12, 12, 1.2);
                font-weight: 400;
                color: rgba($blueDark, 1);
                letter-spacing: 0.4px;
              }
            }
          }
          .v-field__prepend-inner {
            height: 16px;
            .v-icon {
              color: rgba($blueDark, 1);
              opacity: 1;
            }
          }
          .v-field__outline {
            display: none;
          }
        }
      }
    }
    .sort__wrapper {
      .v-btn {
        padding: 0;
        height: auto;
        opacity: 1;
        :deep(.v-btn__content) {
          text-transform: capitalize;
          display: flex;
          gap: 10px;
          align-items: center;
          @include fluidFont(14, 14, 1.2);
          font-weight: 600;
          color: rgba($blueDark, 1);
          letter-spacing: normal;
          span {
            width: 24px;
            height: 24px;
            border-radius: 100%;
            background-color: rgba($blueDark, 0.05);
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        :deep(.v-ripple__container) {
          display: none !important;
        }
      }
    }
    .v-menu {
      :deep(.v-overlay__content) {
        background-color: rgba($white, 1);
        min-width: 185px !important;
        border-radius: 16px;
        box-shadow: 0px 0px 8px 0px #0c0f4a14;
        padding: 16px;
        top: 222px !important;
        left: auto !important;
        right: 40px !important;
        @include respond(xlg) {
          right: 10px !important;
        }
        &::before {
          content: "";
          position: absolute;
          width: 30px;
          height: 20px;
          background-image: url(../../../assets/icons/triangle.svg);
          right: 10px;
          top: -10px;
        }
        .v-input {
          .v-input__control {
            .v-selection-control-group {
              gap: 10px;
              .v-selection-control {
                gap: 8px;
                .v-selection-control__wrapper {
                  width: auto;
                  height: auto;
                  .v-selection-control__input {
                    width: auto;
                    height: auto;
                  }
                }
                .v-label {
                  @include fluidFont(14, 14, 1.3);
                  font-weight: 600;
                  color: rgba($blueDark, 1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
  }
  .historic_project__card_list {
    margin-top: 24px;
    display: flex;
    gap: 16px;
    align-items: flex-start;
    flex-wrap: wrap;
    .v-card {
      width: calc(33.33% - 11px);
      max-width: 100%;
      padding: 20px 16px 0;
      display: flex;
      gap: 16px;
      flex-direction: column;
      align-items: flex-start;
      box-shadow: 0px 0px 8px 0px #0c0f4a14;
      border-radius: 8px;
      @include respond(s1024) {
        width: calc(50% - 8px);
      }
      @include respond(s720) {
        width: 100%;
      }
      .card__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        max-width: 100%;
        .card__header__img {
          width: 40px;
          height: 40px;
          border-radius: 100%;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .right__block {
          display: flex;
          gap: 16px;
          align-items: center;
          .heart_icon {
            width: 30px;
            height: 30px;
            box-shadow: 0px 0px 7.5px 0px #0c0f4a14;
            border-radius: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
      .card__content {
        text-align: left;
        width: 100%;
        max-width: 100%;
        .card__content__header {
          padding-bottom: 8px;
          border-bottom: 1px solid rgba($cardBorder, 1);
          h5 {
            @include fluidFont(20, 20, 1.3);
            font-weight: 700;
            color: rgba($blueDark, 1);
          }
          .card__info {
            display: inline-flex;
            gap: 12px;
            align-items: center;
            .card__info__left {
              display: inline-flex;
              justify-content: flex-start;
              align-items: center;
              span {
                @include fluidFont(12, 12, 1.3);
                font-weight: 600;
                color: rgba($blueDark, 0.5);
                letter-spacing: 0.25px;
                .v-icon {
                  @include fluidFont(10, 10, 1.3);
                }
              }
            }
            .card__info__right {
              display: inline-flex;
              justify-content: flex-start;
              align-items: center;
              gap: 4px;
              .v-icon {
                @include fluidFont(16, 16, 1);
              }
              span {
                @include fluidFont(12, 12, 2);
                font-weight: 400;
                color: rgba($blueDark, 1);
                letter-spacing: 0.25px;
              }
            }
          }
        }
        .card__info__tags {
          display: flex;
          align-items: center;
          gap: 8px;
          padding: 8px 0;
          border-bottom: 1px solid rgba($cardBorder, 1);
          @include respond(s1800) {
            flex-wrap: wrap;
          }
          @include respond(s1024) {
            flex-wrap: nowrap;
          }
          @include respond(md) {
            flex-wrap: wrap;
          }
          @include respond(s720) {
            flex-wrap: nowrap;
          }
          @include respond(sm) {
            flex-wrap: wrap;
          }
          .card__info__tags__default {
            padding: 4px 8px;
            display: inline-flex;
            align-items: center;
            gap: 4px;
            background-color: rgba($blueDark, 0.04);
            border-radius: 8px;
            width: 100px;
            max-width: 100%;
            p {
              @include fluidFont(12, 12, 2);
              font-weight: 700;
              color: rgba($success, 1);
              letter-spacing: 0.15px;
              span {
                font-weight: 400;
                color: rgba($blueDark, 1);
              }
            }
          }
          .card__info__tags__custom {
            display: flex;
            gap: 8px;
            align-items: center;
            width: calc(100% - 100px);
            max-width: 100%;
            @include respond(s1800) {
              width: 100%;
              flex-wrap: wrap;
            }
            @include respond(s1024) {
              width: calc(100% - 100px);
              flex-wrap: nowrap;
            }
            @include respond(md) {
              width: 100%;
              flex-wrap: wrap;
            }
            @include respond(s720) {
              width: calc(100% - 100px);
            }
            @include respond(sm) {
              width: 100%;
              flex-wrap: nowrap;
            }
            .custom_tags {
              display: flex;
              gap: 4px;
              align-items: center;
              background-color: rgba($blueDark, 0.04);
              border-radius: 8px;
              padding: 4px 8px;
              // width: 33.33%;
              .v-icon {
                width: 16px;
                height: 16px;
                border-radius: 100%;
                overflow: hidden;
                background-color: rgba($blueDark, 1);
                img {
                  filter: invert(99%) sepia(0%) saturate(3735%)
                    hue-rotate(87deg) brightness(119%) contrast(100%);
                  width: 10px;
                }
              }
              span {
                @include fluidFont(12, 12, 2);
                font-weight: 700;
                color: rgba($blueDark, 1);
                letter-spacing: 0.15px;
                display: inline-block;
                width: calc(100% - 25px);
                max-width: 100px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                @include respond(sm) {
                  max-width: 70px;
                }
              }
            }
            .custom_tags__more {
              display: inline-flex;
              gap: 4px;
              align-items: center;
              background-color: rgba($blueDark, 0.04);
              border-radius: 8px;
              padding: 4px 8px;
              span {
                @include fluidFont(12, 12, 2);
                font-weight: 700;
                color: rgba($blueDark, 1);
                letter-spacing: 0.15px;
                display: inline-block;
                width: 100%;
                max-width: 100%;
                white-space: nowrap;
              }
            }
          }
        }
        .card_project_summary {
          padding: 8px 0;
          border-bottom: 1px solid rgba($cardBorder, 1);
          .card_project_summary__list {
            display: flex;
            align-items: center;
            .card_project_summary__list_item {
              width: 33.33%;
              max-width: 100%;
              text-align: center;
              position: relative;
              &::before {
                content: "";
                position: absolute;
                right: 0;
                border-right: 1px solid rgba($cardBorder, 1);
                width: 1px;
                height: 100%;
              }
              &:last-child {
                &::before {
                  border: none;
                }
              }
              p {
                @include fluidFont(12, 12, 2);
                font-weight: 500;
                color: rgba($blueDark, 0.5);
                letter-spacing: 0.15px;
              }
              span {
                @include fluidFont(14, 14, 2);
                font-weight: 700;
                color: rgba($blueDark, 1);
                letter-spacing: 0.15px;
              }
            }
          }
        }
      }
      .card__footer {
        width: 100%;
        max-width: 100%;
      }
    }
  }
}

.sort__wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  .sort__btn {
    padding: 0;
    background-color: rgba($blueDark, 0.05);
    border-radius: 100%;
    width: 24px;
    min-width: 24px;
    height: 24px !important;
    box-shadow: none;
    .v-ripple__container {
      display: none !important;
    }
  }
}

.sort_dropdown_wrapper {
  position: relative;
}

.v-menu > .v-overlay__content > .v-list.sort_dropdown_inner {
  box-shadow: 0px 0px 8px 0px rgba(12, 15, 74, 0.0784313725);
  border-radius: 16px;
  padding: 16px;
  position: relative;
  height: unset;
  overflow: visible;
  margin-top: 24px;
  width: 185px;
  max-width: 100%;
  margin-left: auto;

  &:before {
    right: 5px;
    top: -23px;
    height: 30px;
    width: 40px;
    position: absolute;
    pointer-events: none;
    margin-top: 0;
    content: "";
    background-image: url(../../../assets/icons/triangle.svg);
    // filter: invert(100%) sepia(0%) saturate(1%) hue-rotate(219deg)
    //   brightness(102%) contrast(101%);
  }

  .v-list-item {
    padding: 0;
    border-radius: 8px !important;
    min-height: 38px;
    transition: background-color 0.3s ease, color 0.3s ease;
    cursor: pointer;

    .v-list-item-title {
      @include fluidFont(14, 14, 1);
      color: #0c0f4a;
      font-weight: 600;
      display: flex;
      align-items: center;
      gap: 10px;
      transition: background-color 0.3s ease, color 0.3s ease;

      .v-icon {
        color: #4f55f0;
        font-size: 16px;
      }
    }
  }
}

/* Empty Screen */

.empty-screen {
  padding: 50px 0;
  color: $blueDark;

  &__inner {
    max-width: 480px;
    width: 100%;
    margin: 0 auto;
  }

  &__image {
    max-width: 372px;
    width: 100%;
    margin: 0 auto 46px;

    @media (max-width: 767px) {
      max-width: 278px;
      margin: 0 0 24px;
    }

    img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
    @include respond(s720) {
      margin: 0 auto;
    }
  }

  &__header {
    text-align: center;
    margin-bottom: 36px;
    @include fluidFont(14, 14, 18px);
    letter-spacing: 0.15px;
    font-weight: 500;
    color: rgba($blueDark, 0.5);

    @media (max-width: 767px) {
      text-align: left;
    }

    h2 {
      @include fluidFont(32, 28, 30px);
      color: $blueDark;
      font-weight: 500;
      margin-bottom: 16px;
    }
  }

  &__content {
    padding: 0 28px;
    text-align: left;
    @include fluidFont(14, 14, 18px);
    letter-spacing: 0.4px;
    display: grid;
    grid-gap: 8px;

    @media (max-width: 767px) {
      padding: 0;
    }

    &-item {
      display: grid;
      grid-template-columns: 24px 1fr;
      grid-gap: 8px;
      align-items: center;

      .mdi {
        @include fluidFont(24, 24, 1);
        color: rgba($skyBlue, 1);
      }

      img {
        width: 100%;
        height: auto;
        object-fit: contain;
      }
    }
  }

  &__footer {
    margin-top: 36px;
  }
}
</style>
