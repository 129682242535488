export default {
  ws_modal: {
    isShowWorkStationModal: false,
    isShowSoleTraderWorkStation: false,
    isShowLimitedCompanyWorkStation: false,
    activeSoleTraderWorkStationModal: 1,
    activeLimitedCompanyWorkStationModal: 1,

    isFromCourse: false,
  },
  userWorkstations: [],
  propertyOwnerUserAllWorkStation: [],
  activeUserWorkstation: null,
  userWorkstationMembers: [],
  isShowCreatePropertyOwnerWorkStationModal: false,
  isShowHomeownersWorkStation: false,
  activeHomeownersWorkStationModal: 0,
  isShowLandlordsWorkStation: false,
  activeLandlordsWorkStationModal: 0,
  isShowOccupiersWorkStation: false,
  activeOccupiersWorkStationModal: 0,
  isShowHomeownersWelcome: false,
  activeHomeownersWelcomeModal: 0,
  WorkStationSiltVerificationStatus: null,
  activeUserWorkstationDescription: null,
  allOnlineWorkStations: [],
  assignWorkStationData: {
    teamMemberIds: [],
    subCategoryId: [],
    certificateIds: [],
  },
  loadingWorkstation: false,
};
