<template>
  <div
    v-if="isLoading"
    class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-h-full tw-w-full tw-mt-[530px]"
  >
    <Loader :show="isLoading" />
  </div>
  <div
    class="tradepassport_certificate__dashboard md:!tw-pb-[4rem] md:!tw-box-border"
    v-if="!isLoading"
  >
    <DashBoardWelcome
      class="white-card"
      :isShowCloseButton="false"
      :button="dashBoardWelcomeButton"
    >
      <template v-slot:title><h3>Professional Certifications</h3></template>
      <template v-slot:text>
        <!-- <p>
          Lorem ipsum dolor sit amet consectetur. Sed odio tristique a libero.
          Habitant duis congue nunc etiam malesuada risus. Tellus rhoncus vel
          est arcu. Ornare nisi sed odio id. Eget in vestibulum lorem lectus
          justo enim.
        </p> -->
        <v-btn
          @click="onClickAddNewCertification"
          v-if="getUserWorkStationFieldAccess"
          class="button button-orange"
          >add new certification</v-btn
        >
      </template>
      <template v-slot:image>
        <img
          src="@/assets/images/certificate-banner.png"
          class="!tw-w-[240px] !tw-h-[120px] rsm:!tw-w-[280px] rsm:!tw-h-[156px]"
      /></template>
    </DashBoardWelcome>
    <!-- <div class="tw-flex tw-w-full tw-items-center tw-justify-between">
      <div class="heading">
        <h3 class="tw-flex tw-items-center">
          <v-icon icon="mdi-purse" /> Your Certificates
        </h3>
        <div class="add-more-data">
        </div>
      </div>
      <div>
        <v-btn @click="onClickAddNewCertification" class="button button-orange"
          >add new certificate</v-btn
        >
      </div>
    </div> -->
    <div class="trade__certification__wrapper">
      <!-- <div class="trade__certification">
        <div class="icon-title">
          <div class="icon-title__inner">
            <v-icon>
              <img src="../../../assets/icons/certificate.svg" alt="" />
            </v-icon>
            <p class="common-title">Your Certifications</p>
          </div>
        </div>
      </div> -->

      <div class="trade__header">
        <h4>Your Certifications</h4>
      </div>

      <div class="trade__certification__row">
        <v-card
          class="trade-certi-card"
          v-for="certificate in userCertificates"
          :key="certificate.id"
        >
          <!-- <div>
            <PendingChip
              class="tw-w-[100px]"
              v-if="
                certificate?.userVerificationStatus ===
                UserVerificationStatusEnum.PENDING_VERIFICATION
              "
            />
            <AcceptChip
              class="tw-w-[100px]"
              label="Accepted"
              v-if="
                certificate?.userVerificationStatus ===
                UserVerificationStatusEnum.VERIFIED
              "
            />
            <RejectChip
              class="tw-w-[100px]"
              label="Rejected"
              v-if="
                certificate?.userVerificationStatus ===
                UserVerificationStatusEnum.REJECTED
              "
            />
          </div> -->
          <div class="itemlist_card">
            <div
              class="itemlist_card__action"
              v-if="getUserWorkStationFieldAccess"
            >
              <v-icon
                icon="mdi-pencil"
                color="#80829F"
                class="card__edit"
                @click="editCertificate(certificate)"
              ></v-icon>
              <v-icon
                icon="mdi-delete"
                color="#80829F"
                class="card__delete"
                @click="onDeleteItem(certificate)"
              ></v-icon>
            </div>

            <div class="title">
              <h5>
                {{
                  certificate.metadata.name
                    ? certificate.metadata.name
                    : certificate.certificate.name
                }}
              </h5>
              <p>
                Membership No: {{ certificate?.metadata?.registrationNumber }}
              </p>
            </div>
            <div class="filename" v-if="certificate?.attachment?.length">
              <img src="../../../assets/icons/pdficon.svg" alt="" />
              <p>{{ certificate?.attachment[0]?.originalName }}</p>
            </div>
            <div class="members_wrapper" v-if="certificate?.assignedUsers">
              <h5>
                Members <span>({{ certificate.assignedUsers?.length }})</span>
              </h5>

              <!-- Members List -->
              <div class="members__list">
                <div
                  class="members__list__info"
                  v-for="data in certificate.assignedUsers.slice(0, 4)"
                  :key="data.id"
                >
                  <div class="members_img">
                    <img
                      v-if="data?.userPublicProfile?.publicUrl"
                      :src="
                        data.userPublicProfile.publicUrl ||
                        '../../../assets/images/avatar-info-image.png'
                      "
                      alt="user-image"
                    />
                    <UserProfileLogo
                      v-else
                      :userName="`${data?.firstName} ${data?.lastName}`"
                    />
                  </div>
                  <h6>{{ data.firstName }} {{ data.lastName }}</h6>
                </div>
                <div
                  class="members__list__more"
                  v-if="certificate.assignedUsers?.length > 5"
                >
                  4+
                </div>
              </div>
            </div>
          </div>
        </v-card>
      </div>

      <!-- <ItemListCard
            :onClickDelete="() => onDeleteItem(certificate.id)"
            :onClickEdit="() => editCertificate(certificate)"
            :isShowLogo="true"
          >
            <template v-slot:listItemLogo>
              <div class="logo_wrapper">
                <img src="../../../assets/icons/tools.svg" alt="" />
              </div>
            </template>
            <template v-slot:listItemCardTitle>{{
              certificate.certificate.name
            }}</template>
            <template v-slot:listItemCardLabel>{{
              certificate?.metadata?.registrationNumber
            }}</template>
            <template v-slot:listItemCardText
              >{{ certificate?.metadata?.url }}
              <div v-if="certificate?.attachment?.length" class="upload_dox">
                {{ certificate?.attachment[0]?.originalName }}
              </div></template
            >
          </ItemListCard> -->
      <!-- <label class="blue-text btn-xs btn-blue-hover">
              Verify Certification<v-icon icon="mdi-chevron-right"
            /></label> -->

      <!-- </v-col> -->

      <CertificationModel
        v-if="isShowCertificateModal"
        @onClickCloseTab="onClickCloseModel"
        :userCertificateData="userCertificateData"
        @clearUserCertificateData="clearData"
      />

      <CertificateEditModal
        v-if="isEditCertificateModal"
        @onSaveData="onClickUpdateModal"
        @onClickCloseTab="onClickCloseEditModal"
        :userCertificateData="userCertificateData"
      />

      <ConfirmDeleteModal
        v-if="isOpenConfirmDelete"
        :type="confirmDeleteCertificate.name"
        label="You're about to delete your certification."
        @on-close="onCloseConfirmDelete"
        @on-delete="deleteItem"
        :loading="deleteLoader"
      ></ConfirmDeleteModal>
    </div>
  </div>
</template>

<script lang="ts">
import DashBoardWelcome from "@/core/components/DashBoardWelcome.vue";
import { computed, onMounted, onBeforeMount, ref, watch } from "vue";
import { useStore } from "vuex";
import { USER_STORE } from "@/store/modules/user";
import ItemListCard from "@/core/components/ItemListCard.vue";
import CertificationModel from "@/modules/certification/components/CertificationModel.vue";
import CertificateEditModal from "@/modules/certification/components/CertificateEditModal.vue";
// import CommonChip from "@/modules/dashboard/components/Chips/CommonChip.vue";
import AcceptChip from "@/modules/dashboard/components/Chips/AcceptChip.vue";
import RejectChip from "@/modules/dashboard/components/Chips/RejectChip.vue";
import PendingChip from "@/modules/dashboard/components/Chips/PendingChip.vue";
import ConfirmDeleteModal from "@/modules/dashboard/components/Modals/ConfirmDeleteModal.vue";
import { UserVerificationStatusEnum } from "@/core/enums/RolesEnum";
import { getImageStringToImageURL } from "@/core/utils/common";
import $axios from "@/core/utils/axios-api-config";
import { WORKSTATION } from "@/store/modules/workstation";
import UserProfileLogo from "@/core/components/UserProfileLogo.vue";
import Loader from "@/core/components/common/Loader.vue";

export default {
  components: {
    DashBoardWelcome,
    CertificationModel,
    CertificateEditModal,
    ConfirmDeleteModal,
    UserProfileLogo,
    Loader,
  },
  setup() {
    const dashBoardWelcomeButton = ref({
      text: "CREATE A Trades WORKSTATION",
      class: ["button", "button-purple-border"],
    }) as any;
    const store = useStore();
    const isShowCertificateModal = ref(false);
    const isEditCertificateModal = ref(false);

    const user = computed(() => store.getters[`${USER_STORE}/user`]);
    const userCertificates = ref([]) as any;
    const deleteLoader = ref(false);
    const isLoading = ref(false);

    const getUserWorkStationFieldAccess = computed(
      () => store.getters[`${WORKSTATION}/getUserWorkStationFieldAccess`]
    );

    watch(
      () => store.getters[`${USER_STORE}/userCertificates`],
      async (certificates) => {
        userCertificates.value = certificates;
        await setProfileImageUrl();
      },
      {
        immediate: true,
      }
    );

    async function setProfileImageUrl() {
      for (const certificate of userCertificates.value) {
        for (const member of certificate.assignedUsers) {
          if (member.userPublicProfile.profileImage !== null) {
            member.userPublicProfile.publicUrl = await getImageStringToImageURL(
              member.userPublicProfile.profileImage
            );
          }
        }
      }
    }

    const userCertificateData = ref(null) as any;

    const isOpenConfirmDelete = ref(false);
    const confirmDeleteCertificate = ref(null) as any;
    const confirmDeleteAssignedMembers = ref(null) as any;

    onBeforeMount(async () => {
      try {
        isLoading.value = true;
        if (user.value?.id && !userCertificates.value?.length) {
          await store.dispatch(
            `${USER_STORE}/setUserCertificates`,
            user.value.id
          );
        }
      } catch (error) {
        console.log();
      } finally {
        isLoading.value = false;
      }
    });
    const onClickAddNewCertification = () => {
      isShowCertificateModal.value = true;
    };
    const onClickCloseModel = () => {
      isShowCertificateModal.value = false;
      clearData();
    };

    const onClickUpdateModal = async () => {
      await store.getters[`${USER_STORE}/userCertificates`];
      isEditCertificateModal.value = false;
      clearData();
    };

    const onClickCloseEditModal = () => {
      isEditCertificateModal.value = false;
      clearData();
    };

    const onDeleteItem = (certificate: any) => {
      confirmDeleteCertificate.value = certificate;
      isOpenConfirmDelete.value = true;
    };

    const onCloseConfirmDelete = () => {
      confirmDeleteCertificate.value = null;
      isOpenConfirmDelete.value = false;
    };
    const deleteCertificate = async (certificate: any) => {
      try {
        await store.dispatch(`${USER_STORE}/removeUserCertificates`, {
          userId: user?.value?.id,
          userCertificateId: certificate.id,
        });

        // Delete related Members
        // let payload = {
        //   teamMemberIds: members,
        // };

        // if (members?.length) {
        //   await store.dispatch(
        //     `${USER_STORE}/removeCertificateRelatedMembers`,
        //     {
        //       userId: user?.value?.id,
        //       teamMemberIds: payload,
        //     }
        //   );
        // }

        await store.dispatch(`${USER_STORE}/getAccountStatuses`);
      } catch (error) {
        console.log();
      }
    };

    const deleteItem = async () => {
      deleteLoader.value = true;
      await deleteCertificate(confirmDeleteCertificate.value);
      deleteLoader.value = false;
      onCloseConfirmDelete();
    };

    const editCertificate = (certificate: any) => {
      isEditCertificateModal.value = true;
      userCertificateData.value = certificate;
    };

    const clearData = () => {
      userCertificateData.value = null;
    };

    const chipLabel = (status: any) => {
      if (UserVerificationStatusEnum.PENDING_VERIFICATION === status)
        return "Pending";
      else if (UserVerificationStatusEnum.REJECTED === status)
        return "Rejected";
      else if (UserVerificationStatusEnum.VERIFIED === status)
        return "Verified";
      else if (UserVerificationStatusEnum.NOT_ADDED === status)
        return "Not added";
    };
    const chipColor = (status: any) => {
      if (UserVerificationStatusEnum.PENDING_VERIFICATION === status)
        return "#FAA500";
      else if (UserVerificationStatusEnum.REJECTED === status) return "red";
      else if (UserVerificationStatusEnum.VERIFIED === status) return "green";
      else if (UserVerificationStatusEnum.NOT_ADDED === status)
        return "#7467B7";
    };
    return {
      dashBoardWelcomeButton,
      onClickAddNewCertification,
      userCertificates,
      deleteCertificate,
      isShowCertificateModal,
      isEditCertificateModal,
      onClickCloseModel,
      onClickCloseEditModal,
      editCertificate,
      userCertificateData,
      chipLabel,
      chipColor,
      UserVerificationStatusEnum,
      clearData,
      getUserWorkStationFieldAccess,
      isOpenConfirmDelete,
      deleteItem,
      onDeleteItem,
      onCloseConfirmDelete,
      onClickUpdateModal,
      confirmDeleteCertificate,
      deleteLoader,
      isLoading,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/modules/trade-passport/styles/TradePassport.scss";
</style>
