<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 0.154297C7.42541 0.154297 6.88354 0.208823 6.40199 0.31362C2.69246 1.05992 0 4.34683 0 8.13013C0 10.4399 0.997866 12.6755 2.73508 14.1759C4.19675 15.4519 6.06604 16.1543 8 16.1543C12.4112 16.1543 16 12.528 16 8.13013C16 3.73225 12.4112 0.154297 8 0.154297ZM7.57678 7.40505H9.45455C9.85653 7.40505 10.1818 7.72935 10.1818 8.13013C10.1818 8.5309 9.85653 8.8552 9.45455 8.8552H7.64097C7.58448 9.20701 7.47699 9.52735 7.32383 9.81607C7.7599 9.94209 8.17115 10.1758 8.51418 10.5178C9.03263 11.0347 9.87641 11.0347 10.3949 10.5178C10.6789 10.2346 11.1392 10.2346 11.4233 10.5178C11.7073 10.801 11.7073 11.2599 11.4233 11.5431C10.8807 12.0841 10.1676 12.3546 9.45455 12.3546C8.74148 12.3546 8.02841 12.0841 7.48582 11.5431C7.10298 11.1622 6.49862 11.0546 6.01779 11.2627C5.84664 11.3937 5.67122 11.5162 5.49435 11.6337C5.18114 11.8412 4.76494 11.7775 4.52844 11.49C4.2912 11.2018 4.31253 10.7812 4.57673 10.5178C4.77774 10.3174 5.00213 10.1539 5.2415 10.0278C5.73697 9.63302 6.01067 9.26173 6.13842 8.8552H5.09091C4.68892 8.8552 4.36364 8.5309 4.36364 8.13013C4.36364 7.72935 4.68892 7.40505 5.09091 7.40505H6.09474L5.83239 6.09721C5.81818 4.35534 7.12286 3.0546 8.72727 3.0546C10.3317 3.0546 11.6364 4.35534 11.6364 5.9549C11.6364 6.35567 11.3111 6.67998 10.9091 6.67998C10.5071 6.67998 10.1818 6.35567 10.1818 5.9549C10.1818 5.15548 9.52911 4.50475 8.72727 4.50475C7.92543 4.50475 7.27273 5.15548 7.27273 5.9549L7.57678 7.40505Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "CircularCurrencyPound",
};
</script>
