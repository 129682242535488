<template>
  <main
    class="estimate-received-preview tw-h-full tw-w-full tw-flex tw-flex-col tw-gap-4"
  >
    <section
      class="estimate-received-preview__container tw-flex tw-items-start tw-justify-between tw-py-4 tw-box-border tw-border-[0] tw-border-b-[1px] tw-border-[#C5D2F2] tw-border-solid md:!tw-pt-0"
    >
      <BackButton
        label="Back to Your Estimates"
        @click="onClickBackEstimateButton"
      />
      <div class="tw-flex tw-items-center tw-gap-4 lg:tw-hidden">
        <Button
          class="!tw-w-[200px]"
          variant="secondary"
          label="REJECT ESTIMATE"
          @click="onClickRejectEstimate"
        />
        <Button
          class="!tw-w-[200px]"
          label="ACCEPT ESTIMATE"
          @click="onClickAcceptEstimate"
        />
      </div>
    </section>
    <section
      class="tw-gap-10 tw-flex xl:tw-gap-5 lg:tw-flex-col md:!tw-pb-16"
      v-if="details"
    >
      <div
        class="tw-flex tw-flex-col tw-gap-4 tw-w-[340px] tw-max-w-full lg:tw-w-full"
      >
        <div class="tw-flex tw-flex-col tw-gap-4 tw-items-start">
          <ContractorDetailsCard :contractorDetails="contractorDetails" />
          <SubContractorList
            class="desktop__wrapper lg:tw-hidden"
            :subContractorDetailsList="details?.inviteContractors"
          />
        </div>
      </div>
      <div class="lg:tw-flex tw-items-center tw-gap-4 tw-hidden sm:tw-flex-col">
        <Button
          class="!tw-w-[200px] sm:!tw-w-full"
          variant="secondary"
          label="REJECT ESTIMATE"
          @click="onClickRejectEstimate"
        />
        <Button
          class="!tw-w-[200px] sm:!tw-w-full"
          label="ACCEPT ESTIMATE"
          @click="onClickAcceptEstimate"
        />
      </div>
      <div
        class="tw-pl-3 tw-pb-2 tw-box-border tw-flex tw-flex-col tw-gap-4 tw-w-[calc(100%_-_340px)] tw-max-w-full lg:tw-w-full lg:tw-pr-0 lg:tw-pl-0"
      >
        <div class="tw-flex tw-flex-col tw-gap-2 tw-w-full">
          <div class="tw-w-full tw-flex tw-items-center tw-justify-start">
            <Text variant="h3">{{ details?.project?.name }}</Text>
          </div>
          <div
            class="tw-w-full tw-flex tw-items-center tw-justify-start tw-gap-8"
          >
            <CommercialChip />
            <LabelWithValue
              :justifyStart="true"
              :noBg="true"
              :data="details?.project?.refId"
              label="Project ID:"
            />
          </div>
        </div>
        <v-divider></v-divider>
        <div
          class="tw-bg-white tw-rounded-lg tw-px-4 tw-py-5 tw-shadow-[0px_0px_8px_0px_#0C0F4A14] tw-text-darkBlue tw-text-left"
          v-if="emergencyCallOutDetails"
        >
          <div
            class="tw-flex tw-gap-y-4 tw-gap-x-6 rmd:tw-gap-x-16 tw-flex-wrap"
          >
            <div>
              <div class="tw-text-[12px] tw-font-bold tw-tracking-[0.15px]">
                <p>Emergency call out fee</p>
              </div>
              <div class="tw-tracking-[0.25px] tw-text-[10px] tw-mt-1">
                <p>£{{ emergencyCallOutDetails.emergencyCallOutFee }}</p>
              </div>
            </div>
            <div>
              <div class="tw-text-[12px] tw-font-bold tw-tracking-[0.15px]">
                <p>Emergency call out rate</p>
              </div>
              <div class="tw-tracking-[0.25px] tw-text-[10px] tw-mt-1">
                <p>
                  £{{ emergencyCallOutDetails.emergencyCallOutRate }} / hour
                </p>
              </div>
            </div>
          </div>
          <v-divider class="tw-my-3"></v-divider>
          <div>
            <div class="tw-text-[12px] tw-font-bold tw-tracking-[0.15px]">
              <p>Contractor Note</p>
            </div>
            <div class="tw-tracking-[0.25px] tw-text-[10px] tw-mt-1">
              <p v-html="emergencyCallOutDetails.notes"></p>
            </div>
          </div>
        </div>
        <EstimateReceivedSummaryCard :selectedEstimate="details" />
      </div>
      <SubContractorList
        class="mobile__wrapper tw-hidden lg:tw-block lg:tw-text-left"
        :subContractorDetailsList="details?.inviteContractors"
      />
    </section>

    <AcceptProjectEstimateModal
      v-if="isAcceptEstimate"
      @on-save="onConfirmEstimate"
      @on-close="onCloseAcceptEstimate"
    />

    <ServiceAgreementModal
      v-if="isOpenServiceAgreementModal"
      :project="projectDetail"
      :projectEstimate="projectEstimate"
      type="property"
      @on-confirm="onConfirmServiceAgreement"
      @on-close="onCloseServiceAgreementModal"
    />
    <RejectProjectEstimateModal
      v-if="isRejectEstimate"
      :projectId="details?.project?.id"
      :projectEstimateId="details?.id"
      @on-confirm="onConfirmRejectEstimate"
      @on-cancel="onCloseRejectEstimate"
    />
    <EstimateDetailsModal
      v-if="isOpenEstimateDetailsModal"
      @on-close="onCloseEstimateDetailsModal"
      :projectEstimateId="details?.id"
    />

    <EstimateApproveModal
      v-if="isEstimateApproved"
      :estimateData="details"
      @on-back-project="onClickBackProject"
      @on-deposit="onClickBackProject"
    />

    <v-dialog
      v-if="isEstimateLoading"
      width="500px"
      v-model="loading"
      class="modal_box action-modal"
      persistent
    >
      <div class="modal_wrapper">
        <main class="main">
          <div class="loader__content">
            <p class="switching-text">{{ switchingText }}</p>
            <div class="loader__icon">
              <img
                :src="require('@/assets/icons/estimate-loader.svg')"
                alt=""
              />
            </div>

            <v-stepper
              class="onboard-stepper"
              v-model="currentSelectedTab.name"
            >
              <v-stepper-header class="onboard-stepper__header">
                <v-stepper-item
                  class="onboard-stepper__item"
                  v-for="(step, index) in tabListItems"
                  :key="index"
                  :value="step.name"
                ></v-stepper-item>
              </v-stepper-header>
            </v-stepper>
          </div>
        </main>
      </div>
    </v-dialog>
  </main>
</template>
<script lang="ts" setup>
import { reactive, computed } from "vue";

import BackButton from "@/core/components/common/BackButton.vue";
import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import SubContractorList from "@/modules/project/components/common/SubContractorList.vue";
import ContractorDetailsCard from "@/modules/project/components/common/ContractorDetailsCard.vue";
import LabelWithValue from "@/core/components/common/LabelWithValue.vue";
import EstimateReceivedSummaryCard from "@/modules/project/components/common/EstimateReceivedSummaryCard.vue";
import CommercialChip from "@/core/uni-components/Chips/CommercialChip.vue";
import { useRouter } from "vue-router";

import { ref } from "vue";
import projectService from "@/core/services/project.service";
import ServiceAgreementModal from "@/modules/jobs/components/modal/ServiceAgreementModal.vue";
import AcceptProjectEstimateModal from "@/modules/project/modal/AcceptProjectEstimateModal.vue";
import RejectProjectEstimateModal from "@/modules/project/modal/RejectProjectEstimateModal.vue";

import { useStore } from "vuex";
import { USER_STORE } from "@/store/modules/user";
import projectEstimationService from "@/core/services/project-estimation.service";
import { AgreeTermsConsumerDisclosure } from "@/core/enums/estimateEnum";
import EstimateDetailsModal from "@/modules/project/modal/EstimateDetailsModal.vue";
import { ProjectJobStatusEnum } from "@/core/enums/ProjectsEnum";
import EstimateApproveModal from "@/modules/project/modal/EstimateApproveModal.vue";
import { PROJECT_STORE } from "@/store/modules/project";
import { onBeforeMount } from "vue";
import JobService from "@/core/services/jobs.service";

const props = defineProps({
  details: {
    type: Object,
  },
});

const router = useRouter();
const store = useStore();
const loading = ref(false);
const isAcceptEstimate = ref(false);
const isRejectEstimate = ref(false);
const isEstimateLoading = ref(false);
const isOpenServiceAgreementModal = ref(false);
const isOpenEstimateDetailsModal = ref(false);
const isEstimateApproved = ref(false);
const user = computed(() => store.getters[`${USER_STORE}/user`]);
const switchingText = ref("Loading...");
const currentSelectedTab = ref({ name: "tab1" });
const emergencyCallOutDetails = ref(null) as any;
const tabListItems = ref([
  { name: "tab1" },
  { name: "tab2" },
  { name: "tab3" },
]);
const projectEstimate = computed(() => {
  return {
    ...props.details,
    projectEstimateCreator: props.details?.workStation?.user,
    projectStages: props.details?.estimationPhase,
  };
});

const projectDetail = computed(() => {
  return {
    ...props.details?.project,
    projectCreator: {
      firstName: user.value?.firstName,
      lastName: user.value?.lastName,
    },
  };
});

const contractorDetails = computed(() => {
  return props.details?.workStation;
});

const onClickBackEstimateButton = () => {
  router.push(`/project-details/${props?.details?.project?.refId}`);
};

const onClickRejectEstimate = () => {
  isRejectEstimate.value = true;
};

const onClickAcceptEstimate = () => {
  isAcceptEstimate.value = true;
};

const onConfirmEstimate = async () => {
  isAcceptEstimate.value = false;
  if (
    emergencyCallOutDetails.value?.type &&
    projectEstimate.value.agreeTermsConsumerDisclosure ===
      AgreeTermsConsumerDisclosure.NOT_ADDED
  ) {
    try {
      await projectService.updateProjectStatus(projectDetail.value?.id, {
        projectJobStatusId: ProjectJobStatusEnum.IN_PROGRESS,
      });
    } catch (error) {
      console.log("error", error);
    }
  } else {
    isOpenServiceAgreementModal.value = true;
  }
};

const onCloseRejectEstimate = () => {
  isRejectEstimate.value = false;
};

const onCloseAcceptEstimate = () => {
  isAcceptEstimate.value = false;
};

const onClickServiceAgreementModal = () => {
  isOpenServiceAgreementModal.value = true;
};
const onConfirmRejectEstimate = async (payload: any) => {
  try {
    const data = await projectEstimationService.rejectEstimate(
      props.details?.id,
      payload
    );
  } catch (error) {
    console.log("error", error);
  } finally {
    isRejectEstimate.value = false;
    router.push(`/project-details/${props?.details?.project?.refId}`);
  }
};
const onConfirmServiceAgreement = async () => {
  isOpenServiceAgreementModal.value = false;
  isOpenEstimateDetailsModal.value = true;
};

const onCloseServiceAgreementModal = () => {
  isOpenServiceAgreementModal.value = false;
};

const onCloseEstimateDetailsModal = async () => {
  isOpenEstimateDetailsModal.value = false;
  isEstimateLoading.value = true;

  try {
    loading.value = true;
    setTimeout(() => {
      switchingText.value = "Loading...";
      currentSelectedTab.value = tabListItems.value[0];
    }, 1000);
    const payload = { status: AgreeTermsConsumerDisclosure.ACCEPTED };
    (await projectEstimationService.submitStatusWithEstimateUpdate(
      props.details?.project?.id,
      props.details?.id,
      payload
    )) as any;

    const payload2 = {
      status: AgreeTermsConsumerDisclosure.SECURE_PROJECT_ESCROW,
    };
    (await projectEstimationService.submitStatusWithEstimateUpdate(
      props.details?.project?.id,
      props.details?.id,
      payload2
    )) as any;
    isEstimateApproved.value = true;
  } catch (error) {
    console.log("error ", error);
    isEstimateApproved.value = false;
    router.go(-1);
  } finally {
    setTimeout(() => {
      switchingText.value = "Loading...";
      currentSelectedTab.value = tabListItems.value[1];
    }, 2000);

    setTimeout(() => {
      switchingText.value = "Loading...";
      currentSelectedTab.value = tabListItems.value[2];
      loading.value = false;
    }, 5000);
  }
};

const onClickBackProject = () => {
  // project refId
  isEstimateApproved.value = false;
  router.push(`/project-details/${props?.details?.project?.refId}`);

  store.dispatch(
    `${PROJECT_STORE}/setProjectRedirectTabName`,
    "project-overview"
  );
};

onBeforeMount(async () => {
  emergencyCallOutDetails.value =
    await JobService.getEmergencyJobCallOutDetails(props.details?.project?.id);
});
</script>
<script lang="ts">
export default {
  name: "EstimateReceivedPreview",
};
</script>
<style lang="scss" scoped>
.custom_selector {
  :deep(.v-input__control) {
    .v-field {
      background-color: transparent;
      border: 1px solid rgba($buttonText, 0.4);
      border-radius: 8px;
      padding: 0;
      .v-field__field {
        .v-field__input {
          min-height: 40px;
          padding: 12px 16px;
          @include fluidFont(12, 12, 1.2);
          font-weight: 400;
          color: rgba($blueDark, 1);
          letter-spacing: 0.4px;
          opacity: 1;
          .v-select__selection {
            .v-select__selection-text {
              @include fluidFont(12, 12, 1.2);
              font-weight: 400;
              color: rgba($blueDark, 1);
              letter-spacing: 0.4px;
            }
          }
          input {
            @include fluidFont(12, 12, 1.2);
            font-weight: 400;
            color: rgba($blueDark, 1);
            letter-spacing: 0.4px;
            &::placeholder {
              @include fluidFont(12, 12, 1.2);
              font-weight: 400;
              color: rgba($blueDark, 1);
              letter-spacing: 0.4px;
              opacity: 1;
            }
          }
        }
      }
      .v-field__outline {
        display: none;
      }
    }
  }
}

.date_selector {
  :deep(.dp__input_wrap) {
    .dp__input {
      min-height: 40px;
      @include fluidFont(12, 12, 1.2);
      font-weight: 400;
      color: rgba($blueDark, 1);
      letter-spacing: 0.4px;
      padding: 12px 30px;
      border-radius: 8px;
    }
    &::placeholder {
      @include fluidFont(12, 12, 1.2);
      font-weight: 400;
      color: rgba($blueDark, 1);
      letter-spacing: 0.4px;
      opacity: 1;
    }
  }
}

.switching-text {
  text-align: center;
  @include fluidFont(18, 18, 1.3);
  font-weight: 400;
  color: rgba($PrimaryBlue, 1);
  letter-spacing: 0.4px;
}

.progress-bar {
  background-color: #ddd;
  height: 20px;
  border-radius: 10px;
  overflow: hidden;
}

.progress-bar-fill {
  background-color: #4caf50;
  height: 100%;
  width: 0;
  transition: width 5s linear;
}

.action-modal .modal_wrapper {
  width: 100%;
  border-radius: 30px;
}

.action-modal .modal_wrapper {
  background: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(
      111.5deg,
      rgba(245, 250, 255, 0.3) 0%,
      rgba(211, 233, 255, 0.3) 100%
    ) !important;
}
.modal_wrapper {
  .main {
    padding: 32px;
    min-height: 330px;
    .loader__content {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      min-height: 250px;
      .loader__icon {
        width: 60px;
        height: 60px;
        margin: 0 auto;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}
</style>
