<template>
  <div class="project_details_content" v-if="!loading">
    <v-tabs class="tab-list" v-model="selectedTab" align-tabs="center" stacked>
      <v-tab
        v-for="tabItem in filterMenu"
        :key="tabItem.name"
        :value="tabItem.name"
      >
        <component
          class="!tw-mb-[5px]"
          :is="tabItem.icon"
          v-if="tabItem?.isIconComponent"
        ></component>
        <v-icon :icon="tabItem.icon" v-else></v-icon>

        <span>{{ tabItem.label }}</span>
      </v-tab>
    </v-tabs>

    <v-window v-model="selectedTab">
      <v-window-item
        v-for="tabValue in filterMenu"
        :key="tabValue.name"
        :value="tabValue.name"
        class="md:!tw-pb-16 md:!tw-box-borders tw-pt-4"
      >
        <ProjectReviewComponent
          v-if="projectRefId && tabValue.name === 'project-overview'"
          :projectRefId="projectRefId"
          type="projectDetails"
        />

        <TradesEstimates
          v-if="
            tabValue.name === 'trades-estimates' &&
            selectedTab === 'trades-estimates'
          "
          :projectId="projectData?.id"
        />
        <EscrowAccount v-if="tabValue.name === 'escrow-account'" />
        <EstimateSummary v-if="tabValue.name === 'estimate-summary'" />
        <SiteVisitRequestsPage
          v-if="tabValue.name === 'site-visit' && selectedTab === 'site-visit'"
          :projectId="projectData?.id"
        />
        <InviteContractorPage v-if="selectedTab === 'invite-contractors'" />
        <VideoConsultationPage
          v-if="selectedTab === VIDEO_CONSULTATION"
          :projectDetails="projectData"
        />
      </v-window-item>
    </v-window>
  </div>
  <!-- LOADER -->
  <div
    v-if="loading"
    class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-h-full tw-w-full tw-mt-[500px]"
  >
    <Loader :show="loading" />
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, watch, reactive, onBeforeMount } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

import GbpCircleFilledIcon from "@/core/components/icons/GbpCircleFilledIcon.vue";
import EscrowAccount from "@/modules/project/components/EscrowAccount.vue";
import EstimateSummary from "@/modules/project/components/EstimateSummary.vue";
import TradesEstimates from "@/modules/project/components/TradesEstimates.vue";
import ProjectReviewComponent from "@/modules/project/components/ProjectReviewComponent.vue";
import SiteVisitRequestsPage from "@/modules/project/pages/SiteVisitRequestsPage.vue";
import { PROJECT_STORE } from "@/store/modules/project";
import PropertyProjectReview from "@/modules/project/components/PropertyProjectReview.vue";
import InviteContractorPage from "@/modules/project/pages/InviteContractorPage.vue";
import VideoConsultationPage from "@/modules/project/pages/VideoConsultationPage.vue";
import {
  ProjectJobStatusEnum,
  ProjectListStatusType,
  ProjectTypesEnum,
} from "@/core/enums/ProjectsEnum";
import { onMounted } from "vue";
import Loader from "@/core/components/common/Loader.vue";
const gbpCircleFilledIcon = GbpCircleFilledIcon;

const store = useStore();
const route = useRoute();

const ESTIMATE = "estimate";
const SCHEDULE = "schedule";
const SITE_VISIT = "sitevisit";
const ESTIMATE_SUMMARY = "estimate summary";
const ESCROW_ACCOUNT = "escrow account";
const INVITE = "invitees";
const VIDEO_CONSULTATION = "video consultation";

const projectMenuWithoutAcceptedEstimates = [
  { name: "project-overview", icon: "mdi-home", label: "Project Details " },
  {
    name: "invite-contractors",
    label: "Invite Contractors",
    icon: "mdi-home",
  },
  {
    name: "trades-estimates",
    icon: "mdi-speedometer-slow",
    label: "Trades Estimates",
  },
  {
    name: "site-visit",
    label: "Site Visit Requests",
    icon: "mdi-home",
  },
  // {
  //   key: SCHEDULE,
  //   title: "Schedule",
  //   icon: "mdi-file-document",
  // },
  {
    name: VIDEO_CONSULTATION,
    label: "Video Consultation",
    icon: "mdi-video-minus",
  },
];

const filterMenu = computed(() => {
  return state.menu.filter((menuItem: any) => {
    if (menuItem.name === "trades-estimates") {
      if (
        [
          ProjectJobStatusEnum.QUOTES_RECEIVED,
          ProjectJobStatusEnum.QUOTE_ACCEPTED,
          ProjectJobStatusEnum.QUOTE_CONFIRMED,
          ProjectJobStatusEnum.IN_PROGRESS,
        ].includes(projectData.value.projectJobStatus.id)
      ) {
        return true;
      }
    } else if (menuItem.name === "site-visit") {
      if (
        projectData.value.siteVisitStatus &&
        [
          ProjectJobStatusEnum.QUOTES_RECEIVED,
          ProjectJobStatusEnum.QUOTE_ACCEPTED,
          ProjectJobStatusEnum.QUOTE_CONFIRMED,
          ProjectJobStatusEnum.IN_PROGRESS,
        ].includes(projectData.value.projectJobStatus.id)
      )
        return true;
      return false;
    } else if (menuItem.name === VIDEO_CONSULTATION) {
      if (
        [ProjectJobStatusEnum.IN_PROGRESS].includes(
          projectData.value.projectJobStatus.id
        )
      ) {
        return true;
      }
    } else {
      return true;
    }
  });
});

const projectMenuWithAcceptedEstimates = [
  { name: "project-overview", icon: "mdi-home", label: "Project Details" },
  {
    name: "escrow-account",
    icon: "mdi-currency-gbp",
    label: "Escrow Account",
  },
  {
    name: "estimate-summary",
    icon: "mdi-receipt-text-edit",
    label: "Estimate Summary",
  },
  // {
  //   key: SCHEDULE,
  //   title: "Schedule",
  //   icon: "mdi-file-document",
  // },
];

const selectedTab = ref();
const projectRefId = ref(null) as any;
const loading = ref(false);
const state = reactive({
  // TEMPORARY STATE
  isProjectWithAcceptedEstimate: false,

  menu: [],
}) as any;
const projectData = ref(null) as any;

const getProjectRedirectTabName = computed(
  () => store.getters[`${PROJECT_STORE}/getProjectRedirectTabName`]
);
const isUpdate = ref(false);
watch(
  getProjectRedirectTabName,
  (newValue, oldValue) => {
    selectedTab.value = newValue;
  },
  { deep: true, immediate: true }
);

watch(
  selectedTab,
  async (newValue, oldValue) => {
    store.dispatch(`${PROJECT_STORE}/setProjectRedirectTabName`, newValue);
  },
  { deep: true, immediate: true }
);

onBeforeMount(async () => {
  if (route.params && route.params.refId) {
    projectRefId.value = route.params.refId;
  }
  let projectDetails = null;
  try {
    loading.value = true;
    projectDetails = await store.dispatch(`${PROJECT_STORE}/getProjectData`, {
      projectRefId: projectRefId.value,
    });

    if (projectDetails) {
      projectData.value = projectDetails;
      await store.dispatch(`${PROJECT_STORE}/projectDetails`, projectDetails);
    }
    if (projectDetails?.projectEstimateId) {
      await store.dispatch(
        `${PROJECT_STORE}/setProjectEstimateEscrowBalance`,
        projectDetails?.id
      );

      await store.dispatch(
        `${PROJECT_STORE}/getProjectEstimateWithEscrow`,
        projectDetails?.projectEstimateId
      );
      state.menu = projectMenuWithAcceptedEstimates;
    } else {
      state.menu = projectMenuWithoutAcceptedEstimates;
    }
  } catch (error) {
    console.log("error", error);
  } finally {
    loading.value = false;
  }
});
</script>
<style lang="scss" scoped>
@import "@/modules/project/styles/projectDetails.scss";
</style>
