<template>
  <div v-if="!showNextRegForm" class="form-wrapper">
    <div class="form_input">
      <label>Email Address</label>
      <v-form ref="registrationEmail" :rules="rules" @submit.prevent>
        <v-text-field
          bg-color="rgba(12, 15, 74, 0.0509803922)"
          class="c-input"
          rounded
          size="lg"
          outlined="false"
          density="compact"
          placeholder="Email address"
          variant="solo"
          v-model="userForm.email"
          :rules="rules.email"
        ></v-text-field>
        <p class="error_msg" v-if="emailErrorMessage">
          {{ emailErrorMessage }}
        </p>
        <v-btn
          class="button disabled"
          :class="{ 'button-orange': isDisabledNextButton }"
          size="large"
          block
          :disabled="!isDisabledNextButton"
          :loading="loadingNextRegister"
          @click.enter="nextRegisterForm"
        >
          NEXT
        </v-btn>
      </v-form>
    </div>

    <div class="card-part">
      <p class="common-title">Already have an account</p>
      <label class="yellow-text" @click="returnToLogin">
        Log In<v-icon icon="mdi-chevron-right" />
      </label>
    </div>
    <div class="benefit_wrapper">
      <div class="title">
        <h6><v-icon icon="mdi-star" />Iknowa Benefits</h6>
      </div>
      <h5>Take a look at all the amazing benefits Iknowa offers</h5>
      <label @click="openOverview" class="yellow-text"
        >Open Iknowa Overview <v-icon icon="mdi-chevron-right"
      /></label>
    </div>
  </div>

  <div class="register_wrapper md:!tw-pt-[25px]" v-else>
    <div class="title">
      <h3 class="logo-description default-text">
        Great! Now just a couple of personal details to proceed.
      </h3>
    </div>
    <v-form
      ref="registrationForm"
      @submit.prevent
      :rules="registrationFormRules"
    >
      <div class="form-field">
        <div class="form_input">
          <label>First Name</label>
          <v-text-field
            bg-color="rgba(12, 15, 74, 0.0509803922)"
            class="input_field"
            placeholder="First Name"
            variant="solo"
            rounded
            outlined="false"
            v-model="userForm.firstName"
            :rules="registrationFormRules.firstName"
          ></v-text-field>
        </div>
        <div class="form_input">
          <label class="">Last Name</label>
          <v-text-field
            bg-color="rgba(12, 15, 74, 0.0509803922)"
            class="input_field"
            density="compact"
            placeholder="Last Name"
            variant="solo"
            rounded
            outlined="false"
            v-model="userForm.lastName"
            :rules="registrationFormRules.lastName"
          ></v-text-field>
        </div>
        <div class="form_input">
          <label class="">Password</label>
          <v-text-field
            bg-color="rgba(12, 15, 74, 0.0509803922)"
            class="input_field password__input"
            density="compact"
            placeholder="Password"
            variant="solo"
            rounded
            outlined="false"
            v-model="userForm.password"
            :append-inner-icon="isShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append-inner="() => (isShowPassword = !isShowPassword)"
            :type="isShowPassword ? 'text' : 'password'"
            :rules="registrationFormRules.password"
          ></v-text-field>
        </div>
        <div class="form_input">
          <label class="">Confirm Password</label>
          <v-text-field
            bg-color="rgba(12, 15, 74, 0.0509803922)"
            class="input_field password__input"
            density="compact"
            placeholder="Confirm Password"
            variant="solo"
            rounded
            outlined="false"
            v-model="userForm.confirmPassword"
            :append-inner-icon="
              isShowConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'
            "
            @click:append-inner="
              () => (isShowConfirmPassword = !isShowConfirmPassword)
            "
            :type="isShowConfirmPassword ? 'text' : 'password'"
            :rules="registrationFormRules.confirmPassword"
          ></v-text-field>
        </div>
      </div>
      <div class="checkbox__wrapper">
        <v-checkbox
          class="checkbox__btn"
          v-model="userForm.tcOptIn"
          :rules="[() => nameValidationSchema('Check', userForm.tcOptIn)]"
        >
          <template v-slot:label>
            <v-icon v-if="userForm.tcOptIn" color="#faa500">
              mdi-check-circle
            </v-icon>
            <v-icon v-else> mdi-circle-outline </v-icon>
            <p>
              I agree to the

              <a
                href="https://iknowa.com/pdf/MFSL-Introduced-Client-Terms.pdf"
                class="yellow-text"
              >
                Terms of Service</a
              >
              and
              <span class="yellow-text">Privacy Policy</span>
            </p>
          </template>
        </v-checkbox>

        <v-checkbox class="checkbox__btn" v-model="userForm.marketingOptIn">
          <template v-slot:label>
            <v-icon v-if="userForm.marketingOptIn" color="#faa500">
              mdi-check-circle
            </v-icon>
            <v-icon v-else> mdi-circle-outline </v-icon>
            <p>
              Please don't send me any marketing<br
                class="tw-inline rsm:!tw-hidden"
              />
              material
            </p>
          </template>
        </v-checkbox>
      </div>
      <v-btn
        class="button disabled"
        :class="{ 'button-orange': isDisabledRegisterButton }"
        size="large"
        block
        :disabled="!isDisabledRegisterButton"
        :loading="loading"
        @click.enter="navigateToVerifyCode"
      >
        register
      </v-btn>
    </v-form>
    <div class="card-part">
      <p class="common-title">Already have an account</p>
      <label class="yellow-text" @click="returnToLogin">
        Log In<v-icon icon="mdi-chevron-right" />
      </label>
    </div>
    <div class="benefit_wrapper">
      <div class="title">
        <h6><v-icon icon="mdi-star" />iknowa Benefits</h6>
      </div>
      <h5>Take a look at all the amazing benefits iknowa offers</h5>
      <label @click="openOverview" class="yellow-text"
        >Open iknowa Overview <v-icon icon="mdi-chevron-right"
      /></label>
    </div>
  </div>
  <AuthIknowaInfoMobileModal
    v-if="isShowAuthIknowaInfoMobile"
    @onCloseButtonClick="onCloseButtonClick"
  />
</template>

<script setup lang="ts">
import CryptoJS from "crypto-js";
import { ref, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import { nameValidationSchema } from "../../../core/validation/validation";
import { VERIFY_REGISTRATION_ROUTE } from "../routes";
import { CRYPTO_SECRET_KEY } from "@/core/constants";
import { USER_STORE } from "@/store/modules/user";
import { useDisplay } from "vuetify/lib/framework.mjs";
import AuthIknowaInfoMobileModal from "@/core/components/AuthIknowaInfoMobileModal.vue";

const router = useRouter();
const route = useRoute();

const registrationForm = ref(null) as any;
const registrationEmail = ref(null) as any;

const isShowPassword = ref(false);
const isShowConfirmPassword = ref(false);
const showNextRegForm = ref(false);
const emailErrorMessage = ref("");
const loadingNextRegister = ref(false);
const loading = ref(false);

const userForm = ref({
  firstName: "",
  lastName: "",
  password: "",
  confirmPassword: "",
  marketingOptIn: false,
  tcOptIn: false,
  email: "",
});
import { useStore } from "vuex";
const store = useStore();
const vuetify = useDisplay();
import { AUTHENTICATION_STORE } from "@/store/modules/authentication";

const returnToLogin = () => {
  router.push("/");
};

const registrationFormRules = ref({
  firstName: [
    (v: string) => !!v || "First Name is required",
    (v: string) =>
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        v
      ) || "Invalid format. Try again.",
  ],
  lastName: [
    (v: string) => !!v || "Last Name is required",
    (v: string) =>
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        v
      ) || "Invalid format. Try again.",
  ],
  password: [
    (v: string) => !!v || "Password is required",
    (v: string) =>
      // eslint-disable-next-line
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]).{8,}$/.test(
        v
      ) ||
      "Password must contain at least 8 characters including at least 1 letter, 1 number and 1 special character",
  ],
  confirmPassword: [
    (v: string) => !!v || "Confirm Password is required",
    (v: string) =>
      v === userForm.value.password || "Your Passwords Do Not Match",
  ],
});

const navigateToVerifyCode = async (e: any) => {
  e.preventDefault();
  const isFormValid = await registrationForm.value.validate();
  let courseReservation = null;
  if (route.query.courseReservation) {
    courseReservation = JSON.parse(
      String(route.query.courseReservation) || "{}"
    );
    console.log(courseReservation, "courseReservation");
  } else {
    console.log("no route");
    console.log(courseReservation, "courseReservation 2");
  }

  if (isFormValid.valid) {
    try {
      loading.value = true;
      const passwordEncrypt = CryptoJS.AES.encrypt(
        userForm.value.password ?? "",
        CRYPTO_SECRET_KEY ?? ""
      ).toString();
      const confirmPasswordEncrypt = CryptoJS.AES.encrypt(
        userForm.value.confirmPassword ?? "",
        CRYPTO_SECRET_KEY ?? ""
      ).toString();
      // Update userForm.value with confirmPassword
      const updatedUserFormValue = {
        ...userForm.value,
        password: passwordEncrypt,
        confirmPassword: confirmPasswordEncrypt,
      };
      const response = await store.dispatch(
        `${AUTHENTICATION_STORE}/registration`,
        updatedUserFormValue
      );
      await store.dispatch(`${USER_STORE}/saveUserState`, response);
      if (response) {
        router.push({
          name: VERIFY_REGISTRATION_ROUTE,
          query: courseReservation
            ? {
                courseReservation: JSON.stringify(courseReservation),
                email: userForm.value.email,
              }
            : { email: userForm.value.email },
        });
      }
    } catch (error) {
      emailErrorMessage.value = error?.response?.data?.message;
      console.log("error", error);
    } finally {
      loading.value = false;
    }
  }
};

const isMoblieView = computed(() => vuetify.mdAndDown.value);
const isShowAuthIknowaInfoMobile = ref(false);
const onCloseButtonClick = () => {
  isShowAuthIknowaInfoMobile.value = false;
  document.body.classList.remove("noScrollModal");
};

const openOverview = () => {
  isShowAuthIknowaInfoMobile.value = true;
  document.body.classList.add("noScrollModal");
};

const rules = ref({
  email: [
    (v: string) => !!v || "Email is required",
    (v: string) =>
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        v
      ) || "Invalid email. Try again.",
  ],
});

const nextRegisterForm = async (e: any) => {
  e.preventDefault();
  const isFormValid = await registrationEmail.value.validate();

  if (isFormValid.valid) {
    try {
      loadingNextRegister.value = true;
      userForm.value.email = userForm.value.email.toLowerCase();
      const response = await store.dispatch(
        `${AUTHENTICATION_STORE}/verifyEmail`,
        {
          email: userForm.value.email,
        }
      );
      if (response) {
        showNextRegForm.value = true;
      }
    } catch (error) {
      if (error?.response?.data?.statusCode == 409) {
        emailErrorMessage.value = error?.response?.data?.message;
      }
      console.log("error", error);
    } finally {
      loadingNextRegister.value = false;
    }
  }
};
const isDisabledNextButton = computed(() => {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    userForm.value.email
  );
});

const isDisabledRegisterButton = computed(() => {
  return (
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}[\]:;<>,.?~\\-]).{8,}$/.test(
      userForm.value.password
    ) &&
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}[\]:;<>,.?~\\-]).{8,}$/.test(
      userForm.value.confirmPassword
    ) &&
    /^[a-zA-Z0-9\s]+$/.test(userForm.value.firstName) &&
    /^[a-zA-Z0-9\s]+$/.test(userForm.value.lastName)
  );
});
</script>

<style lang="scss" scoped>
@import "@/modules/registration/styles/registration.scss";
/**/
</style>
