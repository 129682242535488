<template>
  <v-card
    class="tw-py-[20px] tw-px-[16px] tw-bg-white tw-rounded-lg tw-w-[calc(33.33%-12px)] tw-max-w-full !tw-shadow-[0px_0px_8px_0px_#0C0F4A14]"
  >
    <div class="tw-flex tw-justify-between tw-items-center">
      <div
        class="tw-relative tw-h-[40px]"
        v-if="invitedContractors && invitedContractors.length"
      >
        <div
          v-for="(item, i) in invitedContractors"
          :key="item.id"
          class="tw-absolute tw-top-0 tw-rounded-full tw-w-[40px] tw-h-[40px]"
          :class="`tw-left-[${i * 15}px]`"
        >
          <img
            v-if="!contractorsImages[item.id]"
            class="tw-rounded-full tw-border-solid tw-border-white tw-w-full tw-h-full tw-object-cover"
            src="@/assets/images/user-placeholder.jpg"
          />
          <img
            v-if="contractorsImages[item.id]"
            class="tw-rounded-full tw-border-solid tw-border-white tw-w-full tw-h-full tw-object-cover"
            :src="contractorsImages[item.id]"
          />
        </div>
      </div>
      <div
        class="tw-relative tw-h-[40px]"
        v-else-if="!invitedContractors.length && workstationImage"
      >
        <div
          class="tw-absolute tw-top-0 tw-rounded-full tw-w-[40px] tw-h-[40px]"
        >
          <ImageHolder
            v-if="workstationImage"
            class="tw-rounded-full tw-border-solid tw-border-white tw-w-full tw-h-full tw-object-cover"
            :src="workstationImage"
          />
          <UserProfileLogo
            v-else
            :userName="estimateDetails?.workStation?.name"
          />
        </div>
      </div>
      <div class="tw-flex tw-items-center tw-gap-[16px]">
        <EstimateBadge :badge="estimateBadge" bgColor="#6B5F4B" />
      </div>
    </div>

    <div class="tw-flex tw-items-center tw-gap-1 tw-mt-4">
      <span class="tw-text-[16px] tw-font-[700]">
        {{ estimateDetails?.workStation?.name }}
      </span>
      <span
        class="tw-text-[10px] tw-font-[600]"
        v-if="estimateDetails?.inviteContractors?.length"
      >
        + {{ estimateDetails?.inviteContractors?.length }} more
      </span>
    </div>

    <div class="tw-flex tw-items-center tw-gap-1 tw-mt-1">
      <div
        class="tw-flex tw-items-center tw-font-[600] tw-text-[10px] tw-font-[#0C0F4A]"
      >
        <span
          >{{
            estimateDetails?.workStation?.subscription?.metadata
              ?.businessType || "NA"
          }}
          |
        </span>
        <span class="tw-flex tw-items-center">
          <v-icon icon="mdi-map-marker" size="x-small" />
          {{ estimateDetails?.workStation?.user?.postcode }}
        </span>
      </div>
      <div class="tw-flex tw-items-center tw-gap">
        <v-icon icon="mdi-star" color="yellow" />
        <span class="tw-font-[400] tw-text-[12px]">4.8/5 (536 Jobs)</span>
      </div>
    </div>

    <div
      class="tw-text-left tw-flex tw-items-center tw-gap-2 tw-mt-3 md:!tw-items-start"
    >
      <CustomBadge>
        <v-icon class="tw-text-[12px]" icon="mdi-check-circle" color="green" />
        <span
          class="tw-pl-[4px] tw-text-[12px] tw-font-[600] tw-font-[#1FB27A]"
        >
          {{ estimateDetails?.workStation?.experience || "NA" }}
        </span>
        <span class="tw-text-[12px] tw-font-[600]"> Exp. </span>
      </CustomBadge>
      <CustomBadge
        v-if="displayedSkills && displayedSkills.length"
        class="tw-flex tw-flex-wrap tw-gap-2"
      >
        <!-- <v-icon icon="mdi-check" color="green" /> -->
        <span
          class="tw-text-[12px] tw-font-[700] tw-font-[#0C0F4A]"
          v-for="skill in getUserSkillList(displayedSkills)"
          :key="skill.id"
        >
          {{ skill.label }}
        </span>
      </CustomBadge>
      <span v-if="remainingCount > 0"> +{{ remainingCount }} </span>
    </div>

    <div class="tw-mt-4">
      <div class="tw-w-full tw-h-[1px] tw-bg-gray-200 tw-mb-1" />
      <div class="tw-grid tw-grid-cols-3">
        <div
          class="tw-flex tw-flex-col tw-gap-2 tw-items-center tw-justify-center"
        >
          <span class="tw-font-[500] tw-text-[12px] tw-text-[#00000080]">
            Start Date
          </span>
          <span class="tw-font-[700] tw-text-[14px] tw-text-[#0C0F4A]">
            {{ formattedDate }}
          </span>
        </div>
        <div
          class="tw-flex tw-flex-col tw-gap-2 tw-items-center tw-justify-center"
        >
          <span class="tw-font-[500] tw-text-[12px] tw-text-[#00000080]">
            Duration
          </span>
          <span class="tw-font-[700] tw-text-[14px] tw-text-[#0C0F4A]">
            {{ duration }}
          </span>
        </div>
        <div
          class="tw-flex tw-flex-col tw-gap-2 tw-items-center tw-justify-center"
        >
          <span class="tw-font-[500] tw-text-[12px] tw-text-[#00000080]">
            Cost
          </span>
          <span class="tw-font-[700] tw-text-[14px] tw-text-[#0C0F4A]">
            {{ formattedCurrency(estimateDetails?.totalEstimateCost) }}
          </span>
        </div>
      </div>
      <div class="tw-w-full tw-h-[1px] tw-bg-gray-200 tw-mt-1" />
    </div>

    <div class="tw-mt-4" />

    <Button
      variant="primary"
      label="VIEW ESTIMATE"
      activeFontColor="#FFF"
      @click="viewEstimateDetails"
    />
  </v-card>
</template>
<script lang="ts" setup>
import Button from "@/core/uni-components/Button.vue";
import ImageHolder from "@/core/uni-components/ImageHolder.vue";
import Text from "@/core/uni-components/Text.vue";
import EstimateBadge from "@/core/uni-components/EstimateBadge.vue";
import IconButton from "@/core/uni-components/IconButton.vue";
import CustomBadge from "@/core/uni-components/CustomBadge.vue";
import { computed } from "vue";
import moment from "moment";
import { EstimateTypeForDeposit } from "../enums/estimateEnum";
import { ref, onBeforeMount, onMounted } from "vue";
import {
  getImageApiUrl,
  getImageStringToImageURL,
  getUserSubSkillList,
} from "../utils/common";
import $axios from "@/core/utils/axios-api-config";
import ProfileThumbnail from "@/core/uni-components/ProfileThumbnail.vue";
import CurrencyFilter from "@/filters/currency.filter";
import { USER_STORE } from "@/store/modules/user";
import { useStore } from "vuex";
import { WORKSTATION } from "@/store/modules/workstation";
import UserProfileLogo from "../components/UserProfileLogo.vue";
const emits = defineEmits(["on-view-estimate-details"]);
const props = defineProps({
  estimateDetails: {
    type: Object,
  },
});
const viewEstimateDetails = () => {
  emits("on-view-estimate-details");
};
const store = useStore();
const invitedContractors = ref([]) as any;
const contractorsImages = ref({}) as any;
const workstationImage = ref(null) as any;

const duration = computed(() => {
  if (
    !props.estimateDetails?.suggestedStartDate ||
    !props.estimateDetails?.estimateEndDate
  ) {
    return 0;
  }

  const startDate = moment(props.estimateDetails?.suggestedStartDate) as any;
  const endDate = moment(props.estimateDetails?.estimateEndDate) as any;

  const totalDays = endDate.diff(startDate, "days");

  if (totalDays % 7 === 0) {
    const totalWeeks = totalDays / 7;
    return `${totalWeeks} week${totalWeeks > 1 ? "s" : ""}`;
  }

  return `${totalDays} day${totalDays > 1 ? "s" : ""}`;
});
const formattedDate = computed(() => {
  if (!props.estimateDetails?.suggestedStartDate) {
    return "N/A";
  }
  const date = new Date(props.estimateDetails?.suggestedStartDate);
  const options = { day: "2-digit", month: "long", year: "numeric" } as any;
  return date.toLocaleDateString("en-GB", options);
});

const displayedSkills = computed(() => {
  return props.estimateDetails?.workStation?.user?.userSkills.slice(0, 2);
});

const remainingCount = computed(() => {
  return props.estimateDetails?.workStation?.user?.userSkills.length - 2;
});

const totalPhaseAmount = (
  amount: number,
  vat: number,
  isFirstPhase: boolean
) => {
  const SERVICE_CHARGE = 0.03;
  const PHASE_FIRST_CHARGE = 5;
  let sum = 0;

  sum += amount + amount * vat;
  sum += sum * SERVICE_CHARGE;
  if (isFirstPhase) {
    sum += PHASE_FIRST_CHARGE;
  }

  return sum;
};

const estimateBadge = computed(() => {
  if (
    props.estimateDetails?.depositType ===
    EstimateTypeForDeposit.Initial_Estimate
  )
    return "Initial Estimate";
  else if (
    props.estimateDetails?.depositType ===
    EstimateTypeForDeposit.Fixed_Cost_Estimation
  )
    return "Fixed Cost Estimation";
  else if (
    props.estimateDetails?.depositType ===
    EstimateTypeForDeposit.Variable_Cost_Estimation
  )
    return "Variable Cost Estimation";
  else return "";
});

const formattedCurrency = (amount) => {
  return CurrencyFilter.formatToCurrency(amount);
};

const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);

const getAllSpecialism = computed(
  () => store.getters[`${USER_STORE}/getAllSpecialism`]
);

const getWsImage = async () => {
  const wsImage = await getImageStringToImageURL(
    props.estimateDetails?.workStation?.profileImage
  );
  try {
    workstationImage.value = wsImage;
  } catch (error) {
    return null;
  }
};

const getUserSkillList = (userSkill: any) => {
  return getUserSubSkillList(userSkill, getAllSpecialism.value);
};

onBeforeMount(async () => {
  if (!getAllSpecialism.value.length) {
    await store.dispatch(
      `${USER_STORE}/setAllSpecialismsCategory`,
      activeUserWorkstation.value.id
    );
  }
  const contractors = props?.estimateDetails?.inviteContractors;

  if (contractors && contractors.length) {
    const sliced = contractors.slice(0, 4);
    invitedContractors.value = sliced;
    sliced.map(async (el: any) => {
      if (el?.profileImage) {
        const imageApiUrl = getImageApiUrl(el?.profileImage, true);
        const encoded = (await $axios.get(imageApiUrl)) as any;
        contractorsImages.value[el.id] = encoded.publicUrl;
      }
    });
  }
});

onMounted(async () => {
  await getWsImage();
});
</script>

<style lang="scss" scoped></style>
