<template>
  <WizardModal
    :hideFooter="false"
    :hideStepper="true"
    :hasCustomActionFooter="true"
    :hideBackButton="false"
    :currentStep="2"
    :singleData="modelDetails"
    :showHeaderInMobile="true"
    :hideTitleAndDescription="true"
    :contentOverflowHidden="true"
    :hideClose="true"
    @on-close="onClickBackButton"
    @on-back="onClickBackButton"
  >
    <template #content>
      <main
        class="tw-overflow-y-auto tw-flex tw-flex-col tw-h-full tw-min-h-[unset] tw-justify-between !tw-mt-0 rmd:!tw-mt-4 tw-gap-4 tw-pt-0 tw-box-border"
      >
        <section
          class="tw-w-full tw-flex tw-flex-col tw-h-full tw-gap-4 rmd:!tw-gap-6 tw-px-2"
        >
          <div
            class="tw-rounded-[8px] tw-bg-[#F3F3F6] tw-py-5 tw-px-4 tw-box-border tw-flex tw-flex-col tw-items-start tw-gap-2"
          >
            <Text variant="h6" class="md:!tw-leading-[18px]"
              >Are you a Limited Company with more than 1 candidate?</Text
            >
            <Text variant="p" textWeight="500" class="md:!tw-leading-[18px]"
              >You can select more than 1 spot depending on your requirements
              and the spots available for the specific course dates below.
            </Text>
          </div>
          <div class="tw-flex tw-flex-col tw-gap-2 tw-w-full">
            <div
              v-if="isShowSelectCourse"
              class="tw-flex tw-flex-col tw-items-start tw-gap-2 tw-w-full"
            >
              <Text variant="h5" class="md:!tw-text-[14px]"
                >Select Course Date(s) & Location</Text
              >
              <InputField
                class="!tw-w-full"
                v-model="numberOfSelectedDates"
                append-inner-icon="mdi-chevron-down"
                @click="onToggleShowSelectDate"
                :disabled="isShowSelectDates && selectedDates.length"
                @input="debouncedFilterCourse"
              ></InputField>
            </div>
            <!-- select date if desktop -->
            <div
              class="tw-px-2 tw-pb-2 tw-box-border tw-w-full md:!tw-hidden"
              v-if="isShowSelectDates"
            >
              <div
                v-if="isLoadingDates"
                class="tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center"
              >
                <CommonLoader
                  v-if="isLoadingDates"
                  :loading="isLoadingDates"
                  class="loader-container"
                />
              </div>

              <v-table
                v-else
                class="select-dates-table tw-rounded-[8px]"
                height="300px"
                fixed-header
              >
                <thead>
                  <tr>
                    <th class="text-left !tw-bg-[#F5F6F8]"></th>
                    <th class="text-left !tw-bg-[#F5F6F8]">
                      <Text
                        variant="h6"
                        textColor="rgba(12, 15, 74, 0.5)"
                        whiteSpace="nowrap"
                        >Start Date</Text
                      >
                    </th>
                    <th class="text-left !tw-bg-[#F5F6F8]">
                      <Text variant="h6" textColor="rgba(12, 15, 74, 0.5)"
                        >Location</Text
                      >
                    </th>
                    <th class="text-left !tw-bg-[#F5F6F8]">
                      <Text
                        variant="h6"
                        textColor="rgba(12, 15, 74, 0.5)"
                        whiteSpace="nowrap"
                        >Spots Left</Text
                      >
                    </th>
                    <th class="text-left !tw-bg-[#F5F6F8]">
                      <Text
                        variant="h6"
                        textColor="rgba(12, 15, 74, 0.5)"
                        whiteSpace="nowrap"
                        >Time Left To Enrol</Text
                      >
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in filteredCalendarAttributes"
                    :key="item.name"
                  >
                    <td>
                      <div v-if="item.timeLeft !== TIME_LEFT_DONE">
                        <v-checkbox
                          v-model="selectedDates"
                          :value="item"
                        ></v-checkbox>
                      </div>
                    </td>
                    <td>
                      <Text variant="p" lineHeight="18px" whiteSpace="nowrap">{{
                        appFilters.formatToDate(item.startDate, "DD MMM YYYY")
                      }}</Text>
                    </td>
                    <td>
                      <Text variant="p" lineHeight="18px">{{
                        item.address
                      }}</Text>
                    </td>
                    <td>
                      <Text variant="p" lineHeight="18px">{{
                        item.spotsRemaining
                      }}</Text>
                    </td>
                    <td>
                      <Text variant="p" lineHeight="18px">{{
                        item.timeLeft
                      }}</Text>
                    </td>
                  </tr>
                </tbody>
              </v-table>
            </div>
            <!-- select date if mobile -->
            <div
              v-if="isShowSelectDates"
              class="!tw-flex tw-rounded-[8px] tw-border-[1px] tw-overflow-hidden tw-h-auto tw-w-full tw-p-[6px] tw-box-border tw-border-solid tw-border-[rgba(12,15,74,0.1)] rmd:!tw-hidden"
            >
              <div
                v-for="item in calendarAttributes"
                :key="item.name"
                class="tw-w-full tw-border-b-solid-custom tw-pb-[6px] tw-box-border tw-flex tw-gap-2 tw-items-start tw-justify-between"
              >
                <div class="tw-flex tw-items-start tw-gap-1 tw-w-1/2">
                  <div v-if="item.timeLeft !== TIME_LEFT_DONE">
                    <v-checkbox
                      v-model="selectedDates"
                      :value="item"
                    ></v-checkbox>
                  </div>
                  <div class="tw-flex tw-flex-col tw-gap-1">
                    <div class="tw-flex tw-flex-col">
                      <Text
                        variant="span"
                        textColor="rgba(12, 15, 74, 0.5)"
                        textWeight="400"
                        lineHeight="18px"
                        whiteSpace="nowrap"
                      >
                        Start Date
                      </Text>
                      <Text variant="span" lineHeight="18px">
                        {{
                          appFilters.formatToDate(item.startDate, "DD MMM YYYY")
                        }}
                      </Text>
                    </div>
                    <div class="tw-flex tw-flex-col">
                      <Text
                        variant="span"
                        textColor="rgba(12, 15, 74, 0.5)"
                        textWeight="400"
                        lineHeight="18px"
                        whiteSpace="nowrap"
                      >
                        Spots Left
                      </Text>
                      <Text
                        variant="span"
                        lineHeight="18px"
                        whiteSpace="nowrap"
                      >
                        {{ item.spotsRemaining }}
                      </Text>
                    </div>
                  </div>
                </div>
                <div class="tw-flex tw-flex-col tw-gap-1 tw-w-1/2">
                  <div class="tw-flex tw-flex-col">
                    <Text
                      variant="span"
                      textColor="rgba(12, 15, 74, 0.5)"
                      textWeight="400"
                      lineHeight="18px"
                      whiteSpace="nowrap"
                    >
                      Location
                    </Text>
                    <Text variant="span" lineHeight="18px">
                      {{ item.address }}
                    </Text>
                  </div>
                  <div class="tw-flex tw-flex-col">
                    <Text
                      variant="span"
                      textColor="rgba(12, 15, 74, 0.5)"
                      textWeight="400"
                      lineHeight="18px"
                      whiteSpace="nowrap"
                    >
                      Time Left To Enrol
                    </Text>
                    <Text variant="span" lineHeight="18px" whiteSpace="nowrap">
                      {{ item.timeLeft }}
                    </Text>
                  </div>
                </div>
              </div>
            </div>
            <!-- add spots in mobile -->
            <div
              v-if="isShowAddSpots"
              class="tw-w-full tw-flex tw-flex-col tw-gap-4 rmd:!tw-hidden"
            >
              <div
                class="!tw-flex tw-rounded-[8px] tw-border-[1px] tw-overflow-hidden tw-h-auto tw-w-full tw-p-[6px] tw-box-border tw-border-solid tw-border-[rgba(12,15,74,0.1)] rmd:!tw-hidden"
              >
                <template v-if="selectedDates && selectedDates.length">
                  <div
                    v-for="(item, index) in selectedDates"
                    :key="index"
                    class="tw-w-full tw-border-b-solid-custom tw-pb-[6px] tw-box-border tw-flex tw-gap-2 tw-items-start tw-justify-between"
                  >
                    <div class="tw-flex tw-items-start tw-gap-1 tw-w-1/2">
                      <div class="tw-flex tw-flex-col tw-gap-1">
                        <div class="tw-flex tw-flex-col">
                          <Text
                            variant="span"
                            textColor="rgba(12, 15, 74, 0.5)"
                            textWeight="400"
                            lineHeight="18px"
                            whiteSpace="nowrap"
                          >
                            Course Date
                          </Text>
                          <Text variant="span" lineHeight="18px">
                            {{
                              appFilters.formatToDate(
                                item.startDate,
                                "DD MMM YYYY"
                              )
                            }}
                          </Text>
                        </div>
                        <div class="tw-flex tw-flex-col">
                          <Text
                            variant="span"
                            textColor="rgba(12, 15, 74, 0.5)"
                            textWeight="400"
                            lineHeight="18px"
                            whiteSpace="nowrap"
                          >
                            Spots
                          </Text>
                          <InputField
                            v-model="item.spots"
                            prepend-inner-icon="mdi-minus-box"
                            append-inner-icon="mdi-plus-box"
                            @click:append-inner="onIncrementSpot(index)"
                            @click:prepend-inner="onDecrementSpot(index)"
                          ></InputField>
                        </div>
                      </div>
                    </div>
                    <div class="tw-flex tw-flex-col tw-gap-1 tw-w-1/2">
                      <div class="tw-flex tw-flex-col">
                        <div class="tw-flex tw-items-center tw-justify-end">
                          <v-menu
                            transition="scale-transition"
                            location="start"
                          >
                            <template v-slot:activator="{ props }">
                              <Text
                                v-bind="props"
                                variant="16"
                                class="tw-underline !tw-text-[#4F55F0] tw-cursor-pointer"
                                >Remove</Text
                              >
                            </template>
                            <div
                              class="remove-popup tw-min-w-[389px] md:tw-min-w-[300px] tw-rounded-[8px] tw-bg-white tw-p-4 tw-flex tw-flex-col tw-items-start tw-gap-4"
                            >
                              <Text
                                variant="h6"
                                lineHeight="18px"
                                textColor="rgba(12, 15, 74, 0.6)"
                                >Are you sure you want to remove this
                                spots?</Text
                              >
                              <div
                                class="tw-w-full tw-flex tw-items-center tw-justify-end tw-gap-3"
                              >
                                <Button
                                  class="!tw-w-auto"
                                  label="cancel"
                                  variant="secondary"
                                ></Button>
                                <Button
                                  class="!tw-w-auto"
                                  label="remove"
                                  @click="onRemoveReservation(index)"
                                ></Button>
                              </div>
                            </div>
                          </v-menu>
                        </div>
                      </div>
                      <div class="tw-flex tw-flex-col tw-items-end">
                        <Text
                          variant="span"
                          textColor="rgba(12, 15, 74, 0.5)"
                          textWeight="400"
                          lineHeight="18px"
                          whiteSpace="nowrap"
                        >
                          Spots Remaining
                        </Text>
                        <Text
                          variant="span"
                          lineHeight="18px"
                          whiteSpace="nowrap"
                        >
                          {{ item.spotsRemaining }}
                        </Text>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
              <div
                class="tw-w-full tw-rounded-[8px] tw-bg-[#F3F3F6] tw-py-5 tw-px-4 tw-box-border tw-flex tw-flex-col tw-items-start tw-gap-2"
              >
                <div
                  class="tw-w-full tw-pb-4 tw-box-border tw-border-b-dashed-custom"
                >
                  <Text variant="h6"
                    >Total cost payable for 3 months based on the spots
                    selected</Text
                  >
                </div>

                <div class="tw-w-full tw-flex-row-between !tw-items-start">
                  <div class="tw-flex tw-flex-col tw-items-start">
                    <Text variant="h6">Spots</Text>
                    <Text
                      variant="span"
                      textWeight="400"
                      textColor="rgba(12, 15, 74, 0.5)"
                      >Total {{ numberOfSpotsReserved }} Spots (3 Months x
                      £9.99)</Text
                    >
                  </div>
                  <Text variant="h6">{{
                    CurrencyFilter.formatToCurrency(spotsAmount)
                  }}</Text>
                </div>

                <div class="tw-w-full tw-flex-row-between">
                  <Text variant="h6">VAT</Text>
                  <Text variant="h6">{{
                    CurrencyFilter.formatToCurrency(vatAmount)
                  }}</Text>
                </div>

                <div
                  class="tw-w-full tw-pt-4 tw-box-border tw-border-t-dashed-custom tw-flex-row-between"
                >
                  <Text variant="h6"
                    >Total Payable
                    <Text variant="span" textWeight="400"
                      >(Over 3 months)</Text
                    ></Text
                  >
                  <Text variant="h6" textColor="#FAA100">{{
                    CurrencyFilter.formatToCurrency(spotsAmount + vatAmount)
                  }}</Text>
                </div>
              </div>
            </div>
          </div>
          <!-- add spots in desktop -->
          <div
            v-if="isShowAddSpots"
            class="tw-flex tw-flex-col tw-gap-4 tw-items-start tw-w-full md:!tw-hidden"
          >
            <Text variant="h5" class="md:!tw-text-[14px]"
              >Select the number of spots you require for your selected
              dates?</Text
            >
            <div class="tw-px-2 tw-pb-2 tw-box-border tw-w-full">
              <v-table class="select-dates-table tw-rounded-[8px]">
                <thead>
                  <tr>
                    <th class="text-left !tw-bg-[#F5F6F8]">
                      <Text variant="h6" textColor="rgba(12, 15, 74, 0.5)"
                        >Course Dates</Text
                      >
                    </th>
                    <th class="text-left !tw-bg-[#F5F6F8]">
                      <Text variant="h6" textColor="rgba(12, 15, 74, 0.5)"
                        >Spots</Text
                      >
                    </th>
                    <th class="text-left !tw-bg-[#F5F6F8]">
                      <Text variant="h6" textColor="rgba(12, 15, 74, 0.5)"
                        >Spots Remaining</Text
                      >
                    </th>
                    <th class="text-left !tw-bg-[#F5F6F8]"></th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="selectedDates && selectedDates.length"
                    ><tr v-for="(item, index) in selectedDates" :key="index">
                      <td>
                        <Text variant="p">{{
                          appFilters.formatToDate(item.startDate, "DD MMM YYYY")
                        }}</Text>
                      </td>
                      <td>
                        <InputField
                          v-model="item.spots"
                          prepend-inner-icon="mdi-minus-box"
                          append-inner-icon="mdi-plus-box"
                          @click:append-inner="onIncrementSpot(index)"
                          @click:prepend-inner="onDecrementSpot(index)"
                        ></InputField>
                      </td>
                      <td>
                        <Text variant="h6">{{ item.spotsRemaining }}</Text>
                      </td>
                      <td>
                        <div class="tw-flex tw-items-center tw-justify-end">
                          <v-menu
                            transition="scale-transition"
                            location="start"
                          >
                            <template v-slot:activator="{ props }">
                              <Text
                                v-bind="props"
                                variant="16"
                                class="tw-underline !tw-text-[#4F55F0] tw-cursor-pointer"
                                >Remove</Text
                              >
                            </template>
                            <div
                              class="remove-popup tw-min-w-[389px] md:tw-min-w-[300px] tw-rounded-[8px] tw-bg-white tw-p-4 tw-flex tw-flex-col tw-items-start tw-gap-4"
                            >
                              <Text
                                variant="h6"
                                lineHeight="18px"
                                textColor="rgba(12, 15, 74, 0.6)"
                                >Are you sure you want to remove this
                                spots?</Text
                              >
                              <div
                                class="tw-w-full tw-flex tw-items-center tw-justify-end tw-gap-3"
                              >
                                <Button
                                  class="!tw-w-auto"
                                  label="cancel"
                                  variant="secondary"
                                ></Button>
                                <Button
                                  class="!tw-w-auto"
                                  label="remove"
                                  @click="onRemoveReservation(index)"
                                ></Button>
                              </div>
                            </div>
                          </v-menu>
                        </div>
                      </td></tr
                  ></template>
                </tbody>
              </v-table>
            </div>
            <div
              class="tw-w-full tw-rounded-[8px] tw-bg-[#F3F3F6] tw-py-5 tw-px-4 tw-box-border tw-flex tw-flex-col tw-items-start tw-gap-2"
            >
              <div
                class="tw-w-full tw-pb-4 tw-box-border tw-border-b-dashed-custom"
              >
                <Text variant="h6"
                  >Total cost payable for 3 months based on the spots
                  selected</Text
                >
              </div>

              <div class="tw-w-full tw-flex-row-between !tw-items-start">
                <div class="tw-flex tw-flex-col tw-items-start">
                  <Text variant="h6">Spots</Text>
                  <Text
                    variant="span"
                    textWeight="400"
                    textColor="rgba(12, 15, 74, 0.5)"
                    >Total {{ numberOfSpotsReserved }} Spots (3 Months x
                    £9.99)</Text
                  >
                </div>
                <Text variant="h6">{{
                  CurrencyFilter.formatToCurrency(spotsAmount)
                }}</Text>
              </div>

              <div class="tw-w-full tw-flex-row-between">
                <Text variant="h6">VAT</Text>
                <Text variant="h6">{{
                  CurrencyFilter.formatToCurrency(vatAmount)
                }}</Text>
              </div>

              <div
                class="tw-w-full tw-pt-4 tw-box-border tw-border-t-dashed-custom tw-flex-row-between"
              >
                <Text variant="h6"
                  >Total Payable
                  <Text variant="span" textWeight="400"
                    >(Over 3 months)</Text
                  ></Text
                >
                <Text variant="h6" textColor="#FAA100">{{
                  CurrencyFilter.formatToCurrency(spotsAmount + vatAmount)
                }}</Text>
              </div>
            </div>
          </div>
        </section>
      </main>
    </template>
    <template #footer-action>
      <section
        class="tw-flex !tw-pb-8 tw-items-center tw-justify-center tw-flex-col tw-w-full tw-gap-4 md:!tw-gap-[8px]"
      >
        <Button
          label="continue"
          @click="onContinue"
          :disabled="
            !selectedDates.length || (isShowAddSpots && !allDateHasSpots)
          "
        />
      </section>
    </template>
  </WizardModal>
</template>
<script setup>
import moment from "moment";
import {
  computed,
  ref,
  onBeforeMount,
  onBeforeUnmount,
  watch,
  onMounted,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

// import CommonDialog from "@/core/components/CommonDialog.vue";
import WizardModal from "@/core/components/modals/WizardModal.vue";
import Button from "@/core/components/ui/general/Button.vue";
import CheckGreen from "@/core/components/icons/CheckGreen.vue";
import CancelIcon from "@/core/components/icons/CancelIcon.vue";
import ListIcon from "@/core/components/icons/ListIcon.vue";
import Text from "@/core/components/ui/general/Text.vue";
import ChipState from "@/core/components/common/ChipState.vue";
import { COURSES_STORE } from "@/store/modules/courses";
import { TRADE_PASSPOSRT_ROUTE } from "@/modules/trade-passport/routes";
import { WORKSTATION } from "@/store/modules/workstation";
import { DASHBOARD_ROUTE } from "@/modules/dashboard/routes";
import InputField from "@/core/components/common/InputField.vue";
import CommonLoader from "@/core/components/CommonLoader.vue";
import appFilters from "@/filters";
import CurrencyFilter from "@/filters/currency.filter";
import debounce from "lodash/debounce";

const store = useStore();
const route = useRoute();
const router = useRouter();
const isShowSelectCourse = ref(true);
const courseId = ref(null);
const props = defineProps({
  selectedSchedule: { type: Object, default: null },
});

const emits = defineEmits(["on-close", "on-continue"]);

const TIME_LEFT_DONE = ref("Event started or passed");

const isLoading = ref(false);
const isShowSelectDates = ref(false);

const selectedDates = ref([]);
const numberOfSelectedDates = ref("");

const isShowAddSpots = ref(false);
const spotPrice = ref(29.97); // 1 spot = 9.99 subscription * 3 months plan
const vat = ref(0.2);

const modelDetails = ref({
  id: 2,
  tab: "reserveSpot",
  name: "reserveSpot",
  header: "Air Source Heat Pump (Level 3)",
});

const isLoadingDates = ref(false);
const selectedAddress = ref([]);
const addressListFromCourse = ref([]);
const courseSchedules = ref([]);
const calendarAttributes = ref([]);
const filteredCalendarAttributes = ref([]);

const courseDetails = computed(
  () => store.getters[`${COURSES_STORE}/courseDetails`]
);

const selectedDatesStore = computed(
  () => store.getters[`${COURSES_STORE}/selectedDates`]
);

const totalReserveSpotAmount = computed(
  () => store.getters[`${COURSES_STORE}/totalReserveSpotAmount`]
);

const allDateHasSpots = computed(() => {
  return selectedDates.value.every((item) => item.spots > 0);
});

const spotsAmount = computed(() => {
  // Sum all spots in selectedDates
  const totalSpots = selectedDates.value.reduce((sum, item) => {
    return sum + item.spots;
  }, 0);

  // Multiply the sum by 29.97
  return totalSpots * spotPrice.value;
});

const vatAmount = computed(() => {
  return spotsAmount.value * vat.value;
});

const filterCourse = () => {
  if (selectedDates.value && selectedDates.value.length) {
    return;
  }
  isLoadingDates.value = true;
  // Filter the calendarAttributes by the selected address
  const filteredAttributes = calendarAttributes.value.filter((attribute) => {
    // Check if the address in the calendarAttributes matches the selected address (case-insensitive)
    return attribute.address
      .toLowerCase()
      .includes(numberOfSelectedDates.value.toLowerCase());
  });

  filteredCalendarAttributes.value = filteredAttributes;
  isLoadingDates.value = false;
};

const debouncedFilterCourse = debounce(filterCourse, 300);

const onClickBackButton = () => {
  return emits("on-close");
};

// const onCreateWorkstation = () => {
//   onClickBackButton();
//   store.dispatch(`${WORKSTATION}/setWsModal`, {
//     isShowWorkStationModal: true,
//   });
//   router.push({ name: DASHBOARD_ROUTE });
// };

const onToggleShowSelectDate = () => {
  // if searching always show the selected dates ui
  if (!selectedDates.value.length && numberOfSelectedDates.value !== "") {
    isShowSelectDates.value = true;
    return;
  }
  // if navigating back to selectDates from add spots screen
  if (
    !isShowSelectDates.value &&
    selectedDates.value &&
    selectedDates.value.length
  ) {
    const newSelectedDates = [];
    // Iterate through calendarAttributes
    calendarAttributes.value.forEach((item) => {
      // Check if the selectedDates contains an item with the same ID as in calendarAttributes
      const isSelected = selectedDates.value.find(
        (selectedItem) => selectedItem.id === item.id
      );

      // If it's selected, pre-check the checkbox by pushing it to newSelectedDates
      if (isSelected) {
        newSelectedDates.push(item);
      }
    });
    // set the newSelectedDates to selectedDates
    selectedDates.value = newSelectedDates;
  }
  isShowSelectDates.value = !isShowSelectDates.value;

  // make sure add spots section is close if select dates is open
  if (isShowSelectDates.value) {
    isShowAddSpots.value = false;
  }
};

const onContinue = async () => {
  if (isShowAddSpots.value) {
    // open terms and conditions modal
    await store.dispatch(
      `${COURSES_STORE}/setSelectedDates`,
      selectedDates.value
    );
    await store.dispatch(
      `${COURSES_STORE}/setTotalReserveSpotAmount`,
      spotsAmount.value + vatAmount.value
    );
    const currentDate = new Date();
    await store.dispatch(
      `${COURSES_STORE}/setSpotReservedDateStarted`,
      currentDate
    );
    emits("on-continue");
    return;
  }
  onToggleShowSelectDate();
  if (selectedDates.value && selectedDates.value.length) {
    selectedDates.value = selectedDates.value.map((date) => {
      return {
        ...date,
        spotsRemaining: date.spotsRemaining - 1,
        spots: 1,
      };
    });
  }

  isShowAddSpots.value = true;
};

const getCourseSchedule = async () => {
  try {
    courseId.value = route?.params.courseId;
    isLoadingDates.value = true;
    console.log(courseDetails.value?.name, "courseDetails.value?.name");
    let courseName = "";
    if (
      !courseDetails.value?.name ||
      courseDetails.value?.name === undefined ||
      courseDetails.value?.name === "undefined"
    ) {
      if (courseId.value === "1") {
        courseName = "Air Source Heat Pump Level 3";
      } else {
        courseName = "Green Skills";
      }
    } else {
      courseName = courseDetails.value?.name;
    }
    let response = await store.dispatch(`${COURSES_STORE}/getCourseSchedule`, {
      name: courseName,
    });

    const currentDate = moment().format("YYYY-MM-DD");
    const filteredData = response.data.filter((course) => {
      const enrollStart = moment(course.enrollmentStartDate, "YYYY-MM-DD");
      const enrollEnd = moment(course.enrollmentEndDate, "YYYY-MM-DD");

      // Check if the current date is between or equal to the enrollment start and end dates
      return (
        moment(currentDate).isSameOrAfter(enrollStart) &&
        moment(currentDate).isSameOrBefore(enrollEnd)
      );
    });
    courseSchedules.value = filteredData;
    addressListFromCourse.value = courseSchedules.value
      .filter((item) => item.address !== null)
      .map((item) => {
        return {
          address: item.address,
          id: item.id,
        };
      });
    selectedAddress.value = addressListFromCourse.value;
    if (courseSchedules.value) {
      calendarAttributes.value = await getCalenderAttribute();
    }
  } catch (error) {
    console.log("error:", error);
  } finally {
    console.log(calendarAttributes.value, "calendarAttributes");
    filteredCalendarAttributes.value = calendarAttributes.value;
    isLoadingDates.value = false;
  }
};

// Function to calculate time left
const calculateTimeLeft = async (startDate) => {
  const now = moment().startOf("day");
  const eventStart = moment(startDate).startOf("day");
  const timeLeft = eventStart.diff(now);

  if (timeLeft <= 0) {
    return TIME_LEFT_DONE.value;
  }

  const yearsLeft = eventStart.diff(now, "years");
  const monthsLeft = eventStart.diff(now, "months");
  const daysLeft = Math.ceil(eventStart.diff(now, "days", true));
  const hoursLeft = eventStart.diff(now, "hours");
  const minutesLeft = eventStart.diff(now, "minutes");
  if (yearsLeft >= 1) {
    return `${yearsLeft} ${yearsLeft > 1 ? "Years" : "Year"}`;
  } else if (monthsLeft >= 1) {
    return `${monthsLeft} ${monthsLeft > 1 ? "Months" : "Month"}`;
  } else if (daysLeft % 7 === 0) {
    const weeks = daysLeft / 7;
    return `${weeks} ${weeks > 1 ? "Weeks" : "Week"}`;
  } else if (daysLeft >= 1) {
    return `${daysLeft} ${daysLeft > 1 ? "Days" : "Day"}`;
  } else if (hoursLeft >= 1) {
    return `${hoursLeft} ${hoursLeft > 1 ? "Hours" : "Hour"}`;
  } else {
    return `${minutesLeft} ${minutesLeft > 1 ? "Minutes" : "Minute"}`;
  }
};

const fetchLearnersCount = async (scheduleId) => {
  try {
    const response = await store.dispatch(
      `${COURSES_STORE}/getCourseScheduleById`,
      {
        courseScheduleId: scheduleId,
      }
    );
    const { course } = response;

    const { userCourses } = course;

    if (userCourses.length) {
      let users = [];
      userCourses.forEach((userCourse) => {
        const { userCourseSchedules, user } = userCourse;

        if (userCourseSchedules.length) {
          const item = userCourseSchedules.find((userCourseSchedule) => {
            return userCourseSchedule?.courseSchedule?.id === scheduleId;
          });
          if (item) {
            users.push(user);
          }
        }
      });
      return users.length;
    }

    return 0;
  } catch (error) {
    console.error(
      `Failed to fetch learners count for course ${scheduleId}:`,
      error
    );
    return 0; // Default to 0 if there's an error
  }
};

const getSpotRemaining = async (spotLimit, allotedSpot) => {
  // const enrolledLearnersCount = await fetchLearnersCount(scheduleId); // depracated. Use allotedSpot instead
  let spots;
  if (allotedSpot >= spotLimit) {
    spots = 0;
  } else {
    spots = spotLimit - allotedSpot;
  }
  return spots;
};

const getCalenderAttribute = async () => {
  if (selectedAddress.value.length) {
    const selectedIds = selectedAddress.value.map((item) => item.id);

    const filteredCourseSchedulesByAddress = courseSchedules.value.filter(
      (schedule) => selectedIds.includes(schedule.id)
    );

    const results = await Promise.all(
      filteredCourseSchedulesByAddress.map(async (course) => ({
        ...course,
        spotsRemaining: await getSpotRemaining(
          course.spotLimit,
          course.allotedSpot
        ),
        timeLeft: await calculateTimeLeft(course.enrollmentEndDate),
        dates: new Date(course?.startDate),
      }))
    );

    return results;
  }

  const results = await Promise.all(
    courseSchedules.value.map(async (course) => ({
      ...course,
      spotsRemaining: await getSpotRemaining(
        course.spotLimit,
        course.allotedSpot
      ),
      // timeLeft: await calculateTimeLeft(course.startDate, course.duration),
      dates: new Date(course?.startDate),
    }))
  );

  return results;
};

const numberOfSpotsReserved = computed(() => {
  // Sum all spots in selectedDates
  let totalSpots = 0;
  if (selectedDates.value && selectedDates.value.length) {
    totalSpots = selectedDates.value.reduce((sum, item) => {
      return sum + item.spots;
    }, 0);
  }

  return totalSpots;
});

const onIncrementSpot = (index) => {
  if (selectedDates.value[index].spotsRemaining) {
    selectedDates.value[index].spots++;
    selectedDates.value[index].spotsRemaining--;
  }
};
const onDecrementSpot = (index) => {
  if (selectedDates.value[index].spots > 1) {
    selectedDates.value[index].spots--;
    selectedDates.value[index].spotsRemaining++;
  }
};
const onRemoveReservation = (index) => {
  selectedDates.value.splice(index, 1); // Removes the reservation
};

watch(
  () => selectedDates.value.length,
  () => {
    console.log(selectedDates.value, "selectedDates");
    if (selectedDates.value.length) {
      numberOfSelectedDates.value = `${selectedDates.value.length} Date${
        selectedDates.value.length > 1 ? "s" : ""
      } Selected`;
    } else {
      numberOfSelectedDates.value = "";
    }
  },
  { deep: true }
);

watch(
  () => numberOfSelectedDates.value,
  () => {
    if (!selectedDates.value.length && numberOfSelectedDates.value !== "") {
      isShowSelectDates.value = true;
    }
  },
  { deep: true }
);

onBeforeMount(async () => {
  courseId.value = route?.params.courseId;
  if (courseId.value === "1") {
    modelDetails.value = {
      id: 2,
      tab: "reserveSpot",
      name: "reserveSpot",
      header: "Air Source Heat Pump (Level 3)",
    };
  } else {
    modelDetails.value = {
      id: 2,
      tab: "reserveSpot",
      name: "reserveSpot",
      header: "Solar PV, Battery Storage & EV Charging Level 3",
    };
  }
  await getCourseSchedule();
  if (props.selectedSchedule) {
    selectedDates.value = [props.selectedSchedule];
    onContinue();
    isShowSelectDates.value = false;
    isShowSelectCourse.value = false;
  }
});

onBeforeUnmount(() => {
  //   eligibility.value = null;
});
</script>
<style lang="scss" scoped>
.select-dates-table {
  box-shadow: 0px 4px 8px 0px rgba(12, 15, 74, 0.08);
}

.remove-popup {
  box-shadow: 0px 0px 21px 0px rgba(12, 15, 74, 0.24);
}
</style>
