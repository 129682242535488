<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_8086_44689)">
      <path
        d="M7.99935 1.4873C5.19935 1.4873 2.66602 3.63397 2.66602 6.95397C2.66602 9.07397 4.29935 11.5673 7.55935 14.4406C7.81268 14.6606 8.19268 14.6606 8.44602 14.4406C11.6993 11.5673 13.3327 9.07397 13.3327 6.95397C13.3327 3.63397 10.7993 1.4873 7.99935 1.4873ZM7.99935 8.15397C7.26602 8.15397 6.66602 7.55397 6.66602 6.82064C6.66602 6.0873 7.26602 5.4873 7.99935 5.4873C8.73268 5.4873 9.33268 6.0873 9.33268 6.82064C9.33268 7.55397 8.73268 8.15397 7.99935 8.15397Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_8086_44689">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0 0.154297)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "WhiteMarker",
};
</script>
