<template>
  <div
    v-if="isLoading"
    class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-h-full tw-w-full tw-mt-[530px]"
  >
    <Loader :show="isLoading" />
  </div>
  <div
    class="trade__insurance__wrapper md:!tw-pb-[4rem] md:!tw-box-border"
    v-if="!isLoading"
  >
    <DashBoardWelcome class="white-card" :isShowCloseButton="false">
      <template v-slot:title><h3>Insurances</h3></template>
      <template v-slot:text>
        <v-btn
          @click="onClickAddNewInsurance"
          v-if="getUserWorkStationFieldAccess"
          class="button button-orange"
          >add new insurance</v-btn
        >
      </template>
      <template v-slot:image>
        <img
          src="@/assets/images/insurance-banner.png"
          class="!tw-w-[240px] !tw-h-[120px] rsm:!tw-w-[280px] rsm:!tw-h-[156px]"
      /></template>
    </DashBoardWelcome>
    <div class="trade__certification__wrapper">
      <div class="trade__header">
        <h4>Your Insurances</h4>
      </div>
      <v-row class="trade-passport-row row-1">
        <v-card
          class="custom-card"
          v-for="insurance in userInsurances"
          :key="insurance.id"
        >
          <div>
            <div class="insurances-list">
              <div>
                <!-- <CommonChip
                :label="chipLabel(insurance?.userVerificationStatus)"
                :bgColor="chipColor(insurance?.userVerificationStatus)"
              /> -->
                <PendingChip
                  class="tw-w-[100px]"
                  v-if="
                    insurance?.userVerificationStatus ===
                    UserVerificationStatusEnum.PENDING_VERIFICATION
                  "
                />
                <AcceptChip
                  class="tw-w-[100px]"
                  label="Accepted"
                  v-if="
                    insurance?.userVerificationStatus ===
                    UserVerificationStatusEnum.VERIFIED
                  "
                />
                <RejectChip
                  class="tw-w-[100px]"
                  label="Rejected"
                  v-if="
                    insurance?.userVerificationStatus ===
                    UserVerificationStatusEnum.REJECTED
                  "
                />
              </div>
              <ItemListCard
                :isShowEditButton="getUserWorkStationFieldAccess"
                :isShowDeleteButton="getUserWorkStationFieldAccess"
                :onClickDelete="() => onDeleteItem(insurance.id)"
                :onClickEdit="() => editInsurance(insurance)"
              >
                <template v-slot:listItemCardTitle>
                  {{
                    insurance?.metadata?.insuranceName ||
                    insurance?.insurance?.name
                  }}
                  <div
                    class="prefix"
                    v-if="isExpiredDate(insurance?.metadata?.expiryDate)"
                  >
                    <v-icon>
                      <img src="../../../assets/icons/exclaimation.svg" />
                    </v-icon>
                    Expired
                  </div>
                </template>
                <template v-slot:listItemCardLabel
                  >Coverage: £{{
                    formatSum(insurance?.metadata?.sum)
                  }}</template
                >
                <template v-slot:listItemCardText
                  >Expiry:
                  {{ formatDate(insurance?.metadata?.expiryDate) }}</template
                >
              </ItemListCard>
              <div v-if="insurance?.attachment?.length" class="pdf__uploader">
                <span>
                  {{ insurance?.attachment[0]?.originalName }}
                </span>
              </div>
            </div>
          </div>
        </v-card>
      </v-row>
    </div>

    <InsuranceModel
      v-if="isShowInsuranceModal"
      @onClickCloseTab="onClickCloseModel"
      :userInsuranceData="userInsuranceData"
      :isOuterEditInsurance="isOuterEditInsurance"
      @clearUserInsuranceData="clearData"
    />

    <ConfirmDeleteModal
      v-if="isOpenConfirmDelete"
      type="insurance"
      label="You're about to delete your insurance."
      @on-close="onCloseConfirmDelete"
      @on-delete="deleteItem"
      :loading="deleteLoader"
    ></ConfirmDeleteModal>
  </div>
</template>

<script lang="ts">
import { computed, onBeforeMount, ref } from "vue";
import { useStore } from "vuex";
import { USER_STORE } from "@/store/modules/user";
import ItemListCard from "@/core/components/ItemListCard.vue";
import InsuranceModel from "@/modules/insurance/components/InsuranceModel.vue";
import { WORKSTATION } from "@/store/modules/workstation";
import { isExpiredDate } from "@/core/utils/common";
import { UserVerificationStatusEnum } from "@/core/enums/RolesEnum";
import AcceptChip from "@/modules/dashboard/components/Chips/AcceptChip.vue";
import RejectChip from "@/modules/dashboard/components/Chips/RejectChip.vue";
import PendingChip from "@/modules/dashboard/components/Chips/PendingChip.vue";
// import CommonChip from "@/modules/dashboard/components/Chips/CommonChip.vue";
import ConfirmDeleteModal from "@/modules/dashboard/components/Modals/ConfirmDeleteModal.vue";
import DashBoardWelcome from "@/core/components/DashBoardWelcome.vue";
import insurancesService from "@/core/services/insurances.service";
import { INSURANCE_STORE } from "@/store/modules/insurance";
import Loader from "@/core/components/common/Loader.vue";

export default {
  components: {
    ItemListCard,
    InsuranceModel,
    AcceptChip,
    RejectChip,
    PendingChip,
    // CommonChip,
    ConfirmDeleteModal,
    DashBoardWelcome,
    Loader,
  },
  setup() {
    const store = useStore();
    const isShowInsuranceModal = ref(false);

    const isOpenConfirmDelete = ref(false);
    const confirmDeleteId = ref(null) as any;

    const user = computed(() => store.getters[`${USER_STORE}/user`]);
    const deleteLoader = ref(false);
    const userInsuranceData = ref(null) as any;
    const isLoading = ref(false);
    const userInsurances = computed(
      () => store.getters[`${USER_STORE}/userInsurances`]
    );

    const formatSum = (sum: any) => {
      return new Intl.NumberFormat("en-US", {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(sum);
    };

    const activeUserWorkstation = computed(
      () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
    );

    const userWorkStationStatus = computed(
      () =>
        store.getters[`${WORKSTATION}/activeUserWorkstation`]
          ?.verificationStatus
    );

    const isWorkStationVerified = computed(() => {
      return (
        userWorkStationStatus.value === UserVerificationStatusEnum.VERIFIED
      );
    });
    const isOuterEditInsurance = ref(false);
    const getUserWorkStationFieldAccess = computed(
      () => store.getters[`${WORKSTATION}/getUserWorkStationFieldAccess`]
    );
    onBeforeMount(async () => {
      try {
        isLoading.value = true;
        if (user.value?.id && !userInsurances.value?.length) {
          await store.dispatch(
            `${USER_STORE}/setUserInsurances`,
            user.value.id
          );
        }
      } catch (error) {
        console.log();
      } finally {
        isLoading.value = false;
      }
    });

    const onClickAddNewInsurance = () => {
      isShowInsuranceModal.value = true;
    };
    const onClickCloseModel = () => {
      isShowInsuranceModal.value = false;
    };
    const onDeleteItem = (id: any) => {
      confirmDeleteId.value = id;
      isOpenConfirmDelete.value = true;
    };

    const onCloseConfirmDelete = () => {
      confirmDeleteId.value = null;
      isOpenConfirmDelete.value = false;
    };
    const onDeleteInsurance = async (insuranceId: string) => {
      try {
        await store.dispatch(`${USER_STORE}/removeUserInsurance`, {
          userId: user.value.id,
          userInsuranceId: insuranceId,
        });
        if (userInsurances.value.length === 0 && isWorkStationVerified.value) {
          const responce: any = await insurancesService.isInsuranceExpired(
            user.value.id
          );
          if (responce.isFreezed) {
            store.commit(`${INSURANCE_STORE}/setInsuranceExpired`, true);
          }
        }
        await store.dispatch(`${USER_STORE}/getAccountStatuses`);
      } catch (error) {
        console.log();
      }
    };
    const deleteItem = async () => {
      deleteLoader.value = true;
      await onDeleteInsurance(confirmDeleteId.value);
      deleteLoader.value = false;
      onCloseConfirmDelete();
    };
    const editInsurance = (insurance: object) => {
      isShowInsuranceModal.value = true;
      userInsuranceData.value = insurance;
      isOuterEditInsurance.value = true;
    };
    const clearData = () => {
      userInsuranceData.value = null;
    };
    const chipLabel = (status: any) => {
      if (UserVerificationStatusEnum.PENDING_VERIFICATION === status)
        return "Pending";
      else if (UserVerificationStatusEnum.REJECTED === status)
        return "Rejected";
      else if (UserVerificationStatusEnum.VERIFIED === status)
        return "Verified";
      else if (UserVerificationStatusEnum.NOT_ADDED === status)
        return "Not added";
    };
    const chipColor = (status: any) => {
      if (UserVerificationStatusEnum.PENDING_VERIFICATION === status)
        return "#FAA500";
      else if (UserVerificationStatusEnum.REJECTED === status) return "red";
      else if (UserVerificationStatusEnum.VERIFIED === status) return "green";
      else if (UserVerificationStatusEnum.NOT_ADDED === status)
        return "#7467B7";
    };

    const formatDate = (dateString: string) => {
      const date = new Date(dateString);
      const day = date.getUTCDate();
      const month = date.getUTCMonth() + 1;
      const year = date.getUTCFullYear();
      const formattedDay = day < 10 ? "0" + day : day;
      const formattedMonth = month < 10 ? "0" + month : month;
      return `${formattedDay}/${formattedMonth}/${year}`;
    };
    return {
      onClickAddNewInsurance,
      userInsurances,
      onDeleteInsurance,
      isShowInsuranceModal,
      onClickCloseModel,
      activeUserWorkstation,
      isExpiredDate,
      editInsurance,
      userInsuranceData,
      isOuterEditInsurance,
      chipLabel,
      chipColor,
      UserVerificationStatusEnum,
      clearData,
      formatSum,
      isOpenConfirmDelete,
      deleteItem,
      onDeleteItem,
      onCloseConfirmDelete,
      getUserWorkStationFieldAccess,
      formatDate,
      deleteLoader,
      isLoading,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/modules/trade-passport/styles/TradePassport.scss";
</style>

<div>
    <slot name="listItemLogo"></slot>
  </div>
