<template>
  <div class="tw-flex tw-w-full tw-gap-4 tw-items-center tw-justify-center">
    <LabelWithValue
      :flexColumnReverse="true"
      :justifyCenter="true"
      label="Start date"
      :data="suggestedStartDate"
    />
    <LabelWithValue
      :flexColumnReverse="true"
      :justifyCenter="true"
      label="Total phases"
      :data="totalPhaseLength"
    />
    <LabelWithValue
      v-if="!isHistoricalProject"
      :flexColumnReverse="true"
      :justifyCenter="true"
      label="Duration"
      :data="totalDuration"
    />
  </div>
</template>
<script setup>
import { computed, reactive, onMounted } from "vue";
import { useStore } from "vuex";

import LabelWithValue from "@/core/components/common/LabelWithValue.vue";
import appFilters from "@/filters";
import { JOB_TEMPLATE_STORE } from "@/store/modules/job-template";
import moment from "moment";
const store = useStore();

const props = defineProps([
  "selectedEstimate",
  "isReadOnly",
  "type",
  "isHistoricalProject",
]);

const estimateForm = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/estimateForm`]
);

const formData = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/formData`]
);

const suggestedStartDate = computed(() => {
  let startDate = "";

  // isReadOnly means PO received estimate else TP estimate form
  if (props.isReadOnly) {
    startDate = appFilters.formatToDate(
      props.selectedEstimate?.suggestedStartDate,
      "DD MMM YYYY"
    );
  } else {
    startDate = appFilters.formatToDate(
      formData.value.suggestedStartDate,
      "DD MMM YYYY"
    );
  }
  return startDate;
});

const totalDuration = computed(() => {
  let totalDays = 0;
  if (props.isReadOnly) {
    const startDate = moment(props.selectedEstimate?.suggestedStartDate);
    const endDate = moment(props.selectedEstimate?.projectEstimateEndDate);

    totalDays = endDate.diff(startDate, "days");
  } else {
    totalDays = estimateForm.value.projectStages.reduce((sum, stage) => {
      if (stage.totalDuration) {
        const duration = parseFloat(stage.totalDuration);
        if (stage.durationType === 1) {
          return sum + duration;
        } else if (stage.durationType === 2) {
          return sum + duration * 7;
        }
      }

      return sum;
    }, 0);
  }

  if (!totalDays) return `0 day`;
  return `${totalDays} day${totalDays > 1 ? "s" : ""}`;
});

const totalPhaseLength = computed(() => {
  let length = 0;

  // isReadOnly means PO received estimate else TP estimate form
  if (props.isReadOnly && props.selectedEstimate) {
    length = props.selectedEstimate?.estimationPhase?.length;
  } else {
    length = estimateForm.value?.projectStages?.length || 0;
  }
  return length;
});
</script>
<script>
export default {
  name: "EstimateSummaryData",
};
</script>
<style lang="scss" scoped></style>
