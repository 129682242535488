export enum UserNotificationType {
  USER_NETWORK = "networkRequest",
  TRADE_USER_CONNECTION = "TradeUserConnection",
  USER_REQUEST = "userRequest",
  PROJECT_CHAT = "ProjectChatRequest",
  PROPERTY_USER_CONNECTION = "PropertyUserConnection",
  VERIFY_JOBS = "historicalJob",
  CLIENT_HISTORICAL_PROJECT = "clientHistoricalJob",
  EXTERNAL_TRADE_NETWORK = "ExternalTradeNetwork",
  VIDEO_CALL = "videoCall",
  RAISE_PROJECT_TYPE = "RaiseProjectType",
  REJECTED_RAISE_PROJECT = "RejectRaiseProject",
  RAISE_NOTIFICATION = "RaiseNotification",
  RAISE_ISSUE_BY_OCCUPIER = "issueRaisedByOccupier",
  OUTERNETWORKREQUEST = "outerNetworkRequest",
  CONTRACTORINVITEREQUEST = "contractorInviteRequest",
  INSURANCEEXPIRATION = "insuranceExpiration",
  ASSIGN_TEAM_ESTIMATE = "assignTeamEstimate",
  WA_MEMBER_REQUEST = "wsMemberRequest",
  PHASE_REQUEST = "phaseRequest",
  ACCEPTWORKSTATIONMEMBERREQUEST = "acceptWorkStationMemberRequest",
  FIRSTTIMEWORKSTATIONLAUNCH = "firstTimeLaunchWorkstation",
  ACCEPTTRADENETWORKREQUEST = "acceptTradeNetworkRequest",
}

export enum UserNetworkRequestStatus {
  PENDING = "pending",
  ACCEPTED = "accepted",
  REJECTED = "rejected",
}

export enum ProjectMetaDataStatusEnum {
  PENDING = 0,
  ACCEPTED = 1,
  REJECTED = 2,
  NOT_STARTED = 4,
}

export enum UserWorkstationSubStatusEnum {
  PROCESSING = "processing",
  SUCCEEDED = "succeeded",
  AMOUNT_UPDATED = "amount_capturable_updated",
  FAILED = "payment_failed",
}

export enum InvoiceItem {
  WORKSTATION = "workstation",
  MEMBERS = "members",
  STORAGE = "storage",
}
