<template>
  <Card
    class="!tw-max-h-[unset] !tw-min-w-[262px] !tw-max-w-[262px] !tw-py-5 !tw-px-4 tw-box-border md:!tw-w-full md:!tw-max-w-[unset]"
  >
    <template #content>
      <main
        class="tw-w-full tw-h-full tw-flex tw-flex-col tw-gap-4 tw-justify-between"
      >
        <section class="tw-w-full tw-flex tw-flex-col tw-items-start">
          <Text variant="p" textWeight="400">Memory Usage</Text>
          <div class="tw-flex tw-items-center tw-gap-2 tw-w-full">
            <v-progress-linear
              :color="getColorProgess"
              :model-value="state.documentProgress"
              height="8"
            />
            <Text variant="span" textWeight="500"
              >{{ state.documentProgress }}%</Text
            >
          </div>
          <Text variant="span" textWeight="600" :textColor="getColorText"
            >{{ state.documentAvailableStorage }} Available</Text
          >
        </section>
        <section
          v-if="isLowMemory"
          class="tw-w-full tw-flex tw-flex-col tw-items-start tw-p-3 tw-box-border tw-bg-[#FFE2E0] tw-rounded-lg"
        >
          <div class="tw-flex tw-items-center tw-gap-2">
            <v-icon icon="mdi-alert-circle" color="#F24822"></v-icon>
            <Text variant="span">ALERT: Low Memory</Text>
          </div>
          <Text variant="span" textWeight="400" textAlign="left"
            >Your document storage system is running low on memory. This may
            impact your ability to upload, access, or modify documents.</Text
          >
        </section>
        <Button
          variant="secondary"
          label="Manage Memory"
          @click="onOpenManageMemoryModal"
        />

        <!-- MODALS -->
        <ManageMemoryModal
          v-if="state.manageMemoryModal"
          @on-close="onCloseManageMemoryModal"
        />
      </main>
    </template>
  </Card>
  <PageRestrictionNoticeModal
    v-if="isShowWorkstationRestrictionModal"
    @on-close="toggleRestrictionModal"
  />
</template>
<script setup>
import {
  computed,
  onBeforeMount,
  onMounted,
  reactive,
  watch,
  getCurrentInstance,
} from "vue";
import { useStore } from "vuex";

import Button from "@/core/components/ui/general/Button.vue";
import Card from "@/core/components/ui/general/Card.vue";
import Text from "@/core/components/ui/general/Text.vue";
import ManageMemoryModal from "@/modules/documents/components/modal/ManageMemoryModal.vue";
import { formatBytes } from "@/core/helpers/file.helper";
import { DOCUMENTS_STORE } from "@/store/modules/documents";
import { USER_STORE } from "@/store/modules/user";
import { WORKSTATION } from "@/store/modules/workstation";
import PageRestrictionNoticeModal from "@/core/components/modals/PageRestrictionNoticeModal.vue";
import { ref } from "vue";
import $axios from "@/core/utils/axios-api-config";

const store = useStore();

const emits = defineEmits(["on-update-plan"]);
const props = defineProps(["data"]);

const state = reactive({
  documentProgress: 0,
  documentAvailableStorage: "",
  manageMemoryModal: false,
});

const isLowMemory = computed(() => {
  return state.documentProgress > 74;
});

const getColorProgess = computed(() => {
  let color = "#FFA500";
  if (isLowMemory.value) {
    color = "#F24822";
  }
  return color;
});

const user = computed(() => store.getters[`${USER_STORE}/user`]);

const onOpenManageMemoryModal = () => {
  if (isPersonalAccountWorkStation.value) return toggleRestrictionModal();
  state.manageMemoryModal = true;
};

const onCloseManageMemoryModal = () => {
  emits("on-update-plan");
  state.manageMemoryModal = false;
};

const getColorText = () => {
  let color = "#FFA500";
  if (isLowMemory.value) {
    color = "#F24822";
  }
  color = "rgba(12, 15, 74, 0.5)";
  return color;
};

const normalizeData = () => {
  if (props.data) {
    state.documentProgress = props.data?.documentProgress;
    state.documentAvailableStorage = props.data?.documentAvailableStorage;
    // state.documentAvailableStorage = formatBytes(
    //   props.data?.documentAvailableStorage
    // );
  }
};
const isShowWorkstationRestrictionModal = ref(false);
const isPersonalAccountWorkStation = computed(
  () =>
    store.getters[`${WORKSTATION}/activeUserWorkstation`]?.name === "Default"
);

const toggleRestrictionModal = () => {
  isShowWorkstationRestrictionModal.value =
    !isShowWorkstationRestrictionModal.value;
};

watch(
  () => props.data,
  (newValue, oldValue) => {
    if (newValue) {
      normalizeData();
    }
  },
  { deep: true, immediate: true }
);
</script>
<style lang="scss" scoped></style>
