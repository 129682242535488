export default {
  courses: null,
  courseDetails: null,
  enrollCourses: null, // or reservedSpots

  selectedDates: [],
  totalReserveSpotAmount: 0,
  spotReservedDateStarted: null,

  reservedSpotsData: null, // use this when retrieving data from api
  storedReservedSpotsData: null, // use this when user selected existing workstation
  courseUpgradeResponse: null,
  coursesWithRemainingSlots: [],
  assignedCourses: null,
};
