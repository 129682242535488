<template>
  <GeneralDialog
    class="tw-w-full tw-max-w-[860px] tw-min-w-[300px] rmd:!tw-min-w-[860px]"
    @on-close="onCloseModal"
    width="auto"
  >
    <template #body>
      <main
        v-if="!loading"
        class="tw-w-full tw-flex tw-flex-col tw-gap-6 tw-items-center tw-justify-center rmd:!tw-w-[600px]"
      >
        <div
          class="tw-w-full tw-flex tw-flex-col tw-gap-4 tw-items-center tw-justify-center tw-px-0 tw-box-border rmd:!tw-px-16 rmd:!tw-gap-6"
        >
          <Text
            class="!tw-text-[22px] rmd:!tw-text-[32px] rmd:!tw-leading-[34px]"
            textAlign="center"
            lineHeight="22px"
            >Confirmation: Account Deduction of
            {{ CurrencyFilter.formatToCurrency(spotsAmount) }} (+ VAT) for
            {{ numberOfSpotsReserved }} spots</Text
          >
          <Text
            variant="p"
            textWeight="400"
            textAlign="center"
            class="rmd:!tw-text-[16px] rmd:!tw-leading-[24px]"
            lineHeight="18px"
            textColor="rgba(12, 15, 74, 0.5)"
          >
            Please continue by confirming this transaction. We will deduct the
            total amount of
            {{ CurrencyFilter.formatToCurrency(spotsAmount) }} (+ VAT) from your
            account. Ensure you have sufficient funds to complete this payment.
            Should you encounter any issues, please contact our support team for
            assistance.
          </Text>
        </div>
      </main>
      <main
        class="tw-w-full tw-flex tw-flex-col tw-gap-6 tw-items-center tw-justify-center rmd:!tw-w-[600px]"
        v-else
      >
        <CommonLoader
          v-if="loading"
          :loading="loading"
          class="loader-container"
        />
      </main>
    </template>
    <template #footer>
      <div
        class="tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center tw-px-0 tw-gap-4 md:!tw-gap-[8px]"
      >
        <Button label="Continue" @click="onUpgrade" :loading="isUpgrading" />
        <div
          class="tw-w-full tw-pb-4 tw-flex tw-items-center tw-gap-2 tw-justify-center"
        >
          <Text
            @click="onCloseModal"
            variant="xsmall"
            class="tw-cursor-pointer md:!tw-leading-[12px] rmd:!tw-text-[14px]"
            lineHeight="18px"
            textAlign="center"
            textColor="#4F55F0"
            textWeight="600"
            >Cancel</Text
          >
        </div>
      </div>
    </template>
  </GeneralDialog>
</template>
<script setup>
import moment from "moment";
import {
  computed,
  ref,
  watchEffect,
  onMounted,
  onUnmounted,
  onBeforeMount,
  getCurrentInstance,
} from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
// import { useTimer } from "vue-timer-hook";

import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import { WORKSTATION } from "@/store/modules/workstation";
import { COURSES_STORE } from "@/store/modules/courses";
import {
  DASHBOARD_ROUTE,
  DASHBOARD_WITH_COURSE_ROUTE,
} from "@/modules/dashboard/routes";
import { USER_STORE } from "@/store/modules/user";
import CommonLoader from "@/core/components/CommonLoader.vue";
import ChipState from "@/core/components/common/ChipState.vue";
import CurrencyFilter from "@/filters/currency.filter";

const internalInstance = getCurrentInstance();
const store = useStore();
const router = useRouter();
const route = useRoute();

const emits = defineEmits([
  "on-close",
  "on-cancel",
  "on-upgrade-success",
  "on-upgrade-fail",
]);

const courseId = ref(null);
const loading = ref(false);

const isUpgrading = ref(false);

const selectedSubscriptionType = ref("quarterly");

// const time = new Date();
// time.setSeconds(time.getSeconds() + 600); // 10 minutes timer
// const timer = useTimer(time);

const user = computed(() => store.getters[`${USER_STORE}/user`]);

const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);

const selectedDatesStore = computed(
  () => store.getters[`${COURSES_STORE}/selectedDates`]
);

const spotReservedDateStarted = computed(
  () => store.getters[`${COURSES_STORE}/spotReservedDateStarted`]
);

const courseUpgradeResponse = computed(
  () => store.getters[`${COURSES_STORE}/courseUpgradeResponse`]
);

const isDefault = computed(() => {
  return activeUserWorkstation.value.name === "Default";
});

const courseName = computed(() => {
  courseId.value = route?.params.courseId;
  if (courseId.value === "1") {
    return "Air Source Heat Pump (Level 3)";
  }
  return "Solar PV, Battery Storage & EV Charging Level 3";
});

const reservedSpotsData = computed(
  () => store.getters[`${COURSES_STORE}/reservedSpotsData`]
);

const storedReservedSpotsData = computed(
  () => store.getters[`${COURSES_STORE}/storedReservedSpotsData`]
);

const filteredReserveSpots = computed(() => {
  const courseId = route?.params?.courseId;

  if (storedReservedSpotsData.value && storedReservedSpotsData.value.length) {
    // Filter spots by courseId and and no subscription
    return storedReservedSpotsData.value.filter((spot) => {
      return spot.course?.id == courseId && !spot.subscription;
    });
  }

  return [];
});

const spotsAmount = computed(() => {
  // Sum all spots in selectedDates
  const totalSpots = filteredReserveSpots.value.reduce((sum, item) => {
    return sum + item.selectedSpotCount;
  }, 0);

  // Multiply the sum by 29.97
  return totalSpots * 29.97;
});

const vatAmount = computed(() => {
  return spotsAmount.value * 0.2;
});

const numberOfSpotsReserved = computed(() => {
  // Sum all spots in selectedDates
  let totalSpots = 0;
  if (filteredReserveSpots.value && filteredReserveSpots.value.length) {
    totalSpots = filteredReserveSpots.value.reduce((sum, item) => {
      return sum + item.selectedSpotCount;
    }, 0);
  }

  return totalSpots;
});

const formattedStartDates = computed(() => {
  if (selectedDatesStore.value && selectedDatesStore.value.length) {
    return selectedDatesStore.value
      .map((item) => moment(item.startDate).format("DD MMMM YYYY")) // Format each startDate
      .join(", "); // Join all formatted dates with a comma and space
  }
  return filteredReserveSpots.value
    .map((item) =>
      moment(item.userCourseSchedules[0].courseSchedule.startDate).format(
        "DD MMMM YYYY"
      )
    ) // Format each startDate
    .join(", "); // Join all formatted dates with a comma and space
});

const selectSubscriptionType = (type) => {
  selectedSubscriptionType.value = type;
};

const displayToast = (message, type) => {
  if (
    internalInstance &&
    internalInstance.appContext.config.globalProperties.$notify
  ) {
    internalInstance.appContext.config.globalProperties.$notify[type]({
      message,
    });
  }
};

const onUpgrade = async () => {
  // gather payload
  if (filteredReserveSpots.value && filteredReserveSpots.value.length) {
    const parsedSubscription = JSON.parse(
      activeUserWorkstation.value.subscription
    );
    const userId = user.value.id;
    const wsIdParam = String(route.params?.workstationId);
    const userCourseIds = filteredReserveSpots.value.map((spot) => {
      return spot.id;
    });

    let planType = null;

    if (parsedSubscription?.planType) {
      planType = parsedSubscription?.planType;
    } else {
      planType = parseInt(parsedSubscription.metadata.planType, 10);
    }

    console.log(
      JSON.parse(activeUserWorkstation.value.subscription),
      "activeUserWorkstation"
    );

    const payload = {
      userCourseIds,
      workStationId: parseInt(wsIdParam, 10),
      planType,
    };

    console.log(payload, "onUpgrade payload");

    try {
      isUpgrading.value = true;
      const response = await store.dispatch(
        `${COURSES_STORE}/courseUpgradeSubscription`,
        {
          userId,
          payload,
        }
      );
      if (response) {
        console.log(response, "response from upgrade");
        // open success modal
        onCloseModal();
        displayToast(
          "Reserved spot(s) have been successfully assigned to this workstation.",
          "success"
        );
      }
    } catch (error) {
      console.log(error);
      // open error modal
      displayToast(
        "Error assigning reserved spot(s) to this workstation.",
        "error"
      );
    } finally {
      isUpgrading.value = false;
      console.log(courseUpgradeResponse.value, "courseUpgradeResponse");
    }
  }
};

const onCloseModal = () => {
  emits("on-close");
};

onMounted(async () => {
  //
  console.log(storedReservedSpotsData.value, "storedReservedSpotsData");
});
onUnmounted(() => {
  //
});
</script>
