<template>
  <GeneralDialog
    class="tw-w-full tw-max-w-[860px] tw-min-w-[300px] rmd:!tw-min-w-[860px]"
    @on-close="onCloseModal"
    width="auto"
  >
    <template #body>
      <main
        v-if="!loading"
        class="tw-w-full tw-flex tw-flex-col tw-gap-6 tw-items-center tw-justify-center rmd:!tw-w-[600px]"
      >
        <div
          class="tw-w-full tw-flex tw-flex-col tw-gap-4 tw-items-center tw-justify-center tw-px-0 tw-box-border rmd:!tw-px-16 rmd:!tw-gap-6"
        >
          <Text
            class="!tw-text-[22px] rmd:!tw-text-[32px] rmd:!tw-leading-[34px]"
            textAlign="center"
            lineHeight="22px"
            >Looks like you need to upgrade your plan.</Text
          >
          <Text
            variant="p"
            textWeight="400"
            textAlign="center"
            class="rmd:!tw-text-[16px] rmd:!tw-leading-[24px]"
            lineHeight="18px"
            textColor="rgba(12, 15, 74, 0.5)"
          >
            You are currently on a <strong>Monthly Plan</strong>. To secure your
            spot for the selected
            <strong>{{ courseName }} on {{ formattedStartDates }}</strong
            >, we require a minimum of
            <strong>3-Month subscription,</strong> totalling
            <strong>£29.97</strong> + VAT, per spot.
          </Text>
        </div>

        <div
          class="tw-w-full tw-flex tw-items-center tw-justify-center tw-gap-1 tw-flex-col tw-py-5 tw-box-border tw-rounded-[8px] tw-bg-[#F4F7FF]"
        >
          <div
            class="tw-min-w-[31px] tw-w-[31px] tw-h-[31px] tw-rounded-[5px] tw-overflow-hidden rmd:!tw-min-w-[50px] rmd:!tw-w-[50px] rmd:!tw-h-[50px] rmd:!tw-rounded-[8px]"
          >
            <img
              class="tw-w-full tw-h-full tw-object-cover"
              :src="require('@/assets/images/sample-avatar.jpg')"
            />
          </div>
          <img class="" />
          <Text variant="p" lineHeight="18px" class="rmd:!tw-text-[16px]"
            >Workstation name</Text
          >
        </div>
        <div class="tw-w-full tw-flex tw-flex-col tw-gap-4">
          <!-- yearly -->
          <div
            @click="selectSubscriptionType('yearly')"
            :class="{
              '!tw-border-[#FAA500]': selectedSubscriptionType === 'yearly',
            }"
            class="tw-rounded-[16px] tw-cursor-pointer tw-border-solid tw-border-[rgba(12,15,74,0.2)] tw-border-[2px] tw-w-full tw-flex tw-flex-col tw-gap-4 tw-p-4 tw-box-border"
          >
            <div class="tw-flex-row-between">
              <div
                class="tw-rounded-[4px] tw-border-solid tw-border-[#FAA500] tw-border-[1px] tw-flex tw-items-center tw-justify-center tw-py-[2px] tw-px-[6px] tw-box-border"
              >
                <Text variant="p">YEARLY</Text>
              </div>
              <div
                class="tw-rounded-[4px] tw-bg-[#FAA500] tw-flex tw-items-center tw-justify-center tw-py-[2px] tw-px-[6px] tw-box-border"
              >
                <Text variant="p" textColor="#fff" textWeight="400"
                  >Most Cost Effective</Text
                >
              </div>
            </div>
            <div
              class="tw-flex tw-flex-col tw-items-start tw-gap-2 rmd:!tw-flex-row rmd:!tw-items-end"
            >
              <Text variant="h3">£7.99</Text>
              <Text variant="h6" textWeight="400"
                >per month / <strong>£95.88</strong> (+ VAT)
                <strong>billed today</strong></Text
              >
            </div>
          </div>
          <!-- 3 months -->
          <div
            @click="selectSubscriptionType('quarterly')"
            :class="{
              '!tw-border-[#FAA500]': selectedSubscriptionType === 'quarterly',
            }"
            class="tw-rounded-[16px] tw-border-solid tw-border-[rgba(12,15,74,0.2)] tw-border-[2px] tw-w-full tw-flex tw-flex-col tw-gap-4 tw-p-4 tw-box-border tw-cursor-pointer"
          >
            <div class="tw-flex-row-between">
              <div
                class="tw-rounded-[4px] tw-border-solid tw-border-[#FAA500] tw-border-[1px] tw-flex tw-items-center tw-justify-center tw-py-[2px] tw-px-[6px] tw-box-border"
              >
                <Text variant="p">3-MONTHLY</Text>
              </div>
            </div>
            <div
              class="tw-flex tw-flex-col tw-items-start tw-gap-2 rmd:!tw-flex-row rmd:!tw-items-end"
            >
              <Text variant="h3">£9.99</Text>
              <Text variant="h6" textWeight="400"
                >per month / <strong>£29.97</strong> (+ VAT)
                <strong>billed today</strong></Text
              >
            </div>
          </div>
        </div>
      </main>
      <main
        class="tw-w-full tw-flex tw-flex-col tw-gap-6 tw-items-center tw-justify-center rmd:!tw-w-[600px]"
        v-else
      >
        <CommonLoader
          v-if="loading"
          :loading="loading"
          class="loader-container"
        />
      </main>
    </template>
    <template #footer>
      <div
        class="tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center tw-px-0 tw-gap-4 md:!tw-gap-[8px]"
      >
        <div
          class="tw-w-full tw-flex tw-items-center tw-gap-2 tw-justify-center"
        >
          <Text
            @click="onCancel"
            class="tw-cursor-pointer md:!tw-leading-[12px] rmd:!tw-text-[14px]"
            lineHeight="18px"
            textAlign="center"
            textColor="rgba(12, 15, 74, 0.5)"
            >Cancel Anytime After 3 Months</Text
          >
        </div>
        <Button label="Upgrade" @click="onUpgrade" :loading="isUpgrading" />
        <div
          class="tw-w-full tw-pb-4 tw-flex tw-items-center tw-gap-2 tw-justify-center"
        >
          <Text
            @click="onCloseModal"
            variant="xsmall"
            class="tw-cursor-pointer md:!tw-leading-[12px] rmd:!tw-text-[14px]"
            lineHeight="18px"
            textAlign="center"
            textColor="#4F55F0"
            textWeight="600"
            >Cancel</Text
          >
        </div>
      </div>
    </template>
  </GeneralDialog>
</template>
<script setup>
import moment from "moment";
import {
  computed,
  ref,
  watchEffect,
  onMounted,
  onUnmounted,
  onBeforeMount,
} from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
// import { useTimer } from "vue-timer-hook";

import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import { WORKSTATION } from "@/store/modules/workstation";
import { COURSES_STORE } from "@/store/modules/courses";
import {
  DASHBOARD_ROUTE,
  DASHBOARD_WITH_COURSE_ROUTE,
} from "@/modules/dashboard/routes";
import { USER_STORE } from "@/store/modules/user";
import CommonLoader from "@/core/components/CommonLoader.vue";
import ChipState from "@/core/components/common/ChipState.vue";
import { PaymentPlanType } from "@/core/enums/RolesEnum";

const store = useStore();
const router = useRouter();
const route = useRoute();

const emits = defineEmits([
  "on-close",
  "on-cancel",
  "on-upgrade-success",
  "on-upgrade-fail",
]);

const courseId = ref(null);
const loading = ref(false);

const isUpgrading = ref(false);

const selectedSubscriptionType = ref("quarterly");

// const time = new Date();
// time.setSeconds(time.getSeconds() + 600); // 10 minutes timer
// const timer = useTimer(time);

const user = computed(() => store.getters[`${USER_STORE}/user`]);

const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);

const selectedDatesStore = computed(
  () => store.getters[`${COURSES_STORE}/selectedDates`]
);

const spotReservedDateStarted = computed(
  () => store.getters[`${COURSES_STORE}/spotReservedDateStarted`]
);

const courseUpgradeResponse = computed(
  () => store.getters[`${COURSES_STORE}/courseUpgradeResponse`]
);

const isDefault = computed(() => {
  return activeUserWorkstation.value.name === "Default";
});

const courseName = computed(() => {
  courseId.value = route?.params.courseId;
  if (courseId.value === "1") {
    return "Air Source Heat Pump (Level 3)";
  }
  return "Solar PV, Battery Storage & EV Charging Level 3";
});

const reservedSpotsData = computed(
  () => store.getters[`${COURSES_STORE}/reservedSpotsData`]
);

const storedReservedSpotsData = computed(
  () => store.getters[`${COURSES_STORE}/storedReservedSpotsData`]
);

const filteredReserveSpots = computed(() => {
  const courseId = route?.params?.courseId;

  if (storedReservedSpotsData.value && storedReservedSpotsData.value.length) {
    // Filter spots by courseId and and no subscription
    return storedReservedSpotsData.value.filter((spot) => {
      return spot.course?.id == courseId && !spot.subscription;
    });
  }

  return [];
});

const numberOfSpotsReserved = computed(() => {
  // Sum all spots in selectedDates
  let totalSpots = 0;
  if (filteredReserveSpots.value && filteredReserveSpots.value.length) {
    totalSpots = filteredReserveSpots.value.reduce((sum, item) => {
      return sum + item.selectedSpotCount;
    }, 0);
  }

  return totalSpots;
});

const formattedStartDates = computed(() => {
  if (selectedDatesStore.value && selectedDatesStore.value.length) {
    return selectedDatesStore.value
      .map((item) => moment(item.startDate).format("DD MMMM YYYY")) // Format each startDate
      .join(", "); // Join all formatted dates with a comma and space
  }
  return filteredReserveSpots.value
    .map((item) =>
      moment(item.userCourseSchedules[0].courseSchedule.startDate).format(
        "DD MMMM YYYY"
      )
    ) // Format each startDate
    .join(", "); // Join all formatted dates with a comma and space
});

const selectSubscriptionType = (type) => {
  selectedSubscriptionType.value = type;
};

const onUpgrade = async () => {
  // gather payload
  if (filteredReserveSpots.value && filteredReserveSpots.value.length) {
    const userId = user.value.id;
    const wsIdParam = String(route.params?.workstationId);
    const userCourseIds = filteredReserveSpots.value.map((spot) => {
      return spot.id;
    });

    let planType = null;

    if (selectedSubscriptionType.value === "yearly") {
      planType = PaymentPlanType.YEARLY;
    }

    if (selectedSubscriptionType.value === "quarterly") {
      planType = PaymentPlanType.MONTHLY;
    }

    const payload = {
      userCourseIds,
      workStationId: parseInt(wsIdParam, 10),
      planType,
    };

    console.log(payload, "onUpgrade payload");

    try {
      isUpgrading.value = true;
      const response = await store.dispatch(
        `${COURSES_STORE}/courseUpgradeSubscription`,
        {
          userId,
          payload,
        }
      );
      if (response) {
        console.log(response, "response from upgrade");
        // open success modal
        emits("on-upgrade-success", selectedSubscriptionType.value);
      }
    } catch (error) {
      console.log(error);
      // open error modal
      emits("on-upgrade-fail");
    } finally {
      isUpgrading.value = false;
      console.log(courseUpgradeResponse.value, "courseUpgradeResponse");
    }
  }
};

const onCloseModal = () => {
  emits("on-close");
};

onMounted(async () => {
  //
});
onUnmounted(() => {
  //
});
</script>
