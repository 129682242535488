<template>
  <main>
    <!-- desktop filter -->
    <div class="desktop__wrapper tw-mt-6">
      <div class="lg:tw-hidden tw-flex tw-justify-between tw-items-center">
        <div class="tw-flex tw-gap-[8px] tw-w-full tw-mb-2">
          <InputField
            prepend-inner-icon="mdi-magnify"
            class="tw-max-w-[294px] c-input input_field"
            placeholder="Search"
            v-model="filters.search"
          />
          <InputField
            prepend-inner-icon="mdi-map-marker"
            class="tw-max-w-[143px] c-input input_field"
            placeholder="KT19 9JG"
            v-model="filters.postcode"
          />
        </div>

        <div class="tw-flex tw-gap-2 tw-justify-end tw-items-center">
          <v-btn-toggle v-model="toggledView" mandatory>
            <v-btn
              prepend-icon="mdi-view-grid"
              :active="toggledView === 'tile'"
              :color="toggledView === 'tile' ? '#FAA100' : ''"
              class="tab__buttons"
              variant="plain"
              value="tile"
              >Tile</v-btn
            >
            <v-btn
              prepend-icon="mdi-map"
              :active="toggledView === 'map'"
              :color="toggledView === 'map' ? '#FAA100' : ''"
              class="tab__buttons"
              variant="plain"
              value="map"
              >Map</v-btn
            >
          </v-btn-toggle>
          <div class="sort__wrapper desktop__wrapper">
            <v-menu
              :persistent="true"
              :close-on-content-click="false"
              class="desktop_sort_menu"
            >
              <template v-slot:activator="{ props }">
                <v-btn v-bind="props" variant="plain">
                  Sort By
                  <span>
                    <img :src="require('@/assets/icons/sort.svg')" alt="" />
                  </span>
                </v-btn>
              </template>
              <v-radio-group v-model="filters.order">
                <v-radio
                  label="Most Recent"
                  value="DESC"
                  color="indigo"
                ></v-radio>
                <v-radio label="Oldest" value="ASC" color="indigo"></v-radio>
              </v-radio-group>
            </v-menu>
          </div>
        </div>
      </div>
      <div class="lg:tw-hidden tw-flex tw-gap-2 tw-w-full">
        <v-select
          prepend-inner-icon="mdi-tools"
          class="tw-max-w-full tw-w-1/4 custom-select input_field"
          placeholder="Skills Required"
          item-value="id"
          item-title="tpLabel"
          :items="listOfCategories"
          v-model="filters.specialism"
          :menu-props="{ contentClass: 'c_select__dropdown' }"
          multiple
          clearable
          variant="outlined"
        ></v-select>
        <v-select
          prepend-inner-icon="mdi-newspaper-variant"
          class="tw-max-w-full tw-w-1/4 custom-select input_field"
          placeholder="All Jobs (Including Emergency)"
          :items="jobTypes"
          item-title="text"
          item-value="value"
          v-model="filters.projectTypes"
          clearable
          variant="outlined"
          :menu-props="{ contentClass: 'c_select__dropdown' }"
        >
        </v-select>
        <InputSelect
          prepend-inner-icon="mdi-medal-outline"
          class="tw-max-w-full tw-w-1/4 input_field"
          placeholder="Verified Property Owners Only"
          v-model="filters.verifiedOwners"
          :items="['All (Project Owners)', 'Verified & Clients']"
          :menu-props="{ contentClass: 'c_select__dropdown' }"
          variant="outlined"
        />
        <v-select
          prepend-inner-icon="mdi-account-hard-hat"
          class="tw-max-w-full tw-w-1/4 custom-select input_field"
          placeholder="Commercial / Residential"
          item-title="text"
          item-value="value"
          :items="propertyTypeItems"
          v-model="filters.propertyCategory"
          :menu-props="{ contentClass: 'c_select__dropdown' }"
          clearable
          variant="outlined"
        ></v-select>
      </div>
    </div>

    <!-- Mobile filter -->
    <div class="mobile__wrapper tw-hidden lg:tw-block mobile__filter tw-mt-6">
      <v-expansion-panels v-model="panel">
        <v-expansion-panel>
          <template v-slot:title>
            <img src="../../../assets/icons/filter-icon.svg" />
            <h6>All Filters</h6>
          </template>
          <v-expansion-panel-text>
            <div class="mobile__inputs">
              <div class="tw-flex tw-gap-[8px] tw-w-full tw-mb-2">
                <InputField
                  prepend-inner-icon="mdi-magnify"
                  class="tw-max-w-full tw-w-3/5 c-input input_field"
                  placeholder="Search"
                  v-model="filters.search"
                />
                <InputField
                  prepend-inner-icon="mdi-map-marker"
                  class="tw-max-w-full tw-w-1/5 c-input input_field"
                  placeholder="KT19 9JG"
                  v-model="filters.postcode"
                />
              </div>
              <div class="tw-flex tw-gap-2 tw-w-full tw-flex-col">
                <v-select
                  prepend-inner-icon="mdi-tools"
                  class="tw-max-w-full c-select input_field"
                  placeholder="Skills Required"
                  item-value="id"
                  item-title="tpLabel"
                  :items="listOfCategories"
                  v-model="filters.specialism"
                  :menu-props="{ contentClass: 'c_select__dropdown' }"
                  multiple
                  clearable
                  variant="outlined"
                ></v-select>
                <v-select
                  prepend-inner-icon="mdi-newspaper-variant"
                  class="tw-max-w-full c-select input_field"
                  placeholder="All Jobs (Including Emergency)"
                  :items="jobTypes"
                  item-title="text"
                  item-value="value"
                  v-model="filters.projectTypes"
                  clearable
                  variant="outlined"
                  :menu-props="{ contentClass: 'c_select__dropdown' }"
                >
                </v-select>
                <InputSelect
                  prepend-inner-icon="mdi-medal-outline"
                  class="tw-max-w-full c-select input_field"
                  placeholder="Verified Property Owners Only"
                  v-model="filters.verifiedOwners"
                  :items="['All (Project Owners)', 'Verified & Clients']"
                  :menu-props="{ contentClass: 'c_select__dropdown' }"
                  variant="outlined"
                />
                <v-select
                  prepend-inner-icon="mdi-account-hard-hat"
                  class="tw-max-w-full c-select input_field"
                  placeholder="Commercial / Residential"
                  item-title="text"
                  item-value="value"
                  :items="propertyTypeItems"
                  v-model="filters.propertyCategory"
                  :menu-props="{ contentClass: 'c_select__dropdown' }"
                  clearable
                  variant="outlined"
                ></v-select>
              </div>
            </div>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>

    <div v-if="activeUserWorkstation?.name === 'Default'">
      <div class="tw-hidden lg:tw-flex tw-items-center tw-justify-between">
        <v-btn-toggle v-model="toggledView" mandatory>
          <v-btn
            prepend-icon="mdi-view-grid"
            :active="toggledView === 'tile'"
            :color="toggledView === 'tile' ? '#FAA10066' : ''"
            class="tw-text-[12px]"
            variant="plain"
            value="tile"
            @click="onClickToggleView('tile')"
            >Tile</v-btn
          >
          <v-btn
            prepend-icon="mdi-map"
            :active="toggledView === 'map'"
            :color="toggledView === 'map' ? '#FAA10066' : ''"
            class="tw-text-[12px]"
            variant="plain"
            value="map"
            @click="onClickToggleView('map')"
            >Map</v-btn
          >
        </v-btn-toggle>
        <div class="sort__wrapper mobile__wrapper">
          <v-menu
            :persistent="true"
            :close-on-content-click="false"
            class="mobile_sort_menu"
          >
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="props"
                variant="plain"
                @click="openSortButton"
                menu
              >
                Sort By
                <span>
                  <img :src="require('@/assets/icons/sort.svg')" alt="" />
                </span>
              </v-btn>
            </template>
            <v-radio-group v-model="filters.order">
              <v-radio
                label="Most Recent"
                value="DESC"
                color="indigo"
              ></v-radio>
              <v-radio label="Oldest" value="ASC" color="indigo"></v-radio>
            </v-radio-group>
          </v-menu>
        </div>
      </div>

      <!-- <div class="tw-flex tw-gap-3 tw-mt-5 tw-mb-4">
        <Text variant="p" textColor="rgba(12, 15, 74, .5)" textWeight="400">
          <Text variant="p" textColor="rgba(12, 15, 74, .5)">{{
            jobsStateDefaultWorkstation.length
          }}</Text>
          Results
        </Text>
      </div> -->
      <div class="tw-flex tw-gap-3 tw-mt-6 tw-mb-4">
        <p
          class="tw-text-xs tw-font-normal tw-text-[#0C0F4A80] tw-tracking-[0.4px] tw-leading-4"
        >
          <strong>{{ jobsStateDefaultWorkstation.length }}</strong> Results
        </p>
      </div>

      <div
        class="tw-flex tw-gap-4 tw-overflow-y-auto tw-overflow-x-hidden no-scrollbar md:tw-max-h-[unset] working-2"
        :class="toggledView === 'map' ? 'md:tw-px-0' : ''"
      >
        <div
          class=""
          :class="
            toggledView === 'tile'
              ? 'tw-w-full '
              : 'tw-w-1/2 md:tw-absolute md:tw-z-[999] md:tw-bottom-[60px] md:tw-w-full md:tw-max-w-full md:tw-h-auto md:tw-overflow-y-hidden md:tw-overflow-x-auto  md:tw-flex  md:tw-items-end working-1 tw-max-h-[620px] tw-overflow-auto  no-scrollbar md:tw-justify-center'
          "
        >
          <template v-if="jobsStateDefaultWorkstation.length === 0">
            <div
              class="tw-w-100 tw-h-100 tw-flex tw-items-center tw-justify-center"
            >
              No Data
            </div>
          </template>
          <div
            v-else
            class="tw-flex tw-gap-4 tw-flex-wrap md:tw-gap-4"
            :class="
              toggledView === 'map'
                ? 'tw-w-full tw-max-w-full md:tw-flex-nowrap tw-pt-3 md:tw-px-0 '
                : ''
            "
          >
            <JobCard
              v-for="job in jobsStateDefaultWorkstation"
              :key="job.refId"
              @onSelect="setDefaultSelectedJob(job)"
              :value="job.refId"
              :job="job"
              jobType="all"
              :class="`${
                selectedJob === job.refId
                  ? 'tw-shadow-[0_0_0_4px_#FAA100_inset]'
                  : ''
              } ${
                toggledView === 'map'
                  ? 'tw-max-w-full tw-w-[calc(50%-14px)] xl:tw-w-full md:tw-min-w-[360px] md:tw-max-w-[360px]'
                  : '!tw-w-[calc(25%-15px)] 3xl:!tw-w-[calc(33.33%-14px)] xl:!tw-w-[calc(50%-10px)] md:!tw-w-full !tw-max-w-full'
              }`"
            />
          </div>
        </div>
        <template v-if="toggledView === 'map'">
          <div class="tw-w-1/2 md:tw-max-w-full md:tw-w-full">
            <JobMap :data="jobsState" />
          </div>
        </template>
      </div>
    </div>

    <div v-else>
      <div
        v-if="loading"
        class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-h-full tw-w-full tw-mt-[530px]"
      >
        <Loader :show="loading" />
      </div>
      <div v-if="!loading">
        <div class="tw-hidden lg:tw-flex tw-items-center tw-justify-between">
          <v-btn-toggle v-model="toggledView" mandatory>
            <v-btn
              prepend-icon="mdi-view-grid"
              :active="toggledView === 'tile'"
              :color="toggledView === 'tile' ? '#FAA10066' : ''"
              class="tw-text-[12px]"
              variant="plain"
              value="tile"
              @click="onClickToggleView('tile')"
              >Tile</v-btn
            >
            <v-btn
              prepend-icon="mdi-map"
              :active="toggledView === 'map'"
              :color="toggledView === 'map' ? '#FAA10066' : ''"
              class="tw-text-[12px]"
              variant="plain"
              value="map"
              @click="onClickToggleView('map')"
              >Map</v-btn
            >
          </v-btn-toggle>
          <div class="sort__wrapper mobile__wrapper">
            <v-menu
              :persistent="true"
              :close-on-content-click="false"
              class="mobile_sort_menu"
            >
              <template v-slot:activator="{ props }">
                <v-btn
                  v-bind="props"
                  variant="plain"
                  @click="openSortButton"
                  menu
                >
                  Sort By
                  <span>
                    <img :src="require('@/assets/icons/sort.svg')" alt="" />
                  </span>
                </v-btn>
              </template>
              <v-radio-group v-model="filters.order">
                <v-radio
                  label="Most Recent"
                  value="DESC"
                  color="indigo"
                ></v-radio>
                <v-radio label="Oldest" value="ASC" color="indigo"></v-radio>
              </v-radio-group>
            </v-menu>
          </div>
        </div>

        <div class="tw-flex tw-gap-3 tw-mt-5 tw-mb-4 md:!tw-mt-0">
          <Text variant="p" textColor="rgba(12, 15, 74, .5)" textWeight="400">
            <Text variant="p" textColor="rgba(12, 15, 74, .5)">{{
              jobsState.length
            }}</Text>
            Results
          </Text>
        </div>

        <div
          class="tw-flex tw-gap-4 tw-overflow-y-auto tw-overflow-x-hidden no-scrollbar md:tw-max-h-[unset] working-2"
          :class="
            toggledView === 'map' && jobsState.length
              ? 'md:tw-px-0 md:tw-overflow-x-auto'
              : ''
          "
        >
          <div
            class=""
            :class="
              toggledView === 'tile' || !jobsState.length
                ? 'tw-w-full '
                : ' tw-w-1/2 md:tw-absolute md:tw-z-[999] md:tw-bottom-[60px] md:tw-w-full md:tw-max-w-full md:tw-h-auto md:tw-overflow-y-hidden md:tw-overflow-x-auto  md:tw-flex  md:tw-items-end working-1 tw-max-h-full tw-overflow-auto  no-scrollbar md:tw-justify-center tw-h-full'
            "
          >
            <div
              v-if="!jobsState.length && !loading"
              class="tw-w-[45%] tw-m-auto tw-h-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-9"
            >
              <div class="tw-flex tw-flex-col">
                <img
                  width="340"
                  height="168"
                  src="../../../assets/images/property-owner-dashboard.png"
                />
              </div>
              <div class="title">
                <h3 class="tw-mb-7">Find your next job, simply and easily.</h3>
                <p>Change your radius or add services to find more jobs.</p>
              </div>
            </div>
            <div
              v-if="jobsState.length && !loading"
              class="tw-flex tw-gap-5 tw-flex-wrap"
              :class="
                toggledView === 'map' && jobsState.length ? 'working ' : ''
              "
            >
              <JobCard
                v-for="job in jobsState"
                :key="job.refId"
                @onSelect="setSelectedJob"
                :value="job.refId"
                :job="job"
                jobType="all"
                :class="`${
                  selectedJob === job.refId
                    ? 'tw-shadow-[0_0_0_4px_#FAA100_inset]'
                    : ''
                } ${
                  toggledView === 'map' && jobsState.length
                    ? 'tw-max-w-full tw-w-[calc(50%-10px)] 2xl:tw-w-full md:tw-min-w-[300px] md:tw-max-w-[300px]'
                    : '!tw-w-[calc(25%-15px)] 3xl:!tw-w-[calc(33.33%-14px)] xl:!tw-w-[calc(50%-10px)] md:!tw-w-full !tw-max-w-full'
                }`"
              />
            </div>
          </div>
          <template v-if="toggledView === 'map' && jobsState.length">
            <div
              class="tw-w-[calc(50%-16px)] tw-max-w-full md:tw-w-full md:tw-relative md:z-0 tw-h-[620px]"
            >
              <JobMap :data="jobsState" />
            </div>
          </template>
        </div>
      </div>
    </div>
  </main>
  <PageRestrictionNoticeModal
    v-if="isShowWorkstationRestrictionModal"
    @on-close="toggleRestrictionModal"
  />
</template>

<script lang="ts" setup>
import { useRouter } from "vue-router";
import { useStore } from "vuex";

import JobCard from "./JobCard.vue";
import InputField from "@/core/components/common/InputField.vue";
import InputSelect from "@/core/components/common/InputSelect.vue";
import Text from "@/core/components/ui/general/Text.vue";

import JobMap from "./JobMap.vue";
import { onMounted, reactive, ref, watch } from "vue";
import { JOB_DETAILS_ROUTE } from "../routes";
import { computed } from "vue";
import { JOBS_STORE } from "@/store/modules/jobs";
import { JobFilters, JobsState } from "@/core/models/jobs";
import debounce from "lodash/debounce";
import { USER_STORE } from "@/store/modules/user";
import {
  ProjectTypesEnum,
  PropertyCategoryEnum,
} from "@/core/enums/ProjectsEnum";
import { WORKSTATION } from "@/store/modules/workstation";
import PageRestrictionNoticeModal from "@/core/components/modals/PageRestrictionNoticeModal.vue";
import moment from "moment";
import Loader from "@/core/components/common/Loader.vue";

const router = useRouter();
const store = useStore();
const loading = ref(false);
const selectedJob = ref("");
const toggledView = ref("tile");
const isShowWorkstationRestrictionModal = ref(false);
const listOfCategories = ref([]) as any;
const panel = ref([]);

const jobTypes = ref([
  {
    text: "Emergency",
    value: ProjectTypesEnum.EMERGENCY,
  },
  {
    text: "Routine",
    value: ProjectTypesEnum.ROUTINE,
  },
  {
    text: "Standard",
    value: ProjectTypesEnum.STANDARD,
  },
  {
    text: "Video Consultation",
    value: ProjectTypesEnum.VIDEO_CONSULTATION,
  },
]);

const propertyTypeItems = computed(() => {
  return [
    {
      text: "Commercial",
      value: PropertyCategoryEnum.COMMERCIAL,
    },
    { text: "Residential", value: PropertyCategoryEnum.RESIDENTIAL },
  ];
});

const user = computed(() => store.getters[`${USER_STORE}/user`]);

const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);

const jobsState = computed<JobsState["jobs"]>(
  () => store.getters[`${JOBS_STORE}/getJobs`]
);

const jobsStateDefaultWorkstation = ref([
  {
    id: 1,
    type: "emergency",
    refId: "2bj0YQjI2WF7i8fraweOi",
    name: "Workshop Concrete Floor",
    postcode: "W12 9RE",
    description:
      "<p>A workshop concrete floor provides a durable and strong foundation ideal for various activities. It is designed to withstand heavy equipment, resist stains, and minimize dust, ensuring a clean and efficient workspace.</p>",
    dateCreated: moment().subtract(7, "seconds"),
    latitude: null,
    longitude: null,
    specialism: [],
    propertyCategory: "Residential",
    projectJobStatus: {
      id: "1",
      display: "Awaiting Quotes",
    },
    projectQuoteCount: "0",
    attachment: "1290/projects/1165/1721209959899_vU6hCnTF304PM.png",
    categories: [
      {
        tpLabel: "Drainage Specialist",
        iconName: "drainage",
      },
      {
        tpLabel: "Extensions Specialist",
        iconName: "extensions",
      },
      {
        tpLabel: "Plumber",
        iconName: "plumbing",
      },
    ],
    userWorkstation: {
      subscription:
        '"{\\"customerId\\":\\"cus_QSd0D8AhUNXDdM\\",\\"subscriptionId\\":\\"sub_1Pbhl2HMbjzNI6kGtm7JjTUi\\",\\"metadata\\":{\\"businessType\\":\\"property_owner\\",\\"capacity\\":\\"100MB\\",\\"companyName\\":\\"NOOOO\\",\\"name\\":\\"NOOOO\\",\\"planType\\":\\"2\\",\\"productName\\":\\"tradesperson-profile\\",\\"totalMember\\":\\"1\\",\\"userId\\":\\"1290\\",\\"userWorkstationId\\":\\"1626\\",\\"workstationAccountType\\":\\"homeowner\\"},\\"created\\":1720784117}"',
    },
  },
  {
    id: 2,
    type: "standard",
    refId: "6pdlUGHPrjre-1EQDGvUo",
    name: "Workshop Concrete Floor",
    postcode: "W12 9RE",
    description:
      "<p>It is designed to withstand heavy equipment, resist stains, and minimize dust, ensuring a clean and efficient workspace</p>",
    dateCreated: moment().subtract(2, "minutes"),
    latitude: null,
    longitude: null,
    specialism: [],
    propertyCategory: "Commercial",
    projectJobStatus: {
      id: "1",
      display: "Awaiting Quotes",
    },
    projectQuoteCount: "0",
    attachment: "1290/projects/1164/1721195882952_4T4TleUUeMpAg.jpeg",
    categories: [
      {
        tpLabel: "Drainage Specialist",
        iconName: "drainage",
      },
      {
        tpLabel: "Plumber",
        iconName: "plumbing",
      },
    ],
    userWorkstation: {
      subscription:
        '"{\\"customerId\\":\\"cus_QSd0D8AhUNXDdM\\",\\"subscriptionId\\":\\"sub_1Pbhl2HMbjzNI6kGtm7JjTUi\\",\\"metadata\\":{\\"businessType\\":\\"property_owner\\",\\"capacity\\":\\"100MB\\",\\"companyName\\":\\"NOOOO\\",\\"name\\":\\"NOOOO\\",\\"planType\\":\\"2\\",\\"productName\\":\\"tradesperson-profile\\",\\"totalMember\\":\\"1\\",\\"userId\\":\\"1290\\",\\"userWorkstationId\\":\\"1626\\",\\"workstationAccountType\\":\\"homeowner\\"},\\"created\\":1720784117}"',
    },
  },
  {
    id: 3,
    type: "routine",
    refId: "O0yQUOwOXxiXU8Q_e-bWV",
    name: "Test 24 May",
    postcode: "W12 9RE",
    description:
      "<p>On May 24th, a comprehensive test was conducted to evaluate the performance and reliability of the new software update.</p>",
    dateCreated: moment().subtract(3, "hour"),
    latitude: null,
    longitude: null,
    specialism: [],
    propertyCategory: "Residential",
    projectJobStatus: {
      id: "1",
      display: "Awaiting Quotes",
    },
    projectQuoteCount: "0",
    attachment: "temp/1716543690277_zy7jzfPgmIC4E.jpg",
    categories: [
      {
        tpLabel: "Drainage Specialist",
        iconName: "drainage",
      },
      {
        tpLabel: "Extensions Specialist",
        iconName: "extensions",
      },
      {
        tpLabel: "Plumber",
        iconName: "plumbing",
      },
    ],
    userWorkstation_subscription: null,
  },
  {
    id: 4,
    type: "standard",
    refId: "Jf_akiIek323Bwwczy_u8",
    name: "Solid floor insulation",
    postcode: "WV109YQ",
    description:
      "<p>Solid floor insulation involves installing insulating material beneath the surface of a solid floor, such as concrete or screed, to improve thermal efficiency and comfort.</p>",
    dateCreated: moment().subtract(2, "day"),
    latitude: "52.6037359",
    longitude: "-2.1115401",
    specialism: [],
    propertyCategory: "Residential",
    projectJobStatus: {
      id: "2",
      display: "Quotes Received",
    },
    projectQuoteCount: "1",
    attachment: null,
    categories: [
      {
        tpLabel: "Plumber",
        iconName: "plumbing",
      },
    ],
    userWorkstation_subscription: null,
  },
  {
    id: 1,
    type: "emergency",
    refId: "2bj0YQjI2WF7i8fraweOi",
    name: "Workshop Concrete Floor",
    postcode: "W12 9RE",
    description:
      "<p>A workshop concrete floor provides a durable and strong foundation ideal for various activities. It is designed to withstand heavy equipment, resist stains, and minimize dust, ensuring a clean and efficient workspace.</p>",
    dateCreated: moment().subtract(7, "seconds"),
    latitude: null,
    longitude: null,
    specialism: [],
    propertyCategory: "Residential",
    projectJobStatus: {
      id: "1",
      display: "Awaiting Quotes",
    },
    projectQuoteCount: "0",
    attachment: "1290/projects/1165/1721209959899_vU6hCnTF304PM.png",
    categories: [
      {
        tpLabel: "Drainage Specialist",
        iconName: "drainage",
      },
      {
        tpLabel: "Extensions Specialist",
        iconName: "extensions",
      },
      {
        tpLabel: "Plumber",
        iconName: "plumbing",
      },
    ],
    userWorkstation: {
      subscription:
        '"{\\"customerId\\":\\"cus_QSd0D8AhUNXDdM\\",\\"subscriptionId\\":\\"sub_1Pbhl2HMbjzNI6kGtm7JjTUi\\",\\"metadata\\":{\\"businessType\\":\\"property_owner\\",\\"capacity\\":\\"100MB\\",\\"companyName\\":\\"NOOOO\\",\\"name\\":\\"NOOOO\\",\\"planType\\":\\"2\\",\\"productName\\":\\"tradesperson-profile\\",\\"totalMember\\":\\"1\\",\\"userId\\":\\"1290\\",\\"userWorkstationId\\":\\"1626\\",\\"workstationAccountType\\":\\"homeowner\\"},\\"created\\":1720784117}"',
    },
  },
  {
    id: 2,
    type: "standard",
    refId: "6pdlUGHPrjre-1EQDGvUo",
    name: "Workshop Concrete Floor",
    postcode: "W12 9RE",
    description:
      "<p>It is designed to withstand heavy equipment, resist stains, and minimize dust, ensuring a clean and efficient workspace</p>",
    dateCreated: moment().subtract(2, "minutes"),
    latitude: null,
    longitude: null,
    specialism: [],
    propertyCategory: "Commercial",
    projectJobStatus: {
      id: "1",
      display: "Awaiting Quotes",
    },
    projectQuoteCount: "0",
    attachment: "1290/projects/1164/1721195882952_4T4TleUUeMpAg.jpeg",
    categories: [
      {
        tpLabel: "Drainage Specialist",
        iconName: "drainage",
      },
      {
        tpLabel: "Plumber",
        iconName: "plumbing",
      },
    ],
    userWorkstation: {
      subscription:
        '"{\\"customerId\\":\\"cus_QSd0D8AhUNXDdM\\",\\"subscriptionId\\":\\"sub_1Pbhl2HMbjzNI6kGtm7JjTUi\\",\\"metadata\\":{\\"businessType\\":\\"property_owner\\",\\"capacity\\":\\"100MB\\",\\"companyName\\":\\"NOOOO\\",\\"name\\":\\"NOOOO\\",\\"planType\\":\\"2\\",\\"productName\\":\\"tradesperson-profile\\",\\"totalMember\\":\\"1\\",\\"userId\\":\\"1290\\",\\"userWorkstationId\\":\\"1626\\",\\"workstationAccountType\\":\\"homeowner\\"},\\"created\\":1720784117}"',
    },
  },
  {
    id: 3,
    type: "routine",
    refId: "O0yQUOwOXxiXU8Q_e-bWV",
    name: "Test 24 May",
    postcode: "W12 9RE",
    description:
      "<p>On May 24th, a comprehensive test was conducted to evaluate the performance and reliability of the new software update.</p>",
    dateCreated: moment().subtract(3, "hour"),
    latitude: null,
    longitude: null,
    specialism: [],
    propertyCategory: "Residential",
    projectJobStatus: {
      id: "1",
      display: "Awaiting Quotes",
    },
    projectQuoteCount: "0",
    attachment: "temp/1716543690277_zy7jzfPgmIC4E.jpg",
    categories: [
      {
        tpLabel: "Drainage Specialist",
        iconName: "drainage",
      },
      {
        tpLabel: "Extensions Specialist",
        iconName: "extensions",
      },
      {
        tpLabel: "Plumber",
        iconName: "plumbing",
      },
    ],
    userWorkstation_subscription: null,
  },
  {
    id: 4,
    type: "standard",
    refId: "Jf_akiIek323Bwwczy_u8",
    name: "Solid floor insulation",
    postcode: "WV109YQ",
    description:
      "<p>Solid floor insulation involves installing insulating material beneath the surface of a solid floor, such as concrete or screed, to improve thermal efficiency and comfort.</p>",
    dateCreated: moment().subtract(2, "day"),
    latitude: "52.6037359",
    longitude: "-2.1115401",
    specialism: [],
    propertyCategory: "Residential",
    projectJobStatus: {
      id: "2",
      display: "Quotes Received",
    },
    projectQuoteCount: "1",
    attachment: null,
    categories: [
      {
        tpLabel: "Plumber",
        iconName: "plumbing",
      },
    ],
    userWorkstation_subscription: null,
  },
  {
    id: 1,
    type: "emergency",
    refId: "2bj0YQjI2WF7i8fraweOi",
    name: "Workshop Concrete Floor",
    postcode: "W12 9RE",
    description:
      "<p>A workshop concrete floor provides a durable and strong foundation ideal for various activities. It is designed to withstand heavy equipment, resist stains, and minimize dust, ensuring a clean and efficient workspace.</p>",
    dateCreated: moment().subtract(7, "seconds"),
    latitude: null,
    longitude: null,
    specialism: [],
    propertyCategory: "Residential",
    projectJobStatus: {
      id: "1",
      display: "Awaiting Quotes",
    },
    projectQuoteCount: "0",
    attachment: "1290/projects/1165/1721209959899_vU6hCnTF304PM.png",
    categories: [
      {
        tpLabel: "Drainage Specialist",
        iconName: "drainage",
      },
      {
        tpLabel: "Extensions Specialist",
        iconName: "extensions",
      },
      {
        tpLabel: "Plumber",
        iconName: "plumbing",
      },
    ],
    userWorkstation: {
      subscription:
        '"{\\"customerId\\":\\"cus_QSd0D8AhUNXDdM\\",\\"subscriptionId\\":\\"sub_1Pbhl2HMbjzNI6kGtm7JjTUi\\",\\"metadata\\":{\\"businessType\\":\\"property_owner\\",\\"capacity\\":\\"100MB\\",\\"companyName\\":\\"NOOOO\\",\\"name\\":\\"NOOOO\\",\\"planType\\":\\"2\\",\\"productName\\":\\"tradesperson-profile\\",\\"totalMember\\":\\"1\\",\\"userId\\":\\"1290\\",\\"userWorkstationId\\":\\"1626\\",\\"workstationAccountType\\":\\"homeowner\\"},\\"created\\":1720784117}"',
    },
  },
  {
    id: 2,
    type: "standard",
    refId: "6pdlUGHPrjre-1EQDGvUo",
    name: "Workshop Concrete Floor",
    postcode: "W12 9RE",
    description:
      "<p>It is designed to withstand heavy equipment, resist stains, and minimize dust, ensuring a clean and efficient workspace</p>",
    dateCreated: moment().subtract(2, "minutes"),
    latitude: null,
    longitude: null,
    specialism: [],
    propertyCategory: "Commercial",
    projectJobStatus: {
      id: "1",
      display: "Awaiting Quotes",
    },
    projectQuoteCount: "0",
    attachment: "1290/projects/1164/1721195882952_4T4TleUUeMpAg.jpeg",
    categories: [
      {
        tpLabel: "Drainage Specialist",
        iconName: "drainage",
      },
      {
        tpLabel: "Plumber",
        iconName: "plumbing",
      },
    ],
    userWorkstation: {
      subscription:
        '"{\\"customerId\\":\\"cus_QSd0D8AhUNXDdM\\",\\"subscriptionId\\":\\"sub_1Pbhl2HMbjzNI6kGtm7JjTUi\\",\\"metadata\\":{\\"businessType\\":\\"property_owner\\",\\"capacity\\":\\"100MB\\",\\"companyName\\":\\"NOOOO\\",\\"name\\":\\"NOOOO\\",\\"planType\\":\\"2\\",\\"productName\\":\\"tradesperson-profile\\",\\"totalMember\\":\\"1\\",\\"userId\\":\\"1290\\",\\"userWorkstationId\\":\\"1626\\",\\"workstationAccountType\\":\\"homeowner\\"},\\"created\\":1720784117}"',
    },
  },
  {
    id: 3,
    type: "routine",
    refId: "O0yQUOwOXxiXU8Q_e-bWV",
    name: "Test 24 May",
    postcode: "W12 9RE",
    description:
      "<p>On May 24th, a comprehensive test was conducted to evaluate the performance and reliability of the new software update.</p>",
    dateCreated: moment().subtract(3, "hour"),
    latitude: null,
    longitude: null,
    specialism: [],
    propertyCategory: "Residential",
    projectJobStatus: {
      id: "1",
      display: "Awaiting Quotes",
    },
    projectQuoteCount: "0",
    attachment: "temp/1716543690277_zy7jzfPgmIC4E.jpg",
    categories: [
      {
        tpLabel: "Drainage Specialist",
        iconName: "drainage",
      },
      {
        tpLabel: "Extensions Specialist",
        iconName: "extensions",
      },
      {
        tpLabel: "Plumber",
        iconName: "plumbing",
      },
    ],
    userWorkstation_subscription: null,
  },
  {
    id: 4,
    type: "standard",
    refId: "Jf_akiIek323Bwwczy_u8",
    name: "Solid floor insulation",
    postcode: "WV109YQ",
    description:
      "<p>Solid floor insulation involves installing insulating material beneath the surface of a solid floor, such as concrete or screed, to improve thermal efficiency and comfort.</p>",
    dateCreated: moment().subtract(2, "day"),
    latitude: "52.6037359",
    longitude: "-2.1115401",
    specialism: [],
    propertyCategory: "Residential",
    projectJobStatus: {
      id: "2",
      display: "Quotes Received",
    },
    projectQuoteCount: "1",
    attachment: null,
    categories: [
      {
        tpLabel: "Plumber",
        iconName: "plumbing",
      },
    ],
    userWorkstation_subscription: null,
  },
  {
    id: 1,
    type: "emergency",
    refId: "2bj0YQjI2WF7i8fraweOi",
    name: "Workshop Concrete Floor",
    postcode: "W12 9RE",
    description:
      "<p>A workshop concrete floor provides a durable and strong foundation ideal for various activities. It is designed to withstand heavy equipment, resist stains, and minimize dust, ensuring a clean and efficient workspace.</p>",
    dateCreated: moment().subtract(7, "seconds"),
    latitude: null,
    longitude: null,
    specialism: [],
    propertyCategory: "Residential",
    projectJobStatus: {
      id: "1",
      display: "Awaiting Quotes",
    },
    projectQuoteCount: "0",
    attachment: "1290/projects/1165/1721209959899_vU6hCnTF304PM.png",
    categories: [
      {
        tpLabel: "Drainage Specialist",
        iconName: "drainage",
      },
      {
        tpLabel: "Extensions Specialist",
        iconName: "extensions",
      },
      {
        tpLabel: "Plumber",
        iconName: "plumbing",
      },
    ],
    userWorkstation: {
      subscription:
        '"{\\"customerId\\":\\"cus_QSd0D8AhUNXDdM\\",\\"subscriptionId\\":\\"sub_1Pbhl2HMbjzNI6kGtm7JjTUi\\",\\"metadata\\":{\\"businessType\\":\\"property_owner\\",\\"capacity\\":\\"100MB\\",\\"companyName\\":\\"NOOOO\\",\\"name\\":\\"NOOOO\\",\\"planType\\":\\"2\\",\\"productName\\":\\"tradesperson-profile\\",\\"totalMember\\":\\"1\\",\\"userId\\":\\"1290\\",\\"userWorkstationId\\":\\"1626\\",\\"workstationAccountType\\":\\"homeowner\\"},\\"created\\":1720784117}"',
    },
  },
  {
    id: 2,
    type: "standard",
    refId: "6pdlUGHPrjre-1EQDGvUo",
    name: "Workshop Concrete Floor",
    postcode: "W12 9RE",
    description:
      "<p>It is designed to withstand heavy equipment, resist stains, and minimize dust, ensuring a clean and efficient workspace</p>",
    dateCreated: moment().subtract(2, "minutes"),
    latitude: null,
    longitude: null,
    specialism: [],
    propertyCategory: "Commercial",
    projectJobStatus: {
      id: "1",
      display: "Awaiting Quotes",
    },
    projectQuoteCount: "0",
    attachment: "1290/projects/1164/1721195882952_4T4TleUUeMpAg.jpeg",
    categories: [
      {
        tpLabel: "Drainage Specialist",
        iconName: "drainage",
      },
      {
        tpLabel: "Plumber",
        iconName: "plumbing",
      },
    ],
    userWorkstation: {
      subscription:
        '"{\\"customerId\\":\\"cus_QSd0D8AhUNXDdM\\",\\"subscriptionId\\":\\"sub_1Pbhl2HMbjzNI6kGtm7JjTUi\\",\\"metadata\\":{\\"businessType\\":\\"property_owner\\",\\"capacity\\":\\"100MB\\",\\"companyName\\":\\"NOOOO\\",\\"name\\":\\"NOOOO\\",\\"planType\\":\\"2\\",\\"productName\\":\\"tradesperson-profile\\",\\"totalMember\\":\\"1\\",\\"userId\\":\\"1290\\",\\"userWorkstationId\\":\\"1626\\",\\"workstationAccountType\\":\\"homeowner\\"},\\"created\\":1720784117}"',
    },
  },
  {
    id: 3,
    type: "routine",
    refId: "O0yQUOwOXxiXU8Q_e-bWV",
    name: "Test 24 May",
    postcode: "W12 9RE",
    description:
      "<p>On May 24th, a comprehensive test was conducted to evaluate the performance and reliability of the new software update.</p>",
    dateCreated: moment().subtract(3, "hour"),
    latitude: null,
    longitude: null,
    specialism: [],
    propertyCategory: "Residential",
    projectJobStatus: {
      id: "1",
      display: "Awaiting Quotes",
    },
    projectQuoteCount: "0",
    attachment: "temp/1716543690277_zy7jzfPgmIC4E.jpg",
    categories: [
      {
        tpLabel: "Drainage Specialist",
        iconName: "drainage",
      },
      {
        tpLabel: "Extensions Specialist",
        iconName: "extensions",
      },
      {
        tpLabel: "Plumber",
        iconName: "plumbing",
      },
    ],
    userWorkstation_subscription: null,
  },
  {
    id: 4,
    type: "standard",
    refId: "Jf_akiIek323Bwwczy_u8",
    name: "Solid floor insulation",
    postcode: "WV109YQ",
    description:
      "<p>Solid floor insulation involves installing insulating material beneath the surface of a solid floor, such as concrete or screed, to improve thermal efficiency and comfort.</p>",
    dateCreated: moment().subtract(2, "day"),
    latitude: "52.6037359",
    longitude: "-2.1115401",
    specialism: [],
    propertyCategory: "Residential",
    projectJobStatus: {
      id: "2",
      display: "Quotes Received",
    },
    projectQuoteCount: "1",
    attachment: null,
    categories: [
      {
        tpLabel: "Plumber",
        iconName: "plumbing",
      },
    ],
    userWorkstation_subscription: null,
  },
]);

const selectedCategory = ref([]);
const filters = reactive({
  search: "",
  postcode: "",
  radius: "",
  projectTypes: null,
  verifiedOwners: null,
  propertyCategory: null,
  specialism: [],
  // order: "ASC",
}) as any;
const setSelectedJob = async (value: string) => {
  store.commit(`${JOBS_STORE}/setJobDetails`, {});

  // store.dispatch(`${JOBS_STORE}/fetchJobDetails`, value);

  router.push({ name: JOB_DETAILS_ROUTE, params: { refId: value } });
  selectedJob.value = value;
};

const setDefaultSelectedJob = async (job: any) => {
  toggleRestrictionModal();
  // store.commit(`${JOBS_STORE}/setJobDetails`, job);
  // router.push({ name: JOB_DETAILS_ROUTE, params: { refId: job.refId } });
  // selectedJob.value = job;
};

const onClickToggleView = (value: string) => {
  toggledView.value = value;
};

// FetchJobs
const refetchJobs = debounce(async (state: JobFilters) => {
  loading.value = true;
  try {
    await store.dispatch(`${JOBS_STORE}/fetchJobs`, {
      ...state,
    });
  } finally {
    loading.value = false;
  }
}, 500);

watch(
  filters,
  (state: any) => {
    let params = JSON.parse(JSON.stringify({ ...state }));

    // Handle verifiedOwners logic
    if (params.verifiedOwners === "All (Project Owners)") {
      delete params.verifiedOwners;
    } else if (params.verifiedOwners === "Verified & Clients") {
      params.verifiedOwners = true;
    }

    if (!params.specialism?.length) {
      delete params.specialism;
    } else {
      params.specialism = params.specialism.toString();
    }

    params = Object.fromEntries(
      Object.entries(params).filter((value: any) =>
        value[1] === 0 ? true : value[1]
      )
    );

    refetchJobs(params);
  },
  { deep: true }
);

const openSortButton = () => {
  panel.value = [];
};

onMounted(async () => {
  loading.value = true;
  listOfCategories.value = await store.dispatch(`${USER_STORE}/getCategories`);
  let postData = JSON.parse(JSON.stringify({ ...filters }));
  if (!postData.specialism?.length) {
    delete postData["specialism"];
  } else {
    postData.specialism = postData.specialism.toString();
  }
  postData = Object.fromEntries(
    Object.entries(postData).filter((value: any) =>
      value[1] === 0 ? true : value[1]
    )
  );

  // if (user.value.postcode) {
  //   postData.postcode = user.value.postcode;
  //   filters.postcode = user.value.postcode;
  // }

  try {
    await store.dispatch(`${JOBS_STORE}/fetchJobs`, postData);
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
});

const toggleRestrictionModal = () => {
  isShowWorkstationRestrictionModal.value =
    !isShowWorkstationRestrictionModal.value;
};
</script>
<style lang="scss" scoped>
.custom-select {
  @include respond(md) {
    width: 100% !important;
  }
}

/* Sort Button */
.sort__wrapper {
  .v-btn {
    padding: 0;
    height: auto;
    :deep(.v-btn__content) {
      text-transform: capitalize;
      display: flex;
      gap: 10px;
      align-items: center;
      span {
        width: 24px;
        height: 24px;
        border-radius: 100%;
        background-color: rgba($blueDark, 0.05);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    :deep(.v-ripple__container) {
      display: none !important;
    }
  }
}
.v-menu {
  :deep(.v-overlay__content) {
    background-color: rgba($white, 1);
    min-width: 185px !important;
    border-radius: 16px;
    box-shadow: 0px 0px 8px 0px #0c0f4a14;
    padding: 16px;
    top: 222px !important;
    left: auto !important;
    right: 40px !important;
    @include respond(xlg) {
      right: 10px !important;
    }
    &::before {
      content: "";
      position: absolute;
      width: 30px;
      height: 20px;
      background-image: url(../../../assets/icons/triangle.svg);
      right: 10px;
      top: -10px;
    }
    .v-input {
      .v-input__control {
        .v-selection-control-group {
          gap: 10px;
          .v-selection-control {
            gap: 8px;
            .v-selection-control__wrapper {
              width: auto;
              height: auto;
              .v-selection-control__input {
                width: auto;
                height: auto;
              }
            }
            .v-label {
              @include fluidFont(14, 14, 1.3);
              font-weight: 600;
              color: rgba($blueDark, 1);
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
/* mobile Sort */
.mobile_sort_menu {
  :deep(.v-overlay__content) {
    right: auto !important;
    left: 140px !important;
    top: 180px !important;
    @include respond(s1024) {
      // right: 10px !important;
      // left: auto !important;
      // top: 400px !important;
      right: 20px !important;
      left: auto !important;
      top: 295px !important;
    }
    @include respond(sm) {
      left: auto !important;
      // right: 0 !important;
      // top: 450px !important;
      // right: 14px !important;
      // top: 277px !important;
      top: 231px !important;
      right: 5px !important;
    }
  }
}
/*Mobile Filters */
.mobile__filter {
  :deep(.v-expansion-panels) {
    .v-expansion-panel {
      background-color: transparent;
      border: 1px solid #c5d2f2;
      border-radius: 8px;
      .v-expansion-panel__shadow {
        box-shadow: none;
      }
      .v-expansion-panel-title {
        .v-expansion-panel-title__overlay {
          background-color: transparent;
        }
        img {
          width: 16px;
          margin-right: 8px;
        }
        h6 {
          @include fluidFont(12, 12, 1.2);
          font-weight: 700;
          letter-spacing: 0.4px;
          color: rgba($blueDark, 1);
        }
      }
      .v-expansion-panel-text {
        .v-expansion-panel-text__wrapper {
          padding: 8px 24px 16px;
          flex: 1 1 auto;
          max-width: 100%;
          .mobile__inputs {
            .v-input {
              width: 100%;
              max-width: 100%;
              min-width: unset;
              background-color: transparent;
              margin-bottom: 0;
              .v-input__control {
                .v-field {
                  padding: 0 10px;
                  border: 1px solid rgba($buttonText, 0.4);
                  border-radius: 8px;
                  background-color: transparent;
                  .v-field__prepend-inner {
                    padding: 0;
                    width: 16px;
                    height: 16px;
                    .v-icon {
                      opacity: 1;
                      @include fluidFont(16, 16, 1);
                    }
                    img {
                      width: 100%;
                      height: 100%;
                      object-fit: cover;
                      filter: invert(13%) sepia(20%) saturate(5279%)
                        hue-rotate(219deg) brightness(80%) contrast(112%);
                      vertical-align: middle;
                    }
                  }
                  .v-field__field {
                    display: block;
                    .v-field__input {
                      padding: 0;
                      background-color: transparent;
                      min-height: 40px;
                      padding-left: 8px;
                      @include fluidFont(12, 12, 1.2);
                      font-weight: 400;
                      letter-spacing: 0.4px;
                      max-width: 100%;
                      text-overflow: ellipsis;
                      overflow: hidden;
                      margin: 0;
                      top: 0;
                      input {
                        top: 50%;
                        transform: translateY(-50%);
                        &::placeholder {
                          color: rgba($blueDark, 1);
                          opacity: 1;
                        }
                      }
                    }
                  }
                  .c-input .v-field__outline {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.v-expansion-panel-title:hover > .v-expansion-panel-title__overlay {
  opacity: 0;
}

.v-input {
  width: 100%;
  min-width: unset;
  background-color: transparent;
  margin-bottom: 0;
  :deep(.v-input__control) {
    .v-field {
      padding: 0 10px;
      border: 1px solid rgba($buttonText, 0.4);
      border-radius: 8px;
      background-color: rgba($white, 1);
      align-items: center;
      .v-field__prepend-inner {
        padding: 0;
        width: 16px;
        height: 16px;
        .v-icon {
          opacity: 1;
          @include fluidFont(16, 16, 1);
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          filter: invert(13%) sepia(20%) saturate(5279%) hue-rotate(219deg)
            brightness(80%) contrast(112%);
          vertical-align: middle;
        }
      }
      .v-field__field {
        display: block;
        .v-field__input {
          padding: 0;
          background-color: transparent;
          min-height: 40px;
          padding-left: 8px;
          @include fluidFont(12, 12, 1.2);
          font-weight: 400;
          letter-spacing: 0.4px;
          max-width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
          margin: 0;
          top: 0;
          input {
            top: 50%;
            transform: translateY(-50%);
            background-color: transparent;
            &::placeholder {
              color: rgba($blueDark, 1);
              opacity: 1;
            }
          }
        }
      }
      .v-field__outline {
        display: none;
        &::before {
          border: none;
        }
      }
    }
  }
}
.v-field--variant-filled .v-field__outline::before,
.v-field--variant-underlined .v-field__outline::before {
  border: none;
}
.tab__buttons {
  padding: 0;
  opacity: 1;
  :deep(.v-btn__content) {
    @include fluidFont(12, 12, 1.2);
    font-weight: 600;
    text-transform: capitalize;
    color: rgba($blueDark, 0.4);
  }
  :deep(.v-btn__prepend) {
    color: rgba($blueDark, 0.4);
  }
}
.v-btn--active {
  :deep(.v-btn__content) {
    color: rgba($orange, 1);
  }
  :deep(.v-btn__prepend) {
    color: rgba($orange, 1);
  }
}
.description {
  text-align: left;
  margin: 0;
}

@media (max-width: 767px) {
  .working {
    flex-wrap: unset;
  }
}
</style>
