<template>
  <GeneralDialog
    class="tw-w-full tw-max-w-[860px] tw-min-w-[300px] rmd:!tw-min-w-[860px]"
    @on-close="onCloseModal"
    width="auto"
  >
    <template #body>
      <main
        v-if="!loading"
        class="tw-w-full tw-flex tw-flex-col tw-gap-6 tw-items-center tw-justify-center rmd:!tw-w-[600px]"
      >
        <div
          class="tw-w-full tw-flex tw-flex-col tw-gap-4 tw-items-center tw-justify-center tw-px-0 tw-box-border rmd:!tw-px-16 rmd:!tw-gap-6"
        >
          <Text
            class="!tw-text-[22px] rmd:!tw-text-[32px] rmd:!tw-leading-[34px]"
            textAlign="center"
            lineHeight="22px"
            >You already have {{ numberOfSpotsReserved }} spots reserved for
            {{ courseName }} in {{ formattedStartDates }}</Text
          >
          <Text
            variant="p"
            textWeight="400"
            textAlign="center"
            class="rmd:!tw-text-[16px] rmd:!tw-leading-[24px]"
            lineHeight="18px"
            textColor="rgba(12, 15, 74, 0.5)"
          >
            <strong>Please note:</strong> Verified workstation is required to be
            fully enrolled on the course
          </Text>
        </div>
      </main>
      <main
        class="tw-w-full tw-flex tw-flex-col tw-gap-6 tw-items-center tw-justify-center rmd:!tw-w-[600px]"
        v-else
      >
        <CommonLoader
          v-if="loading"
          :loading="loading"
          class="loader-container"
        />
      </main>
    </template>
    <template #footer>
      <div
        class="tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center tw-px-0 tw-gap-4 md:!tw-gap-[8px]"
      >
        <Button label="close" @click="onCloseModal" :loading="isUpgrading" />
      </div>
    </template>
  </GeneralDialog>
</template>
<script setup>
import moment from "moment";
import {
  computed,
  ref,
  watchEffect,
  onMounted,
  onUnmounted,
  onBeforeMount,
} from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
// import { useTimer } from "vue-timer-hook";

import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import { WORKSTATION } from "@/store/modules/workstation";
import { COURSES_STORE } from "@/store/modules/courses";
import {
  DASHBOARD_ROUTE,
  DASHBOARD_WITH_COURSE_ROUTE,
} from "@/modules/dashboard/routes";
import { USER_STORE } from "@/store/modules/user";
import CommonLoader from "@/core/components/CommonLoader.vue";
import ChipState from "@/core/components/common/ChipState.vue";
import CurrencyFilter from "@/filters/currency.filter";

const store = useStore();
const router = useRouter();
const route = useRoute();

const emits = defineEmits([
  "on-close",
  "on-cancel",
  "on-upgrade-success",
  "on-upgrade-fail",
]);

const courseId = ref(null);
const loading = ref(false);

const isUpgrading = ref(false);

const selectedSubscriptionType = ref("quarterly");

// const time = new Date();
// time.setSeconds(time.getSeconds() + 600); // 10 minutes timer
// const timer = useTimer(time);

const user = computed(() => store.getters[`${USER_STORE}/user`]);

const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);

const selectedDatesStore = computed(
  () => store.getters[`${COURSES_STORE}/selectedDates`]
);

const spotReservedDateStarted = computed(
  () => store.getters[`${COURSES_STORE}/spotReservedDateStarted`]
);

const courseUpgradeResponse = computed(
  () => store.getters[`${COURSES_STORE}/courseUpgradeResponse`]
);

const isDefault = computed(() => {
  return activeUserWorkstation.value.name === "Default";
});

const courseName = computed(() => {
  let courseReservation = null;
  if (route.query.courseReservation) {
    courseReservation = JSON.parse(
      String(route.query.courseReservation) || "{}"
    );
  } else {
    console.log("no route");
  }

  return courseReservation?.courseName;
});

const reservedSpotsData = computed(
  () => store.getters[`${COURSES_STORE}/reservedSpotsData`]
);

const storedReservedSpotsData = computed(
  () => store.getters[`${COURSES_STORE}/storedReservedSpotsData`]
);

const filteredReserveSpots = computed(() => {
  let courseReservation = null;
  if (route.query.courseReservation) {
    courseReservation = JSON.parse(
      String(route.query.courseReservation) || "{}"
    );
  } else {
    console.log("no route");
  }
  const courseId = courseReservation?.courseId;

  if (reservedSpotsData.value && reservedSpotsData.value.length) {
    // Filter spots by courseId and and no subscription
    return reservedSpotsData.value.filter((spot) => {
      return spot.course?.id == courseId && !spot.subscription;
    });
  }

  return [];
});

const spotsAmount = computed(() => {
  // Sum all spots in selectedDates
  const totalSpots = filteredReserveSpots.value.reduce((sum, item) => {
    return sum + item.selectedSpotCount;
  }, 0);

  // Multiply the sum by 29.97
  return totalSpots * 29.97;
});

const vatAmount = computed(() => {
  return spotsAmount.value * 0.2;
});

const numberOfSpotsReserved = computed(() => {
  // Sum all spots in selectedDates
  let totalSpots = 0;
  if (filteredReserveSpots.value && filteredReserveSpots.value.length) {
    totalSpots = filteredReserveSpots.value.reduce((sum, item) => {
      return sum + item.selectedSpotCount;
    }, 0);
  }

  return totalSpots;
});

const formattedStartDates = computed(() => {
  if (selectedDatesStore.value && selectedDatesStore.value.length) {
    return selectedDatesStore.value
      .map((item) => moment(item.startDate).format("DD MMMM YYYY")) // Format each startDate
      .join(", "); // Join all formatted dates with a comma and space
  }
  return filteredReserveSpots.value
    .map((item) =>
      moment(item.userCourseSchedules[0].courseSchedule.startDate).format(
        "DD MMMM YYYY"
      )
    ) // Format each startDate
    .join(", "); // Join all formatted dates with a comma and space
});

const onCloseModal = () => {
  emits("on-close");
};

onMounted(async () => {
  //
});
onUnmounted(() => {
  //
});
</script>
