<template>
  <div ref="map" class="tw-w-full tw-h-full" :class="className"></div>
</template>
<script setup>
import { computed, ref, reactive, onMounted, onUnmounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

import createGoogleMapsLoader from "@/core/utils/google-map-loader";
import { parseJSON } from "@/core/utils/common";
// import { Loader } from "@googlemaps/js-api-loader";

const emits = defineEmits(["onClickMarker"]);
const props = defineProps({
  properties: {
    type: Array,
    default: () => [],
  },
  className: {
    type: String,
  },
});
const store = useStore();
const route = useRoute();

const state = reactive({
  loading: false,
});

const map = ref(null);

let propertyLoader = createGoogleMapsLoader();
const initializeMap = async () => {
  if (!window.google || !window.google.maps) {
    console.error("Google Maps API is not loaded");
    return;
  }

  const bounds = new google.maps.LatLngBounds();

  // Create the map
  map.value = await new google.maps.Map(map.value, {
    center: { lat: 55.3781, lng: 3.436 }, // Default center
  });

  // Add markers
  props.properties.forEach((property, index) => {
    if (property.addressData) {
      const addressData = parseJSON(property.addressData);
      const position = new google.maps.LatLng(addressData.lat, addressData.lng);
      const svgIcon = `
        <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M19 36.5C28.665 36.5 36.5 28.665 36.5 19C36.5 9.33502 28.665 1.5 19 1.5C9.33502 1.5 1.5 9.33502 1.5 19C1.5 28.665 9.33502 36.5 19 36.5Z" fill="white" stroke="#FFA500" stroke-width="2"/>
          <text x="20" y="25" font-size="16" text-anchor="middle" fill="#FAA100" font-weight="bold" font-family="Mulish, sans-serif">${
            index + 1
          }</text>
  </svg>
      `;
      const marker = new google.maps.Marker({
        position,
        map: map.value,
        icon: {
          url: `data:image/svg+xml;utf-8,${encodeURIComponent(svgIcon)}`,
          scaledSize: new google.maps.Size(32, 32), // Adjust size as needed
        },
        customInfo: {
          lat: addressData.lat,
          lng: addressData.lng,
        },
      });
      marker.addListener("click", () => {
        const customInfo = marker.customInfo;
        emits("onClickMarker", customInfo);
      });
      bounds.extend(position);
    } else {
      // Normalize latitude and longitude keys
      const lat = property.lat || property.LAT;
      const lng = property.lng || property.LNG;

      if (lat && lng) {
        const position = new google.maps.LatLng(lat, lng);
        const svgIcon = `
        <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19 36.5C28.665 36.5 36.5 28.665 36.5 19C36.5 9.33502 28.665 1.5 19 1.5C9.33502 1.5 1.5 9.33502 1.5 19C1.5 28.665 9.33502 36.5 19 36.5Z" fill="white" stroke="#FFA500" stroke-width="2"/>
          <text x="20" y="25" font-size="16" text-anchor="middle" fill="#FAA100" font-weight="bold" font-family="Mulish, sans-serif">${
            index + 1
          }</text>
        </svg>
      `;
        const marker = new google.maps.Marker({
          position,
          map: map.value,
          icon: {
            url: `data:image/svg+xml;utf-8,${encodeURIComponent(svgIcon)}`,
            scaledSize: new google.maps.Size(32, 32), // Adjust size as needed
          },
          customInfo: {
            lat: lat,
            lng: lng,
          },
        });
        marker.addListener("click", () => {
          const customInfo = marker.customInfo;
          emits("onClickMarker", customInfo);
        });
        bounds.extend(position);
      } else {
        console.warn(
          `Property at index ${index} does not have valid lat/lng or LAT/LNG values.`
        );
      }
    }
  });

  // Adjust the map's zoom level to fit all markers
  if (map.value && props.properties && props.properties.length) {
    map.value.fitBounds(bounds, {
      top: 50, // Padding in pixels
      bottom: 50, // Padding in pixels
      left: 50, // Padding in pixels
      right: 50, // Padding in pixels
    });

    // Optional: Set a maximum zoom level if necessary
    const maxZoomLevel = 15; // Adjust as needed
    google.maps.event.addListenerOnce(map.value, "bounds_changed", () => {
      if (map.value.getZoom() > maxZoomLevel) {
        map.value.setZoom(maxZoomLevel);
      }
    });
  }
};
onMounted(async () => {
  try {
    await propertyLoader.load();
    // await fetchUserLocation();
    initializeMap();
  } catch (error) {
    console.error(
      "Failed to load Google Maps API or fetch user location",
      error
    );
    // Handle errors gracefully, e.g., show error message to the user
  }
});
// Clean up resources when the component is unmounted
onUnmounted(() => {
  if (map.value) {
    google.maps.event.clearInstanceListeners(map.value);
    map.value = null;
  }
  // Note: `loader` does not have a direct cleanup method, but setting `map.value` to `null` ensures no further interactions with the map
});
</script>
