import { CreateSafetyCheckInterface } from "../models/property";
import $axios from "../utils/axios-api-config";
import axios from "axios";

export default {
  fetchProperties(params = {}): Promise<any> {
    return $axios.get("/properties", { params });
  },

  fetchPropertyDetails(propertyId: string) {
    return $axios.get(`/properties/view/${propertyId}`);
  },

  fetchEPCDataList(propertyId: string, params = {}) {
    return $axios.get(`/properties/epc-data-list/${propertyId}`, { params });
  },

  fetchEPCDataListByCategoryId(propertyEpcDataId: string) {
    return $axios.get(`/properties/epc-data/${propertyEpcDataId}`);
  },

  createProperty(formData: any): Promise<any> {
    return $axios.post("/properties/add-properties", formData);
  },

  deleteProperty(propertyId: number) {
    return $axios.delete(`properties/${propertyId}`);
  },

  fetchAssetRegisters(propertyId: string, params = {}): Promise<any> {
    return $axios.get(`/properties/asset/${propertyId}`, { params });
  },

  createAssetRegister(formData: any): Promise<any> {
    return $axios.post("/properties/asset", formData);
  },

  fetchAssetRegisterDetails(assetId: string) {
    return $axios.get(`/properties/asset/view/${assetId}`);
  },

  updateAssetRegister(assetId: string, formData: any) {
    return $axios.put(`/properties/asset/${assetId}`, formData);
  },

  deleteAssetRegister(assetId: string) {
    return $axios.delete(`/properties/asset/${assetId}`);
  },

  fetchPropertyProjects(propertyId: string, params = {}): Promise<any> {
    return $axios.get(`/properties/projects/${propertyId}`, { params });
  },

  fetchPolygonCoordinates(
    latitude: string,
    longitude: string,
    width: string,
    height: string,
    response_version: string
  ) {
    // const params = `${process.env.VUE_APP_PROPERTY_DATA_BASE_URL}/get_building_insight/?location.latitude=${latitude}&location.longitude=${longitude}&width=${width}&height=${height}&response_version=${response_version} `;
    const params = `/properties/building-insight?latitude=${latitude}&longitude=${longitude}&width=${width}&height=${height}&response_version=${response_version}`;
    return $axios.get(params);
  },

  fetchPropertyPolygon(uprnId: string) {
    // const params = `https://geo.barrythebonsai.com/get_polygon_from_uprn/?uprn=${uprnId} `;
    const params = `/properties/polygon?uprn=${uprnId}`;
    return $axios.get(params);
  },
  fetchPropertyPolygonWithClassAndUprn(uprnId: string, classCode: string) {
    const params = `/properties/polygon?uprn=${uprnId}&class_of_title_choices=${classCode}`;
    return $axios.get(params);
  },
  fetchPropertyPolygonFromTitleNo(titleNo: string) {
    // const params = `https://geo.barrythebonsai.com/get_polygon_from_uprn/?uprn=${uprnId} `;
    const params = `/properties/title/polygon?title_no=${titleNo}`;
    return $axios.get(params);
  },
  fetchComparables(params = {}, uprn: any): Promise<any> {
    return $axios.post(`/properties/comparables/data/${uprn}`, params);
  },

  fetchPropertyPropbar(uprn: any): Promise<any> {
    return $axios.get(`/properties/history/${uprn}`);
  },

  createRicsReport(formData: any, propertyId: number): Promise<any> {
    return $axios.post(`/property/${propertyId}/report`, formData);
  },

  fetchRicsReports(params = {}, propertyId: any): Promise<any> {
    const { ...filters } = params;
    const urlParams = new URLSearchParams({ ...filters });
    const url = `${urlParams.toString()}`;
    return $axios.get(`/property/${propertyId}/report?${url}`, params);
  },

  createPropertyInsurance(formData: any, propertyId: number): Promise<any> {
    return $axios.post(`/property/${propertyId}/insurances`, formData);
  },

  fetchPropertyInsurances(propertyId: any): Promise<any> {
    return $axios.get(`/property/${propertyId}/insurances`);
  },
  updateEfficiencyRating(updatedData: any) {
    return $axios.put(`/properties/epc-data/update`, updatedData);
  },
  updatePropertyCategory(payload: any, propertyId: number) {
    return $axios.put(`/properties/${propertyId}`, payload);
  },
  createSafetyCheck(payload: {
    data: CreateSafetyCheckInterface;
    propertyId: number;
  }) {
    return $axios.post(
      `/property/${payload.propertyId}/safety-check`,
      payload.data
    );
  },
  updateSafetyCheck(payload: {
    data: CreateSafetyCheckInterface;
    propertyId: number;
    safetyCheckId: number;
  }) {
    return $axios.put(
      `/property/${payload.propertyId}/safety-check/${payload.safetyCheckId}`,
      payload.data
    );
  },
  getPropertySafetyCheckList(propertyId: number) {
    return $axios.get(`/property/${propertyId}/safety-check`);
  },
  getHeatPumpSubscription(payload: { userId: number; propertyId: number }) {
    return $axios.post(
      `payment/${payload.userId}/heat-pump-subscription/${payload.propertyId}`
    );
  },
  getDataLayerUrls(payload: {
    latitude: number;
    longitude: number;
    radius_meters: number;
  }) {
    return $axios.get(
      `properties/get-solar-data-layer?latitude=${payload.latitude}&longitude=${payload.longitude}&radius_meters=${payload.radius_meters}`
    );
  },
  getTIFFLayer(id: string) {
    return $axios.get(`properties/get-geo-tiff?id=${id}`, {
      responseType: "arraybuffer",
    });
  },
  getSpecialPolygon(payload: { uprn: string; polygonType: string }) {
    return $axios.get(
      `/properties/get-special-polygons?uprn=${payload.uprn}&polygonType=${payload.polygonType}`
    );
  },
};
