import $axios from "../utils/axios-api-config";

export default {
  getCourses() {
    return $axios.get(`/courses`);
  },
  checkElegibility(courseId: number) {
    return $axios.get(`/courses/${courseId}/check-eligibility`);
  },
  getCourseSchedule(params: any) {
    return $axios.get(`/course-schedules?search=${params.name}&limit=100`);
  },
  getCourseScheduleById(params: {
    courseScheduleId: any;
    currentFilters: {
      search: any;
      status: any;
    };
  }): Promise<any> {
    const { courseScheduleId, currentFilters } = params;
    const urlParams = new URLSearchParams(currentFilters);
    const data = $axios.get(
      `/course-schedules/${courseScheduleId}?${urlParams.toString()}`
    );
    return data;
  },
  getCourseSchedules(params: any) {
    return $axios.get("/course-schedules", { params });
  },
  createUserCourse(
    userId: number,
    courseId: number,
    forQueue: boolean,
    terms: boolean
  ) {
    return $axios.post(`/user/${userId}/courses`, {
      courseId,
      forQueue,
      terms,
    });
  },
  updateUserCourse(
    userId: number,
    courseId: number,
    forQueue: boolean,
    terms: boolean,
    enrollmentStatus: string
  ) {
    return $axios.put(`/user/${userId}/courses/${courseId}`, {
      forQueue,
      terms,
      enrollmentStatus,
    });
  },
  createUserCourseSchedules(courseScheduleId: number, courseUserId: number) {
    return $axios.post(`/user-course/${courseUserId}/schedules`, {
      courseScheduleId,
    });
  },

  getEnrollCourses(userId: number) {
    return $axios.get(`/user/${userId}/courses`);
  },

  checkCourseAlreadyEnrolled(userId: number, scheduleId: number) {
    return $axios.get(`/user/${userId}/courses/is-enrolled/${scheduleId}`);
  },

  reserveSpot(userId: number, courseReserveData: any) {
    // sample payload
    // courseReserveData: [
    //   {
    //     courseId: 1,
    //     courseScheduleId: 14,
    //     selectedSpotCount: 3,
    //   },
    // ];
    return $axios.post(`/user/${userId}/courses/reserve-course-spot`, {
      courseReserveData,
    });
  },

  fetchReservedSpots(userId: number) {
    return $axios.get(`/user/${userId}/courses`);
  },

  courseUpgradeSubscription(userId: number, payload: any) {
    return $axios.post(`/payment/${userId}/course-subscription`, payload);
  },
  getCoursesWithRemainingSlots(userId: number) {
    return $axios.get(`/user/${userId}/courses/slots-remaining`);
  },
  assignUserCourseToMember(userId: number, userCourseId: number, payload: any) {
    return $axios.put(
      `/user/${userId}/courses/ws-assign-spots/${userCourseId}`,
      payload
    );
  },
  getAssignedCourses(userId: number) {
    return $axios.get(`/user/${userId}/courses/assigned`);
  },
};
