<template>
  <div class="submit_estimate_modal">
    <div class="card">
      <div class="content">
        <h4>
          {{ step == 0 ? "Submitting Estimate...." : "Estimate Submitted" }}
        </h4>
        <div class="loader_icon">
          <img
            src="../../../../assets/icons/estimate-loader.svg"
            alt=""
            v-if="step == 0"
          />
          <img
            src="../../../../assets/icons/blue-check.svg"
            alt=""
            class="checked"
            v-if="step == 1"
          />
        </div>
        <v-stepper class="onboard-stepper" :modelValue="step">
          <v-stepper-header class="onboard-stepper__header">
            <v-stepper-item
              class="onboard-stepper__item"
              value="0"
            ></v-stepper-item>
            <v-stepper-item
              class="onboard-stepper__item"
              value="1"
            ></v-stepper-item>
          </v-stepper-header>
        </v-stepper>
        <div class="tw-mt-6">
          <Button
            variant="primary"
            label="Done"
            :disabled="step == 0"
            @click="onCancel"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import Button from "@/core/components/ui/general/Button.vue";
const props = defineProps({ step: { type: Number } });
const emit = defineEmits(["onCancel"]);
const onCancel = () => {
  emit("onCancel");
};
</script>

<style lang="scss" scoped>
.submit_estimate_modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url(http://localhost:8080/img/background-map.b821aa8f.png);
  z-index: 999;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;

  .card {
    width: 390px;
    padding: 24px;
    border-radius: 8px;
    background-color: #f5faff;
    h4 {
      @include fluidFont(18, 18, 1.3);
      font-weight: 400;
      letter-spacing: 0.4px;
      color: rgba($PrimaryBlue, 1);
    }
    .loader_icon {
      width: 100px;
      height: 100px;
      max-width: 100%;
      background-color: rgba($white, 1);
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 24px auto;
      img {
        width: 60px;
        height: 60px;
      }
    }
  }
}
</style>
