<template>
  <div
    v-if="isLoading"
    class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-h-full tw-w-full tw-mt-[530px]"
  >
    <Loader :show="isLoading" />
  </div>
  <div v-if="!isLoading">
    <DashBoardWelcome
      class="white-card"
      :isShowCloseButton="false"
      :button="dashBoardWelcomeButton"
    >
      <template v-slot:title><h3>Trades Services</h3></template>
      <template v-slot:text>
        <!-- <p>
        Lorem ipsum dolor sit amet consectetur. Sed odio tristique a libero.
        Habitant duis congue nunc etiam malesuada risus. Tellus rhoncus vel est
        arcu. Ornare nisi sed odio id. Eget in vestibulum lorem lectus justo
        enim.
      </p> -->
        <v-btn
          @click="onClickToggleSpecialismForm"
          v-if="getUserWorkStationFieldAccess"
          class="button button-orange"
          >add new trade Services</v-btn
        >
      </template>
      <template v-slot:image>
        <img
          src="@/assets/images/specialism-banner.png"
          class="!tw-w-[240px] !tw-h-[120px] rsm:!tw-w-[280px] rsm:!tw-h-[156px]"
      /></template>
    </DashBoardWelcome>

    <div class="trade__specialism__wrapper md:!tw-pb-[4rem] md:!tw-box-border">
      <div class="trade__header">
        <h4>Your Trade Services</h4>
      </div>
      <div class="trade__specialism-row">
        <v-card
          class="trade-specialism-card"
          v-for="specialism in userSpecialismList"
          :key="specialism?.id"
        >
          <div class="itemlist_card">
            <div
              class="itemlist_card__action"
              v-if="getUserWorkStationFieldAccess"
            >
              <v-icon
                v-if="getUserWorkStationFieldAccess"
                icon="mdi-pencil"
                color="#80829F"
                class="card__edit"
                @click="editSpecialism(specialism)"
              ></v-icon>
              <v-icon
                icon="mdi-delete"
                @click="onDeleteItem(specialism?.id)"
                color="#80829F"
                class="card__delete"
              ></v-icon>
            </div>

            <div class="title">
              <v-icon>
                <img
                  v-if="specialism?.category?.iconName"
                  :src="
                    require('@/assets/category-icons/' +
                      specialism?.category?.iconName +
                      '.svg')
                  "
                  alt="Image"
                />
              </v-icon>
              <div class="specialism__text">
                <h5>
                  {{ specialism?.label }}
                </h5>
                <p>
                  Added:
                  {{ new Date(specialism?.dateCreated).toLocaleDateString() }}
                </p>
              </div>
            </div>
            <div class="expertise__wrapper">
              <div class="expertise__text">
                <h5>Expertise</h5>
                <div class="expertise__tags">
                  <span
                    v-for="subCategorie in specialism?.subCategories?.slice(
                      0,
                      4
                    )"
                    :key="subCategorie?.id"
                  >
                    {{ subCategorie.label }}</span
                  >
                  <span
                    class="more_tags"
                    v-if="specialism?.subCategories?.length > 4"
                    >+{{ specialism?.subCategories?.length - 4 }}</span
                  >
                </div>
              </div>
            </div>
            <div class="members_wrapper">
              <div class="expertise__text">
                <h5>
                  Members <span>({{ specialism?.assignedUsers?.length }})</span>
                </h5>
                <div class="members__list">
                  <div
                    class="members__list__info"
                    v-for="assignedMember in specialism?.assignedUsers?.slice(
                      0,
                      4
                    )"
                    :key="assignedMember?.id"
                  >
                    <div class="members_img">
                      <img
                        v-if="assignedMember?.profileImageURL"
                        :src="assignedMember?.profileImageURL"
                        alt=""
                      />
                      <UserProfileLogo
                        v-else
                        :userName="`${assignedMember?.firstName} ${assignedMember?.lastName}`"
                      />
                    </div>
                    <h6>
                      {{ assignedMember?.firstName }}
                      {{ assignedMember?.lastName }}
                    </h6>
                  </div>

                  <div
                    class="members__list__more"
                    v-if="specialism?.assignedUsers?.length > 4"
                  >
                    +{{ specialism?.assignedUsers?.length - 4 }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-card>
      </div>
    </div>
  </div>
  <SpecialismEditModal
    v-if="isShowEditSpecialismModal"
    @onClickCancel="onClickCloseEditModal"
    :userSpecialismData="userSpecialismData"
  />
  <SpecialismMultiSelectModel
    v-if="isShowSpecialismModal"
    @onClickAddNewTradeSpecialism="onClickAddNewTradeSpecialism"
    @onClickCancel="closeSpecialismMultiSelectModel"
  />
  <AddNewTradeSpecialism
    v-if="isShowAddNewTradeSpecialism"
    @onClickCancelButton="closeAddNewTradeSpecialism"
  />
  <ConfirmDeleteModal
    v-if="isOpenConfirmDelete"
    label="You're about to delete your service."
    @on-close="onCloseConfirmDelete"
    @on-delete="onClickDeleteSpecialism"
    :loading="deleteLoader"
  ></ConfirmDeleteModal>
</template>

<script lang="ts">
import DashBoardWelcome from "@/core/components/DashBoardWelcome.vue";
import { computed, ComputedRef, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { USER_STORE } from "@/store/modules/user";
import ItemListCard from "@/core/components/ItemListCard.vue";
import SpecialismEditModal from "@/modules/Specialism/components/SpecialismEditModal.vue";
import { WORKSTATION } from "@/store/modules/workstation";

import SpecialismMultiSelectModel from "@/modules/Specialism/components/SpecialismMultiSelectModel.vue";
import AddNewTradeSpecialism from "@/modules/Specialism/components/AddNewTradeSpecialism.vue";
import UserProfileLogo from "@/core/components/UserProfileLogo.vue";
import { getImageStringToImageURL } from "@/core/utils/common";
import userService from "@/core/services/user.service";
import { UserRolesEnum } from "@/core/enums/RolesEnum";
import { useRoute } from "vue-router";
import { TRADE_ESTIMATE_STORE } from "@/store/modules/trade-estimate";
import ConfirmDeleteModal from "@/modules/dashboard/components/Modals/ConfirmDeleteModal.vue";
import Loader from "@/core/components/common/Loader.vue";

export default {
  components: {
    DashBoardWelcome,
    // ItemListCard,
    SpecialismMultiSelectModel,
    AddNewTradeSpecialism,
    // SpecialismModel,

    SpecialismEditModal,
    UserProfileLogo,
    ConfirmDeleteModal,
    Loader,
  },
  setup() {
    const dashBoardWelcomeButton = ref({
      text: "CREATE A Trades WORKSTATION",
      class: ["button", "button-purple-border"],
    }) as any;
    const store = useStore();
    const route = useRoute();
    const isShowSpecialismModal = ref(false);
    const isShowAddNewTradeSpecialism = ref(false);
    const isShowEditSpecialismModal = ref(false);
    const userSpecialismData = ref(null) as any;
    const userSpecialismList = ref([]) as any;
    const deleteLoader = ref(false);
    const user = computed(() => store.getters[`${USER_STORE}/user`]);
    const userSpecialisms = computed(
      () => store.getters[`${USER_STORE}/userSpecialisms`]
    );
    const getUserWorkStationFieldAccess = computed(
      () => store.getters[`${WORKSTATION}/getUserWorkStationFieldAccess`]
    );
    const isOpenConfirmDelete = ref(false);
    const isLoading = ref(false);
    const isUserProprtyOwner = computed(
      () => user.value?.role?.name === UserRolesEnum.PROPERTY_OWNER
    );
    const setMemberProfile = async () => {
      userSpecialismList.value = userSpecialisms.value;
      for (const specialism of userSpecialismList.value) {
        specialism["label"] = specialism?.category.poLabel
          ? specialism?.category.tpLabel
          : "";
        for (const user of specialism.assignedUsers) {
          user.profileImageURL = await getImageStringToImageURL(
            user?.userPublicProfile?.profileImage
          );
        }
      }
    };

    const onClickDeleteSpecialism = async () => {
      try {
        deleteLoader.value = true;
        const res = await userService.deleteSkill(
          user.value.id,
          confirmDeleteService.value as number
        );
        await store.dispatch(`${USER_STORE}/getUserSkills`, user.value.id);
        setMemberProfile();
      } catch (error) {
        console.log("delete specialism error", error);
      } finally {
        deleteLoader.value = false;
        onCloseConfirmDelete();
      }
    };

    const onClickToggleSpecialismForm = async () => {
      isShowSpecialismModal.value = !isShowSpecialismModal.value;
    };

    const formatDate = (dateString: string) => {
      const date = new Date(dateString);

      const day = date.getUTCDate();
      const month = date.getUTCMonth() + 1;
      const year = date.getUTCFullYear();
      const formattedDay = day < 10 ? "0" + day : day;
      const formattedMonth = month < 10 ? "0" + month : month;
      return `${formattedDay}/${formattedMonth}/${year}`;
    };

    const onClickAddNewTradeSpecialism = async () => {
      isShowSpecialismModal.value = false;
      isShowAddNewTradeSpecialism.value = true;
    };

    const closeSpecialismMultiSelectModel = () => {
      isShowSpecialismModal.value = false;
      setMemberProfile();
    };

    const closeAddNewTradeSpecialism = () => {
      isShowSpecialismModal.value = true;
      isShowAddNewTradeSpecialism.value = false;
    };

    const editSpecialism = (specialism: any) => {
      userSpecialismData.value = specialism;
      isShowEditSpecialismModal.value = true;
    };
    const onClickCloseEditModal = () => {
      isShowEditSpecialismModal.value = false;
      setMemberProfile();
    };
    const contractorTradePassportWorkstationId = computed(
      () =>
        store.getters[
          `${TRADE_ESTIMATE_STORE}/getContractorTradePassportWorkstationId`
        ]
    );

    const confirmDeleteService = ref<null | number>(null);
    const onDeleteItem = (specialismId: number) => {
      confirmDeleteService.value = specialismId;
      isOpenConfirmDelete.value = true;
    };

    const onCloseConfirmDelete = () => {
      confirmDeleteService.value = null;
      isOpenConfirmDelete.value = false;
    };
    onMounted(async () => {
      try {
        isLoading.value = true;
        if (isUserProprtyOwner.value) {
          await store.dispatch(`${USER_STORE}/getContractorSkills`, {
            userId: user.value.id,
            workStationId: contractorTradePassportWorkstationId.value,
          });
        } else {
          await store.dispatch(`${USER_STORE}/getUserSkills`, user.value.id);
        }
        setMemberProfile();
      } catch (error) {
        console.log("error while loading trade services", error);
      } finally {
        isLoading.value = false;
      }
    });
    return {
      dashBoardWelcomeButton,
      onClickToggleSpecialismForm,
      isShowSpecialismModal,
      formatDate,
      onClickAddNewTradeSpecialism,
      isShowAddNewTradeSpecialism,
      closeSpecialismMultiSelectModel,
      userSpecialisms,
      closeAddNewTradeSpecialism,
      onClickDeleteSpecialism,
      isShowEditSpecialismModal,
      onClickCloseEditModal,
      editSpecialism,
      userSpecialismData,
      getUserWorkStationFieldAccess,
      userSpecialismList,
      isOpenConfirmDelete,
      onDeleteItem,
      onCloseConfirmDelete,
      deleteLoader,
      isLoading,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/modules/trade-passport/styles/TradePassport.scss";
@import "@/modules/onboarding/styles/onboarding.scss";
</style>
