<template>
  <div v-if="!isShowHistoricalProjectFlow">
    <div
      class="project__property__list__header"
      v-if="currentSelectedTab?.id <= 2"
    >
      <div class="project__property__list__header__left">
        <v-btn
          variant="text"
          aria-label="Refresh"
          class="button-transparent back_btn"
          prepend-icon="mdi-chevron-left"
          @click="onClickBackButton"
          >{{
            backButtonLabelList[currentSelectedTab?.id - 1]?.label || "back"
          }}</v-btn
        >
      </div>
      <!-- <div
      class="project__property__list__header__right"
      v-if="!PropertyListLengthZero"
    >
      <v-btn
        color="white"
        aria-label="Refresh"
        class="button"
        prepend-icon="mdi-content-save"
        >Save</v-btn
      >
      <v-btn
        color="white"
        aria-label="Refresh"
        class="button"
        prepend-icon="mdi-open-in-app"
        >Load</v-btn
      >
    </div> -->
    </div>
    <div
      :class="
        className || PropertyListLengthZero
          ? 'project__property__list-empty'
          : ''
      "
      class="project__property__list"
      v-if="!isShowProjectType"
    >
      <div class="project__property__content">
        <div class="property_badge">
          <div class="notification" v-if="!PropertyListLengthZero">
            {{ currentSelectedTab.id }}
          </div>
          <div class="property_badge_text" v-if="!PropertyListLengthZero">
            {{ currentSelectedTab.name }}
          </div>
        </div>
        <div class="property_text md:!tw-mt-[2px]">
          <h4 class="md:!tw-text-[18px]">
            {{ PropertyListLengthZero ? "" : currentSelectedTab.title }}
          </h4>
        </div>
        <div class="card__content">
          <div
            v-if="currentSelectedTab.id == 1 && !isFetchingPropertiesLoading"
            class="card__content__text"
          >
            <div v-if="!PropertyListLengthZero">
              <div class="property-listing-container">
                <div
                  v-if="isFetchingPropertiesLoading"
                  class="loader-container"
                >
                  <CommonLoader :loading="isFetchingPropertiesLoading" />
                </div>
                <template v-else>
                  <div class="topproperty_listing">
                    <div class="toplist_details">
                      <div>{{ propertiesList.length }} Properties</div>
                      |
                      <div>{{ selectedPropertyIds.length }} Selected</div>
                    </div>
                    <div class="topright_details">
                      <div class="two_icons">
                        <div class="right_btn" :class="{ active: !isMapView }">
                          <v-btn
                            prepend-icon="mdi-view-grid"
                            variant="text"
                            @click="isMapView = false"
                          >
                            Tile
                          </v-btn>
                        </div>
                        <div class="right_btn" :class="{ active: isMapView }">
                          <v-btn
                            prepend-icon="mdi-map"
                            variant="text"
                            @click="isMapView = !isMapView"
                          >
                            Map
                          </v-btn>
                        </div>
                      </div>
                      <!-- <div class="sort__wrapper">
                      <div class="sortby_btn">Sort by</div>
                      <v-menu>
                        <template v-slot:activator="{ props }">
                          <v-btn v-bind="props" class="sort__btn">
                            <img src="../../../assets/icons/sort.svg" alt="" />
                          </v-btn>
                        </template>

                        <v-list class="dropdown__sort">
                          <v-list-item
                            v-for="item in filterOptions"
                            :key="item.value"
                          >
                            <v-list-item-title
                              ><v-icon :icon="item.icon" width="12" />
                              {{ item.label }}</v-list-item-title
                            >
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </div> -->
                    </div>
                  </div>
                  <div class="property__location" :class="{ show: isMapView }">
                    <div class="left__block">
                      <div class="courses__card__list">
                        <v-card
                          class="courses__card"
                          :class="{ 'selected-card': isSelected(property?.id) }"
                          @click="toggleSelection(property?.id)"
                          v-for="(property, index) in propertiesList"
                          :key="index"
                        >
                          <!-- <div class="courses__card_top_title">
                          <v-icon icon="mdi-home"></v-icon>
                          <div
                            class="course__property_name"
                            v-if="
                              isEditPropertyName.propertyId !== property?.id
                            "
                          >
                            <h4>
                              {{ property.nickname || "NA" }}
                            </h4>
                            <v-icon
                              icon="mdi-pencil"
                              @click.stop="
                                updateSelectedPropertyName(property?.id)
                              "
                            ></v-icon>
                          </div>
                          <div>
                            <div
                              class="course__edit_property"
                              v-if="
                                isEditPropertyName.isEdit &&
                                isEditPropertyName.propertyId &&
                                isEditPropertyName.propertyId === property?.id
                              "
                            >
                              <v-text-field
                                density="compact"
                                bg-color="rgba(12, 15, 74, 0.0509803922)"
                                class="input_field"
                                @click.stop
                                variant="outlined"
                                v-model="isEditPropertyName.value"
                              ></v-text-field>

                              <v-icon
                                icon="mdi-check"
                                :disabled="
                                  isEditPropertyName.value.length === 0
                                "
                                @click.stop="updatePropertyName()"
                              ></v-icon>
                              <v-icon
                                icon="mdi-close"
                                @click.stop="closeSelectedPropertyName()"
                              ></v-icon>
                            </div>
                          </div>
                        </div> -->
                          <div class="courses__card__img">
                            <LeafletMap
                              :data="[]"
                              :isDraggable="false"
                              :showControls="false"
                              :defaultViewZoom="17"
                              :latlang="getLatLng(property?.addressData)"
                            />
                          </div>
                          <div class="courses__card__prefix">
                            <div class="left__prefix">
                              <v-icon icon="mdi-account"></v-icon>
                              <p>{{ getPropertyType(property) }}</p>
                            </div>
                            <div class="right__prefix">
                              <v-icon icon="mdi-map-marker"></v-icon>
                              <p>{{ property?.postcode }}</p>
                            </div>
                          </div>
                          <div class="courses__card__text">
                            <h5 class="courses__card__title">
                              {{ property?.address }}
                            </h5>
                          </div>
                        </v-card>
                      </div>
                    </div>

                    <div
                      v-if="addresses && addresses.length"
                      class="map__container tw-rounded-xl tw-overflow-hidden"
                    >
                      <PropertyMapModal :properties="parsedPropertyList" />
                    </div>
                  </div>

                  <!-- <div class="load__wrapper">
                  <label
                    v-if="isPaginationAvailable"
                    @click="onLoadMore"
                    class="yellow-text"
                  >
                    Load More Properties<v-icon icon="mdi-chevron-right"
                  /></label>
                  <v-btn
                    v-if="isPaginationAvailable"
                    @click="onLoadMore"
                    variant="text"
                    aria-label="Refresh"
                    class="back_btn yellow-text"
                    append-icon="mdi-chevron-right"
                    :disabled="isFetchingPropertiesLoading"
                    :loading="isFetchingPropertiesLoading"
                    >Load More Properties</v-btn
                  >
                </div> -->
                </template>
              </div>
              <!-- Selected Cards and Submit Button -->
              <!-- <div class="selected-info">
          <v-btn
            aria-label="Refresh"
            class="button button-purple-border"
            @click="onClickAddProperty"
            >ADD NEW PROPERTY</v-btn
          >

          <v-btn
            aria-label="Refresh"
            class="button button-purple"
            @click="submitSelectedCards"
            :disabled="isSubmitButtonDisabled"
            >continue</v-btn
          >
        </div> -->
            </div>

            <div v-else class="empty__content">
              <div class="empty__banner">
                <div class="empty__banner__img">
                  <img
                    src="../../../assets/images/property-banner.png"
                    alt=""
                  />
                </div>
                <div class="empty__banner__content">
                  <h4>You haven’t added any properties yet.</h4>
                  <p>Add a property(s) to get started.</p>
                </div>
              </div>
            </div>
          </div>
          <!-- <CommonLoader v-else :loading="isFetchingPropertiesLoading" /> -->
          <Loader :show="isFetchingPropertiesLoading" />
          <div v-if="currentSelectedTab.id == 2">
            <div class="project-steps-content">
              <div class="project-service-list">
                <v-card
                  class="project-service-item"
                  v-for="item in projectTypeList"
                  :key="item.id"
                  :class="{ selected: item.selected }"
                  @click="toggleSelectionType(item)"
                >
                  <div class="project-service-item__logo">
                    <v-icon v-if="item.iconName" class="list_content_btn">
                      <img
                        v-if="item.iconName"
                        :src="
                          require('@/assets/icons/' + item.iconName + '.svg')
                        "
                        alt="icon"
                      />
                    </v-icon>
                  </div>
                  <div class="project-service-item__text">
                    <h5>{{ item.name }}</h5>
                    <p>
                      {{ item.subTitle }}
                    </p>
                  </div>
                </v-card>
              </div>
              <!-- Recommended & Retrofit Projects -->

              <!-- <div class="all__courses third__step">
          <div class="courses__title">
            <h5>Recommended & Retrofit Projects</h5>
            <p>Select the project(s) you wish to start</p>
          </div>
          <div class="courses__card__list">
            <v-card
              class="courses__card"
              v-for="items in recommendedProjects"
              :key="items?.id"
            >
              <div class="courses__card__prefix">
                <label class="left__prefix"
                  ><v-icon icon="mdi-home"></v-icon>{{ items?.score }}</label
                >
                <label class="right__prefix"
                  ><v-icon
                    ><img
                      width="15px"
                      height="15px"
                      src="../../../assets/icons/Vector.svg"
                      alt="icon" /></v-icon
                  >{{ items?.priority }}</label
                >
              </div>
              <div class="courses__card__prefix">
                <label class="left__prefix__trade"
                  ><v-icon icon="mdi-account-tie"></v-icon
                  >{{ items?.trade }}</label
                >
              </div>
              <p>{{ items?.address }}</p>
              <h5 class="courses__card__title">{{ items?.name }}</h5>
            </v-card>
          </div>
        </div> -->
            </div>
          </div>
        </div>
      </div>
      <div :class="className + '_footer'" class="footer">
        <div
          v-if="!(currentSelectedTab.id == 1 && !isFetchingPropertiesLoading)"
          class="project__button"
        >
          <v-btn
            aria-label="Refresh"
            class="button button-purple w-100"
            @click="onClickToggleContinue"
            :disabled="!isProjectTypeSelected"
            >continue</v-btn
          >
        </div>
        <div
          class="footer__btns"
          v-if="currentSelectedTab.id == 1 && !isFetchingPropertiesLoading"
        >
          <v-btn
            v-if="workflowType === PropertyWorkflowTypeEnum.PROPERTY"
            aria-label="Refresh"
            class="button button-purple-border"
            @click="onClickAddProperty"
            >ADD NEW PROPERTY</v-btn
          >

          <v-btn
            aria-label="Refresh"
            class="button button-purple"
            @click="submitSelectedCards"
            :disabled="isSubmitButtonDisabled"
            >continue</v-btn
          >
        </div>
        <v-stepper
          class="onboard-stepper md:!tw-pb-4"
          v-if="
          !PropertyListLengthZero &&
          [
           PropertyWorkflowTypeEnum.PROPERTY,
           PropertyWorkflowTypeEnum.RAISE_CLIENT,
         ].includes(workflowType as PropertyWorkflowTypeEnum) 
        "
          v-model="currentSelectedTab.id"
        >
          <v-stepper-header class="onboard-stepper__header">
            <v-stepper-item
              class="onboard-stepper__item"
              v-for="step in getStepperList"
              :key="step.id"
              :title="step.tab"
              :value="step.id"
            ></v-stepper-item>
          </v-stepper-header>
        </v-stepper>
      </div>
    </div>
    <ProjectTypeComponent
      v-else
      @onClickBackButtonType="onClickBackButtonType"
      @onClickeditProjectPropertyList="onClickeditProjectPropertyList"
      @onClickSaveLater="onClickSaveLater"
      :projectRefData="projectRefData"
      :isEditMode="isEditMode"
      :workflowType="workflowType"
      :selectedClient="selectedClient"
    />
  </div>
  <AddHistoricalProject
    v-if="isShowHistoricalProjectFlow"
    @on-back="onHistoricalProjectBack"
    @onHistoricProjectSaved="onHistoricalProjectSave"
    :property="userSelectedProperties[0]"
    :isHistoricalDraftProjectFlow="isHistoricalDraftProjectFlow"
  />
</template>

<script lang="ts">
import { LatLngExpression } from "leaflet";
import { ref, computed, onMounted, getCurrentInstance } from "vue";
import { PROJECT } from "@/store/modules/project";
import { useStore } from "vuex";
import { PROJECT_STORE } from "@/store/modules/project";
import CommonLoader from "@/core/components/CommonLoader.vue";
import Loader from "@/core/components/common/Loader.vue";
import AddHistoricalProject from "@/modules/properties/components/AddHistoricalProject.vue";
import LeafletMap from "@/core/components/map/LeafletMap.vue";
import { useRouter, useRoute } from "vue-router";
import ProjectTypeComponent from "@/modules/project/components/ProjectTypeComponent.vue";
import {
  getProperties,
  updateProperty,
} from "@/core/services/properties.service";
// import AddressMapComponent from "@/core/components/AddressMapComponent.vue";
import PropertyMapModal from "@/modules/properties/components/Modals/PropertyMapModal.vue";

import {
  PropertyConnectionRoleEnum,
  PropertyTypeEnum,
  PropertyWorkflowTypeEnum,
} from "@/core/enums/PropertiesEnum";
import { displayToastMessage, parseJSON } from "@/core/utils/common";
import { PROPERTY_STORE } from "@/store/modules/property";
import { ProjectTypesEnum } from "@/core/enums/ProjectsEnum";
import { JOBS_STORE } from "@/store/modules/jobs";
import { ADD_PROPERTY_ROUTE } from "@/modules/properties/routes";
import { PROPERTY_TYPE } from "@/modules/properties/constants";

export default {
  components: {
    ProjectTypeComponent,
    // AddressMapComponent,
    PropertyMapModal,
    CommonLoader,
    LeafletMap,
    Loader,
    AddHistoricalProject,
  },
  emits: ["onGoAddHistoricalPage", "onClickBackButton"],
  props: {
    workflowType: {
      type: String,
      default: PropertyWorkflowTypeEnum.PROPERTY,
    },
    selectedClient: {
      type: Object,
      default: null,
    },
    className: {
      type: String,
      default: "",
    },
    isHistoricalDraftProjectFlow: {
      type: Boolean,
      default: false,
    },
  },
  setup(props: any, ctx: any) {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const selectedProjectTypeName: any = ref();
    const isShowProjectType = ref(false);
    const isEditMode = ref(false);
    const isShowHistoricalProjectFlow = ref(false);
    const backButtonLabelList = [
      { currentTabId: 1, label: "Back To All Projects" },
      { currentTabId: 2, label: "Back To Properties" },
      { currentTabId: 3, label: "Back To Project Type" },
      { currentTabId: 4, label: "Back to  Timelines" },
    ];
    const filterOptions = ref([
      {
        label: "Date Added",
        value: "dateCreated",
        icon: "mdi-calendar-range",
      },
    ]);
    const modelDetails = ref([
      {
        id: 1,
        tab: "Property",
        name: "Property",
        title: "Please select the property / properties this project is for",
      },
      {
        id: 2,
        tab: "Project Type",
        name: "Project Type",
        title: "Please select the type, best suited for your project.",
      },
      {
        id: 3,
        tab: "Timelines",
        name: "Timelines",
        title: "Let us know the timelines you’re looking for",
      },
      {
        id: 4,
        tab: "Project Specifics",
        name: "Project Specifics",
        title: "Please try to explain your project in more detail.",
      },
    ]);

    const getStepperList = computed(() =>
      [
        ProjectTypesEnum.ROUTINE,
        ProjectTypesEnum.STANDARD,
        ProjectTypesEnum.VIDEO_CONSULTATION,
      ].includes(
        selectedProjectTypeName.value
          ? selectedProjectTypeName.value.toLowerCase()
          : ""
      )
        ? modelDetails.value
        : modelDetails.value.filter((item: any) => item.id !== 3)
    );
    const currentSelectedTab = ref(modelDetails.value[0]) as any;

    const propertiesList = ref([]) as any;

    const projectTypeList = ref([
      {
        id: 1,
        name: "Emergency",
        iconName: "Vector",
        subTitle: "This project needs to start now",
      },
      {
        id: 2,
        name: "Standard",
        iconName: "model_training_black",
        subTitle: "This project has a flexible or preferred start date ",
      },
      {
        id: 3,
        name: "Routine",
        iconName: "clock_circle",
        subTitle: "This project occurs regularly",
      },
      {
        id: 4,
        name: "Video Consultation",
        iconName: "video-consult-icon",
        subTitle: "Consult with expert trades and ask advice on the project",
      },
      {
        id: 5,
        name: "Record Historic Project",
        iconName: "historic_project_icon",
        subTitle: "Project you or a trade contractor has completed in the past",
      },
    ]) as any;

    const recommendedProjects = ref([
      {
        id: 1,
        score: "+21 KYP Score",
        priority: "High Priority",
        trade: "Trade Recommended",
        address: "33 Collier Close, KT19",
        name: "New EPC Assessment",
      },
      {
        id: 2,
        score: "+21 KYP Score",
        priority: "High Priority",
        trade: "Trade Recommended",
        address: "33 Collier Close, KT19",
        name: "New EPC Assessment",
      },
      {
        id: 3,
        score: "+21 KYP Score",
        priority: "High Priority",
        trade: "Trade Recommended",
        address: "33 Collier Close, KT19",
        name: "New EPC Assessment",
      },
      {
        id: 4,
        score: "+21 KYP Score",
        priority: "High Priority",
        trade: "Trade Recommended",
        address: "33 Collier Close, KT19",
        name: "New EPC Assessment",
      },
    ]);
    const isFetchingPropertiesLoading = ref(false);
    const paginationData = ref({
      currentPage: 0,
      totalPages: 0,
      totalPropertiesCount: 0,
    });
    const addresses: any = ref([]);
    const isMapView = ref(false);
    const isEditPropertyName = ref({
      isEdit: false,
      propertyId: null,
      value: "",
    }) as any;

    const isProjectTypeSelected = computed(() => {
      return projectTypeList.value.some((item: any) => item.selected);
    });

    const getLatLng = (addressData: string): LatLngExpression | undefined => {
      if (!addressData) return undefined;

      try {
        const unParsedData = parseJSON(addressData);
        const latlng: LatLngExpression = [
          unParsedData.LAT ? unParsedData.LAT : unParsedData.lat,
          unParsedData.LNG ? unParsedData.LNG : unParsedData.lng,
        ];
        return latlng;
      } catch (error) {
        console.error("Invalid address data:", error);
        return undefined;
      }
    };
    const internalInstance = getCurrentInstance();

    const toggleSelectionType = (selectedType: any) => {
      if (selectedType.id === 5 && selectedPropertyIds.value.length > 1) {
        return displayToastMessage(
          internalInstance,
          `For Record Historic Project you need to selected only one property.`,
          "info"
        );
      }
      projectTypeList.value.forEach((item: any, i: number) => {
        if (item.id === selectedType.id) {
          item.selected = true;
          selectedProjectTypeName.value = item.name;
        } else {
          item.selected = false;
        }
      });
    };

    const userSelectedProperties = computed(
      () => store.getters[`${PROJECT_STORE}/selectedProperties`]
    );

    const onClickToggleContinue = () => {
      //empty projectDetails Store
      store.dispatch(`${PROJECT_STORE}/resetProjectDetails`);

      store.dispatch(`${PROJECT}/projectDetails`, {
        projectType: selectedProjectTypeName.value.toLowerCase(),
      });
      currentSelectedTab.value = null;
      if (
        selectedProjectTypeName.value.toLowerCase() ===
        ProjectTypesEnum.RECORD_HISTORIC_PROJECT
      ) {
        store.dispatch(`${PROPERTY_STORE}/setPropertyDetails`, {
          localData: userSelectedProperties.value[0],
        });
        isShowHistoricalProjectFlow.value = true;
      } else {
        isShowProjectType.value = true;
      }
    };

    const parsedPropertyList = computed(() => {
      let list = [] as any;
      if (propertiesList.value.length && propertiesList.value) {
        list = propertiesList.value.map((property: any) => {
          const addressData = parseJSON(property.addressData);
          return {
            ...addressData,
          };
        });

        return list;
      }
      return list;
    });

    const PropertyListLengthZero = computed(
      () => propertiesList.value.length === 0
    );

    const selectedPropertyIds = ref([]) as any;

    const isSelected = (index: number) => {
      return selectedPropertyIds.value.includes(index);
    };

    const toggleSelection = (index: number) => {
      closeSelectedPropertyName();
      if (
        [
          PropertyWorkflowTypeEnum.RAISE_AN_ISSUE,
          PropertyWorkflowTypeEnum.CLIENT,
        ].includes(props.workflowType) ||
        props.isHistoricalDraftProjectFlow
      ) {
        selectedPropertyIds.value = [];
      }
      const selectedIndex = selectedPropertyIds.value.indexOf(index);
      if (selectedIndex === -1) {
        selectedPropertyIds.value.push(index);
      } else {
        selectedPropertyIds.value.splice(selectedIndex, 1);
      }
    };

    const submitSelectedCards = () => {
      const selectedProperties = propertiesList.value?.filter((item: any) =>
        selectedPropertyIds.value.includes(item.id)
      );
      if (
        [
          PropertyWorkflowTypeEnum.RAISE_AN_ISSUE,
          PropertyWorkflowTypeEnum.CLIENT,
        ].includes(props.workflowType)
      ) {
        return ctx.emit("onGoAddHistoricalPage", selectedProperties[0]);
      }
      if (props.isHistoricalDraftProjectFlow) {
        currentSelectedTab.value = modelDetails.value[1];
      } else {
        currentSelectedTab.value = modelDetails.value[1];
      }
      store.commit(`${PROJECT}/setSelectedPropertiesList`, selectedProperties);
      if (props.isHistoricalDraftProjectFlow) {
        toggleSelectionType(projectTypeList.value[4]);
        onClickToggleContinue();
      }
    };

    const onClickBackButton = () => {
      if (currentSelectedTab.value.id === 1) {
        if (route.query && route.query.propertyId) {
          router.push({ path: "/project" });
        }
        ctx.emit("onClickBackButton");
      } else {
        currentSelectedTab.value = modelDetails.value[0];
      }
    };

    const onClickBackButtonType = () => {
      isShowProjectType.value = false;
      currentSelectedTab.value = modelDetails.value[1];
    };

    const getPropertiesListing = async (page: number, limit: number) => {
      try {
        const response = await getProperties({
          page: page,
          limit: limit,
          search: "",
          userWorkstationId:
            props.selectedClient && props.selectedClient?.userWorkstation?.id,
        });

        // remove view role connection property
        if (props.workflowType === PropertyWorkflowTypeEnum.RAISE_AN_ISSUE) {
          response.data = response.data.filter((property: any) => {
            const connectionTag = parseJSON(property.connectionTags);

            return (
              property?.propertyRole === PropertyTypeEnum.OCCUPIER ||
              (connectionTag &&
                connectionTag.includes(PropertyTypeEnum.OCCUPIER))
            );
          });
        } else {
          response.data = response.data.filter(
            (property: any) =>
              property?.connectionRole !== PropertyConnectionRoleEnum.VIEWER
          );
        }

        if (response) {
          paginationData.value.currentPage = response.page;
          paginationData.value.totalPages = response.totalPages;
          paginationData.value.totalPropertiesCount =
            response?.data?.length || response.totalCount;
          isFetchingPropertiesLoading.value = false;
        }

        if (response?.data?.length) {
          return response.data;
        } else {
          return [];
        }
      } catch (error) {
        console.log(error);
        isFetchingPropertiesLoading.value = false;
      }
    };

    const normalizeData = () => {
      const addressList: any = [];
      propertiesList?.value?.length &&
        propertiesList?.value.map((item: any) => {
          let latlang = null;

          if (item.addressData) {
            const address = parseJSON(item.addressData);

            latlang = getLatLng(address);

            addressList.push({
              address: item.address,
              uprn: item.uprn,
              latlang,
              id: item.id,
              postcode: item.postcode,
            });
          }
        });
      addresses.value = addressList;
    };

    const isPaginationAvailable = computed(() => {
      const { totalPropertiesCount } = paginationData.value;
      return totalPropertiesCount !== propertiesList.value.length;
    });
    const getNewPropertyProjectData = computed(
      () => store.getters[`${PROPERTY_STORE}/getNewPropertyProjectData`]
    );
    const getCurrentHistoricalDraftProject = computed(
      () => store.getters[`${JOBS_STORE}/getCurrentHistoricalDraftProject`]
    );

    onMounted(async () => {
      isFetchingPropertiesLoading.value = true;
      const data = await getPropertiesListing(1, 8);
      propertiesList.value = data;
      if (
        propertiesList.value.length === 1 &&
        !getNewPropertyProjectData.value.propertyId
      ) {
        toggleSelection(propertiesList.value[0]?.id);
      }
      normalizeData();

      if (getNewPropertyProjectData.value.propertyId) {
        const propertyId = getNewPropertyProjectData.value.propertyId;
        const isExistId = propertiesList.value.find(
          (data: any) => data.id === propertyId
        );
        if (isExistId) toggleSelection(propertyId);
        store.commit(`${PROPERTY_STORE}/setNewPropertyProjectData`, null);
        submitSelectedCards();
      }

      if (props.isHistoricalDraftProjectFlow) {
        selectedPropertyIds.value = [
          getCurrentHistoricalDraftProject.value?.propertyId,
        ];
      }
    });

    const onLoadMore = async () => {
      const { currentPage, totalPages, totalPropertiesCount } =
        paginationData.value;
      isFetchingPropertiesLoading.value = true;
      if (isPaginationAvailable.value) {
        const data = await getPropertiesListing(
          currentPage,
          totalPropertiesCount + 8
        );
        propertiesList.value = [...data];
        isFetchingPropertiesLoading.value = false;
        normalizeData();
      }
    };

    const getPropertyType = (property: any) => {
      return property?.propertyType
        ? property?.propertyType?.trim()
        : typeof property?.epcData === "string"
        ? parseJSON(property?.epcData)
          ? parseJSON(property?.epcData)["property-type"]
          : "NA"
        : property?.epcData
        ? property?.epcData["property-type"]
        : "NA";
    };

    const isSubmitButtonDisabled = computed(() => {
      return selectedPropertyIds.value.length === 0;
    });

    const onClickAddProperty = () => {
      // if (props.workFlowType === PropertyWorkflowTypeEnum.PROPERTY) {
      // router.push("/properties");
      // } else {
      //  add property New Logic
      // return;
      // }
      router.push({
        name: ADD_PROPERTY_ROUTE,
        params: { type: PROPERTY_TYPE },
      });
    };
    const projectProperties = ref();
    const projectRefData = ref();
    const onClickeditProjectPropertyList = async (value: any) => {
      isShowProjectType.value = false;
      currentSelectedTab.value = modelDetails.value[0];
      try {
        isFetchingPropertiesLoading.value = true;
        let response = await store.dispatch(`${PROJECT_STORE}/getProjectData`, {
          projectRefId: value.projectRefId,
        });
        projectRefData.value = response;
        isEditMode.value = true;
        projectProperties.value = response?.projectProperties;
        selectedPropertyIds.value = projectProperties.value.map((item: any) => {
          return item.property.id;
        });
      } catch (error) {
        console.log(error);
      } finally {
        isFetchingPropertiesLoading.value = false;
      }
    };

    const updateSelectedPropertyName = (propertyId: any) => {
      isEditPropertyName.value.value = "";
      isEditPropertyName.value.isEdit = true;
      isEditPropertyName.value.propertyId = propertyId;
    };
    const closeSelectedPropertyName = () => {
      isEditPropertyName.value.isEdit = false;
      isEditPropertyName.value.propertyId = null;
      isEditPropertyName.value.value = "";
    };
    const updatePropertyName = async () => {
      if (isEditPropertyName.value?.value) {
        const payload = {
          nickname: isEditPropertyName.value.value,
        };

        try {
          const response = await updateProperty(
            isEditPropertyName.value.propertyId,
            payload
          );

          if (response && response.id) {
            const updateProperty = propertiesList.value.find(
              (property: any) => property.id === response.id
            );
            if (updateProperty) updateProperty.nickname = response.nickname;
          }
        } catch (error) {
          console.log(error);
        }
      }

      closeSelectedPropertyName();
    };

    const onClickSaveLater = () => {
      ctx.emit("onClickBackButton");
    };
    const onHistoricalProjectBack = (value = false) => {
      if (value) {
        currentSelectedTab.value = modelDetails.value[0];
        onClickBackButton();
      }
      if (props.isHistoricalDraftProjectFlow) {
        currentSelectedTab.value = modelDetails.value[0];
      } else {
        currentSelectedTab.value = modelDetails.value[1];
      }
      isShowHistoricalProjectFlow.value = false;
    };
    const onHistoricalProjectSave = async () => {
      const params = {
        status: -1,
      };
      const payload = {
        propertyId: selectedPropertyIds.value[0],
        params,
      };
      try {
        await store.dispatch(
          `${PROPERTY_STORE}/fetchPropertyProjects`,
          payload
        );
      } catch (error) {
        console.log("error", error);
      }
    };
    return {
      currentSelectedTab,
      modelDetails,
      propertiesList,
      selectedPropertyIds,
      isSelected,
      toggleSelection,
      submitSelectedCards,
      PropertyListLengthZero,
      onClickBackButton,
      projectTypeList,
      toggleSelectionType,
      recommendedProjects,
      isProjectTypeSelected,
      selectedProjectTypeName,
      onClickToggleContinue,
      isShowProjectType,
      onClickBackButtonType,
      isPaginationAvailable,
      onLoadMore,
      isFetchingPropertiesLoading,
      isMapView,
      getPropertyType,
      addresses,
      isSubmitButtonDisabled,
      filterOptions,
      onClickAddProperty,
      onClickeditProjectPropertyList,
      projectProperties,
      projectRefData,
      isEditMode,
      isEditPropertyName,
      updateSelectedPropertyName,
      closeSelectedPropertyName,
      updatePropertyName,
      onClickSaveLater,
      getLatLng,
      PropertyWorkflowTypeEnum,
      parsedPropertyList,
      getStepperList,
      onHistoricalProjectBack,
      isShowHistoricalProjectFlow,
      onHistoricalProjectSave,
      backButtonLabelList,
      userSelectedProperties,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/modules/project/styles/project.scss";
//@import "@/modules/project/styles/projectType.scss";
.empty__content {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px;
  .empty__banner {
    width: 492px;
    max-width: 100%;
    margin: 0 auto;
    // .empty__banner__img {
    //   img {
    //     @include respond(s720) {
    //       width: 100%;
    //       max-width: 100%;
    //     }
    //   }
    // }
    .empty__banner__content {
      margin-top: 46px;
      h4 {
        @include fluidFont(28, 28, 1.3);
        font-weight: 500;
        color: rgba($blueDark, 1);
      }
      p {
        margin-top: 16px;
        @include fluidFont(14, 14, 1.3);
        font-weight: 500;
        color: rgba($blueDark, 0.5);
        letter-spacing: 0.15px;
      }
    }
  }
}
</style>
