<template>
  <v-card
    class="tw-min-h-[250px] tw-bg-white tw-rounded-[20px] tw-shadow-none tw-cursor-pointer tw-p-4 tw-box-borders"
    :class="
      props.job?.userWorkstation?.subscription
        ? 'premium-job-gradient'
        : 'free-job-gradient'
    "
    @click.prevent="() => $emit('onSelect', job?.refId)"
  >
    <section class="tw-w-full tw-h-full tw-flex-col-between">
      <div
        class="tw-flex tw-flex-col tw-gap-1 tw-pb-3 tw-box-border tw-border-b-dashed-custom"
      >
        <div class="tw-flex-row-between tw-gap-2 tw-flex-nowrap">
          <ChipState
            class="tw-relative tw-z-10 !tw-justify-start !tw-p-[0] !tw-gap-2 tw-w-[70%]"
            :hasCustomLabel="true"
            bgColor="transparent"
            :hasShadow="false"
          >
            <template #prefix>
              <div
                class="tag tw-rounded-full tw-flex tw-items-center tw-justify-center tw-w-[18px] tw-h-[18px]"
              >
                <img
                  v-if="props.job?.userWorkstation?.subscription"
                  class="tw-w-[16px] tw-h-[16px] tw-object-contain"
                  src="@/assets/icons/premium-bg-white.svg"
                />
                <img
                  v-else
                  class="tw-w-[16px] tw-h-[16px] tw-object-contain"
                  src="@/assets/icons/free-member-bg-white.svg"
                />
              </div>
            </template>
            <template #custom-label>
              <Text variant="span" whiteSpace="nowrap">
                {{
                  props.job?.userWorkstation?.subscription ? "PREMIUM" : "FREE"
                }}
                <Text variant="span" whiteSpace="nowrap" textColor="#FFA500"
                  >PROPERTY MEMBER</Text
                >
              </Text>
            </template>
          </ChipState>
          <template v-if="props.jobType === 'all'">
            <Text variant="span" class="tw-w-[30%]"
              >{{ elapsedTime
              }}<Text variant="span" textColor="#8587A4"> ago</Text></Text
            >
          </template>
        </div>
        <div class="tw-flex tw-gap-2 tw-items-center">
          <Text variant="span"
            >Customer Rating:
            <img :src="require('@/assets/icons/rating-star.svg')" />
            <Text variant="span" textColor="#8587A4"
              >&nbsp;{{ projectAvgRating }}</Text
            ></Text
          >
          <!-- <v-icon
            icon="mdi-information-variant-circle-outline"
            size="14px"
            color="#0C0F4A80"
          /> -->
          <!-- <Text variant="span"
            >{{ job?.distance ? parseFloat(job?.distance).toFixed(0) : 0 }}
            <Text variant="span" textColor="#8587A4">Mile Away</Text></Text
          > -->
        </div>
      </div>

      <div
        class="tw-w-full tw-h-full tw-flex tw-flex-col tw-items-start tw-gap-2"
      >
        <h5
          class="fixed-text tw-text-[18px] tw-font-semibold tw-text-[#0c0f4a]"
        >
          {{ job?.name }}
        </h5>
        <!-- <Text
          variant="h5"
          textAlign="left"
          whiteSpace="noWrap"
          class="fixed-text"
          >{</Text
        > -->
        <div class="tw-flex-row-between tw-gap-2 tw-flex-wrap">
          <EmergencyChip label="Emergency" v-if="job?.type === 'emergency'" />
          <StandardChip v-if="job?.type === 'standard'" />
          <RoutineChip v-if="job?.type === 'routine'" />
          <VideoConsultationChip v-if="job?.type === 'video consultation'" />
          <div class="tw-flex tw-items-center tw-gap-1 tw-justify-between">
            <div>
              <!-- <div v-for="(postcode, i) in job?.postcode" :key="i">
                <Text variant="span">
                  <Text variant="span" textColor="#8587A4">{{
                    postcode || "-"
                  }}</Text></Text
                >
              </div> -->
              <div>
                <Text variant="span" textColor="#8587A4"
                  >{{ job?.properties?.length || 0 }}&nbsp;Properties</Text
                >
              </div>
            </div>
            <el-divider
              v-if="job?.properties?.length"
              direction="vertical"
            ></el-divider>
            <div class="">
              <ResidentialChip
                v-if="getPropertyCategory.isResidential"
                class="!tw-p-0"
              />
              <CommercialChip
                v-if="getPropertyCategory.isCommercial"
                class="!tw-p-0"
              />
            </div>
          </div>
        </div>
        <div class="tw-w-full">
          <!-- <Text
            ref="descriptionElement"
            variant="span"
            textWeight="400"
            textAlign="left"
            lineHeight="20px"
            textColor="rgba(12, 15, 74, 0.5)"
            class="description-text tw-overflow-hidden tw-text-ellipsis"
            v-html="job?.description"
          >
          </Text>
          <Text
            textColor="#4F55F0"
            variant="span"
            textWeight="400"
            v-if="isOverflowing"
            >Read More</Text
          > -->
          <div class="description">
            <p v-html="projectDescription"></p>
            <!-- <span
                class="tw-text-sm tw-font-normal tw-text-[#4B4BFF] tw-tracking-[0.25px]"
                v-if="stringWithoutTags?.length > 100"
                @click.stop="toggleReadMore"
                >{{ buttonText }}</span
              > -->
          </div>
        </div>
      </div>

      <div
        class="tw-w-full tw-flex tw-flex-col tw-gap-1"
        :class="{
          'tw-border-t-dashed-custom tw-pt-3 tw-box-border': hasProgressBar,
        }"
      >
        <div class="card_stepper" v-if="props.jobType === 'active'">
          <v-stepper mobile class="stepper__wrapper" v-if="phaseCount">
            <v-stepper-header>
              <div
                class="stepper__step"
                :class="{
                  inprogress: phaseCompleted === 0,
                  completed: phaseCompleted >= 1,
                }"
              >
                <v-stepper-item
                  value="P1"
                  :complete="phaseCompleted >= 1"
                ></v-stepper-item>
              </div>
              <div
                class="stepper__step"
                v-if="phaseCount > 1"
                :class="{
                  inprogress:
                    phaseCompleted <= phaseCount - 2 && phaseCompleted !== 0,
                  completed: phaseCompleted >= phaseCount - 1,
                }"
              >
                <div class="step__connector"></div>
                <v-stepper-item
                  :value="`
                    P${
                      phaseCompleted < phaseCount - 1
                        ? phaseCompleted
                          ? phaseCompleted + 1
                          : 2
                        : phaseCount - 1
                    }`"
                  :complete="phaseCompleted >= phaseCount - 1"
                ></v-stepper-item>
              </div>
              <div
                class="stepper__step"
                :class="{
                  inprogress:
                    phaseCompleted === phaseCount - 1 && phaseCompleted !== 0,
                  completed: phaseCount === phaseCompleted,
                }"
              >
                <div class="step__connector"></div>
                <v-stepper-item
                  value="END"
                  :complete="phaseCount === phaseCompleted"
                ></v-stepper-item>
              </div>
            </v-stepper-header>
          </v-stepper>
        </div>
        <div class="tw-w-full tw-flex-row-between tw-mt-2">
          <div v-if="['all', 'draft'].includes(props.jobType || '')"></div>
          <template v-if="hasProgressBar && props.jobType === 'active'">
            <InProgressChip
              class="tw-flex"
              v-if="
                [
                  ProjectJobStatusEnum.IN_PROGRESS,
                  ProjectJobStatusEnum.QUOTE_ACCEPTED,
                ].includes(job.projectJobStatus?.id)
              "
            />
            <CompletedChip
              class="tw-flex"
              v-else-if="
                [ProjectJobStatusEnum.COMPLETED].includes(
                  job.projectJobStatus?.id
                )
              "
            />
            <EstimateSendChip
              class="tw-flex"
              v-else-if="
                [ProjectJobStatusEnum.QUOTES_RECEIVED].includes(
                  job.projectJobStatus?.id
                )
              "
            />
            <NotStartedChip class="tw-flex" v-else />
          </template>
          <!-- <DraftChip
            class="tw-hidden"
            :class="{
              '!tw-flex': hasProgressBar && props.jobType === 'draft',
            }"
          /> -->
          <ChipState
            label="Details"
            bgColor="#FAA500"
            textColor="#FFF"
            class="!tw-rounded-full"
          >
            <template #postfix>
              <div
                class="tw-rounded-full tw-w-[16px] tw-h-[16px] tw-flex tw-items-center tw-justify-center details-icon"
              >
                <v-icon
                  icon="mdi-chevron-right"
                  color="#faa500"
                  size="x-small"
                ></v-icon>
              </div>
            </template>
          </ChipState>
        </div>
      </div>
    </section>
  </v-card>
</template>
<script lang="ts" setup>
import moment from "moment";
import { ref, onMounted, nextTick, computed, onUnmounted } from "vue";

import Text from "@/core/components/ui/general/Text.vue";
import ChipState from "@/core/components/common/ChipState.vue";

import EmergencyChip from "@/core/uni-components/Chips/EmergencyChip.vue";
import StandardChip from "@/core/uni-components/Chips/StandardChip.vue";
import RoutineChip from "@/core/uni-components/Chips/RoutineChip.vue";
import CommercialChip from "@/core/uni-components/Chips/CommercialChip.vue";
import ResidentialChip from "@/core/uni-components/Chips/ResidentialChip.vue";
import VideoConsultationChip from "@/core/uni-components/Chips/VideoConsultationChip.vue";
import DraftChip from "./chips/DraftChip.vue";
import NotStartedChip from "./chips/NotStartedChip.vue";
import CompletedChip from "./chips/CompletedChip.vue";
import RejectedChip from "@/modules/jobs/components/chips/RejectedChip.vue";
import InProgressChip from "./chips/InProgressChip.vue";
import EstimateSendChip from "./chips/EstimateSendChip.vue";

import {
  ProjectJobStatusEnum,
  PropertyCategoryEnum,
} from "@/core/enums/ProjectsEnum";

const props = defineProps({
  job: {
    type: Object,
    required: true,
  },
  jobType: {
    type: String,
  },
  hasProgressBar: {
    type: Boolean,
    default: false,
  },
});

const descriptionElement = ref(null) as any;
const isOverflowing = ref(false);
const isDescriptionExpanded = ref(false);

const phaseCount = ref(0);
const phaseCompleted = ref(0);

const checkOverflow = () => {
  nextTick(() => {
    if (descriptionElement.value) {
      const el = descriptionElement.value;
      isOverflowing.value = el.scrollHeight > el.clientHeight;
    }
  });
};

// Method to calculate elapsed time
const calculateElapsedTime = (date: string): string => {
  const now = moment();
  const createdDate = moment(date);

  const seconds = now.diff(createdDate, "seconds");
  const minutes = now.diff(createdDate, "minutes");
  const hours = now.diff(createdDate, "hours");
  const days = now.diff(createdDate, "days");
  const weeks = now.diff(createdDate, "weeks");
  const months = now.diff(createdDate, "months");
  const years = now.diff(createdDate, "years");

  if (years > 0) {
    return `${years} Year${years > 1 ? "s" : ""}`;
  } else if (months > 0) {
    return `${months} Month${months > 1 ? "s" : ""}`;
  } else if (weeks > 0) {
    return `${weeks} Week${weeks > 1 ? "s" : ""}`;
  } else if (days > 0) {
    return `${days} Day${days > 1 ? "s" : ""}`;
  } else if (hours > 0) {
    return `${hours} Hrs${hours > 1 ? "" : ""}`;
  } else if (minutes > 0) {
    return `${minutes} Min${minutes > 1 ? "s" : ""}`;
  } else {
    return `${seconds} Sec${seconds > 1 ? "s" : ""}`;
  }
};

// Reactive reference to hold the elapsed time
const elapsedTime = ref(calculateElapsedTime(props.job?.dateCreated));

// Update the elapsed time every minute
const realTimeSetInterval = setInterval(() => {
  elapsedTime.value = calculateElapsedTime(props.job?.dateCreated);
}, 1000);

const getPropertyCategory = computed(() => ({
  isResidential: props.job?.properties?.length
    ? props.job.properties.some(
        (property: any) =>
          property?.propertyCategory === PropertyCategoryEnum.RESIDENTIAL
      )
    : false,
  isCommercial: props.job?.properties?.length
    ? props.job.properties.some(
        (property: any) =>
          property?.propertyCategory === PropertyCategoryEnum.COMMERCIAL
      )
    : false,
}));

const buttonText = computed(() =>
  isDescriptionExpanded.value ? "Read Less" : "Read More"
);
const toggleReadMore = () => {
  isDescriptionExpanded.value = !isDescriptionExpanded.value;
};
const stringWithoutTags = computed(() =>
  props.job?.description
    ? props.job?.description
        .trim()
        .replace(/<\/?[^>]+(>|$)/g, "")
        .replace(/\s\s+/g, " ")
    : " "
);

const projectDescription = computed(() => {
  return isDescriptionExpanded.value ||
    (stringWithoutTags.value && stringWithoutTags.value?.length <= 100)
    ? stringWithoutTags.value
    : stringWithoutTags.value?.substring(0, 100) + "...";
});

const projectAvgRating = computed(() => {
  const rating = props.job?.projectRating?.reduce(
    (previous: any, current: any) => {
      previous += current?.avgRating;
      return previous;
    },
    0
  );
  return rating / props.job?.projectRating?.length
    ? (rating / props.job?.projectRating?.length).toFixed(1)
    : 0;
});
onMounted(() => {
  checkOverflow();
  window.addEventListener("resize", checkOverflow);
  if (props.job?.projectEstimation?.length) {
    phaseCount.value = props.job?.projectEstimation[0]?.estimationPhase?.length;
    phaseCompleted.value =
      props.job?.projectEstimation[0]?.estimationPhase?.filter(
        (e: any) => e.phaseCompletionStatus
      )?.length;
  }
});
onUnmounted(() => {
  clearInterval(realTimeSetInterval);
});
</script>
<style lang="scss" scoped>
.free-job-gradient {
  background: linear-gradient(white, white) padding-box,
    linear-gradient(to bottom, #686df2, white) border-box;
  border: 2px solid transparent;
}

.premium-job-gradient {
  background: linear-gradient(white, white) padding-box,
    linear-gradient(to bottom, #ffa500, white) border-box;
  border: 2px solid transparent;
}

.tag {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
}

.description {
  margin: 0;
  text-align: left;
  p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
}

.card_stepper {
  .stepper__wrapper {
    box-shadow: none;
    .v-stepper-header {
      box-shadow: none;
      .stepper__step {
        &:first-child {
          display: block;
          width: auto;
        }
      }
      .stepper__step {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 100%;
        .step__connector {
          width: 100%;
          height: 3px;
          background-color: rgba($blueDark, 0.05);
        }
        .v-stepper-item {
          padding: 0;
          opacity: 1;
          :deep(.v-avatar) {
            margin: 0;
            background: rgba($blueDark, 0.05);
            color: rgba($blueDark, 0.5);
            @include fluidFont(8, 8, 1);
            font-weight: 700;
            letter-spacing: 0.25px;
          }
        }
      }
      .inprogress {
        .step__connector {
          background-color: rgba($orange, 1);
        }
        .v-stepper-item {
          :deep(.v-avatar) {
            border: 2px solid rgba($orange, 1);
            background: rgba($orange, 0.1);
            color: rgba($orange, 1);
          }
        }
      }
      .completed {
        .step__connector {
          background-color: rgba($orange, 1);
        }
        .v-stepper-item {
          :deep(.v-avatar) {
            background: rgba($orange, 1);
            color: rgba($white, 1);
          }
        }
      }
    }
  }
}

.fixed-text {
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-align: left;
}

.details-icon {
  background-color: rgba($white, 1);
}
</style>
