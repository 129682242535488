import { ProjectState } from "@/core/models/project";

export default {
  setProjectDetails(state: ProjectState, payload: any) {
    state.projectDetails = { ...state.projectDetails, ...payload };
  },
  resetProjectDetails(state: ProjectState, payload: any) {
    state.projectDetails = payload;
  },

  setProjectAttachments(state: any, payload: any) {
    state.projectAttachments = payload;
  },

  setProjectSpecialisms(state: any, payload: any) {
    state.projectSpecialisms = payload;
  },

  addProjectSpecialism(state: any, payload: any) {
    // state.projectSpecialisms = payload;

    const isExist = state.projectSpecialisms.find(
      (specialism: any) => specialism.id === payload.id
    );

    if (!isExist) {
      state.projectSpecialisms.push(payload);
    }
  },
  removeProjectSpecialism(state: any, payload: any) {
    // const data = Object.values(state.projectSpecialisms) as any;
    state.projectSpecialisms = state.projectSpecialisms.filter(
      (specialism: any) => specialism.id !== payload.id
    );
  },
  setInvitedContractors(state: any, payload: any) {
    state.invitedContractors = payload;
  },

  setDraftProjectList(state: ProjectState, payload: any) {
    state.draftProjectList = payload;
  },
  setSelectedPropertiesList(state: ProjectState, payload: any) {
    state.selectedProperties = payload;
  },
  setPropertyListState(
    state: ProjectState,
    payload: { projectId: string; data: boolean }
  ) {
    state.propertyListState = payload;
  },
  setProjectRedirectTabName(state: ProjectState, payload: any) {
    state.projectRedirectTabName = payload;
  },
  setProjectEstimateDataWithEscrowStatus(state: any, payload: any) {
    state.projectEstimateWithEscrow = payload;
  },
  setHistoricalProjectForm(state: any, payload: any) {
    state.historicalProjectForm = payload;
  },

  setHistoricalProjectFormSpecialism(state: any, payload: any) {
    state.historicalProjectForm.specialisms = payload;
  },

  setProjectEstimateEscrowBalance(state: any, payload: any) {
    state.projectEstimateEscrowBalance = payload;
  },

  setProjectRequestedList(state: any, payload: any) {
    state.projectRequestedList = payload;
  },
  setProjectStatusCountsList(state: any, payload: any) {
    state.projectStatusCountsList = payload;
  },
};
