<template>
  <div class="courses_content tw-h-auto">
    <v-tabs
      class="tab-list md:!tw-h-auto md:!tw-mb-4"
      v-model="tab"
      align-tabs="center"
      stacked
    >
      <v-tab value="tab-1">
        <v-icon>mdi-home</v-icon>
        Courses
      </v-tab>

      <v-tab value="tab-2" v-if="!isPersonalAccountWorkStation">
        <v-icon>mdi-file-document</v-icon>
        Enrolled
      </v-tab>
      <v-tab value="tab-3" v-if="!isPersonalAccountWorkStation">
        <v-icon>mdi-file-document-check-outline</v-icon>
        Assigned Courses
      </v-tab>

      <v-tab value="tab-4" v-if="!isPersonalAccountWorkStation">
        <v-icon>mdi-view-list</v-icon>
        Completed
      </v-tab>
    </v-tabs>

    <v-window v-model="tab" class="tw-h-full md:!tw-pb-16 md:!tw-box-border">
      <v-window-item v-for="i in 4" :key="i" :value="'tab-' + i">
        <CoursesListView v-if="i === 1" />
        <EnrollCoursesList v-if="i === 2" @onBack="onBack" />
        <AssignCoursesList v-if="i === 3" @onBack="onBack" />
        <CompletedCourseList v-if="i === 4" @onBack="onBack" />
      </v-window-item>
    </v-window>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import { useStore } from "vuex";

import CoursesListView from "@/modules/courses/components/CoursesListView.vue";
import EnrollCoursesList from "@/modules/courses/components/EnrollCoursesList.vue";
import AssignCoursesList from "@/modules/courses/components/AssignCoursesList.vue";
import CompletedCourseList from "@/modules/courses/components/CompletedCourseList.vue";
import { WORKSTATION } from "@/store/modules/workstation";

export default defineComponent({
  name: "CoursesPage",
  components: {
    CoursesListView,
    EnrollCoursesList,
    CompletedCourseList,
    AssignCoursesList,
  },
  setup() {
    const store = useStore();

    const tab = ref(null) as any;

    const isPersonalAccountWorkStation = computed(
      () =>
        store.getters[`${WORKSTATION}/activeUserWorkstation`]?.name ===
        "Default"
    );

    const onBack = () => {
      if (tab.value) {
        tab.value = "tab-1";
      }
    };
    return { tab, onBack, isPersonalAccountWorkStation };
  },
});
</script>
<style lang="scss" scoped>
@import "@/modules/courses/styles/courses.scss";
</style>
