import { getRandomColor } from "@/core/utils/common";
import { fa } from "element-plus/es/locale";
import _ from "lodash";
import workstation from "../workstation";
import jobTemplate from ".";
const estimateForm = {
  projectStages: [
    {
      id: null,
      isNew: true,
      name: "",
      stageType: "labour",
      totalDuration: 0,
      durationType: 1,
      stageCost: 0,
      projectStageTasks: [],
      requiredDeposit: false,
      vat: 0,
      subTotal: "0",
      subTotalDisplay: "",
      note: "",
      startDate: null,
      phaseOrder: 0,
      phaseStatus: null,
      endDate: null,
      estimationPhaseProperties: [],
      color: getRandomColor(),
      attachments: [],
      user: null,
      workStation: null,
    },
  ],
};

const formData = {
  suggestedStartDate: null,
  grantType: null,
  grantName: "",
  grantAmount: 0,
  project: null,
  id: null,
};

export default {
  estimateForm,
  formData,

  defaultEstimateForm: _.cloneDeep(estimateForm),
  defaultFormData: _.cloneDeep(formData),

  defaultProjectStageForm: {
    name: "",
    stageType: "labour",
    totalDuration: 0,
    durationType: 1,
    stageCost: "0",
    projectStageTasks: [],
    requiredDeposit: false,
    vat: 0,
    subTotal: "0",
    subTotalDisplay: "",
    note: "",
    startDate: null,
    endDate: null,
    estimationPhaseProperties: [],
    grantType: "",
    grantName: "",
    grantAmount: 0,
    phaseOrder: 0,
    attachments: [],
    user: null,
    workStation: null,
    phaseStatus: null,
  },

  selectedPhaseIndex: 0,

  templateName: "",

  selectedJobTemplate: "",

  showPhaseUploadAttachmentModal: false,
  showTaskUploadAttachmentModal: false,
  showCreateTemplateConfirmationModal: false,
  showUseTemplateModal: false,
  showSubmitEstimateConfirmationModal: false,
  isOnEstimateForm: false,
  confirmDeleteModal: false,

  isFormReady: false,
  confirmLoadTemplateModal: false,

  selectedTemplateId: null,

  selectDepositModal: false,
  isTemplateCreate: false,

  isEstimateLoadedModified: false,
  isEstimateSubmit: false,
  hasLoadedTemplate: false,
  loadedEstimate: null,
  isFormReadOnly: false,

  lockTemplateModifiedChecker: false,

  selectedAssignMembers: null,
  assignMembersModal: false,
  assignMembers: [],
  invitedContractorsList: [],
  subContractorsPhases: [],
  parentContractorPhases: [],
  totalPhases: [],
  showUpdateEstimateConfirmationModal: false,
  isSubContractor: false,
  projectEstimationInvitation: null,
  isSubmitProjectEstimation: false,
  errors: { subErrors: {} },
  isFormErrors: {
    startDate: false,
    validationError: false,
    projectStageDate: false,
  },
  mainContractorId: null,
  estimateResponse: {},

  jobTemplates: [],
  estimatePhaseNote: {},
  holdEstimateAddTaskApi: false,
};
